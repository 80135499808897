import React, { useEffect } from "react";
import { styled, Typography, Box, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { context, ACTION_TYPE } from "../State";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import radioButtonIconChecked from "../img/radioButtonChecked.svg"
import radioButtonIconCheckedDisabled from "../img/radioButtonIconCheckedDisabled.svg"

//authentication constants
const AUTHN = "authn";
const WEBAUTHN = "webauthn";
const CMLA = "cmla";
const WEBAUTHN_ACCESS_KEY = "webauthnaccess";


export default function Configurator(props) {
  const { t } = useTranslation();
  const { authenticationSettingsDto, shouldShowAccessKey, disabled } = props;
  const { state, dispatch } = React.useContext(context);
  let showAccessKey = shouldShowAccessKey !== undefined ? shouldShowAccessKey : true
  let authConfig = {
    WEBAUTHN_ENABLED:  authenticationSettingsDto !== null ? authenticationSettingsDto.WEBAUTHN_ENABLED : true,
    WEBAUTHN_MD_ENABLED: authenticationSettingsDto !== null ? authenticationSettingsDto.WEBAUTHN_MD_ENABLED : true,
    AUTHN_ENABLED:  authenticationSettingsDto !== null ? authenticationSettingsDto.AUTHN_ENABLED : true,
    CMLA_ENABLED: false, // CMLA DISABLED FOR NOW
    WEBAUTHN_ACCESS_KEY_ENABLED: authenticationSettingsDto !== null ? authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED : true
  };

  let initialChange = true;

  useEffect(() => {
    if (authenticationSettingsDto !== null && initialChange) {
      initialChange = false;
      let setMethod = AUTHN;
      let setFallbackMethod = undefined;
        if (!authenticationSettingsDto.AUTHN_ENABLED) {
          if (authenticationSettingsDto.WEBAUTHN_ENABLED) {
            setMethod = WEBAUTHN;
            setFallbackMethod = authenticationSettingsDto.CMLA_ENABLED ? t('configurator.cmla') : t('configurator.none');
          } else if (authenticationSettingsDto.CMLA_ENABLED) {
            setMethod = CMLA;
            setFallbackMethod = undefined;
          } else if (authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED && !authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED) {
            setMethod = WEBAUTHN_ACCESS_KEY;
            setFallbackMethod = undefined;
          } else {
            setMethod = WEBAUTHN;
            setFallbackMethod = undefined;
          }
      }
      if (state.method !== setMethod || state.fallbackMethod !== setFallbackMethod) {
        dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });
      }
    }

  }, [])




  function updateStatus(loginMethod, typeOfMethod) {

    let setMethod = typeOfMethod === "login" ? loginMethod : WEBAUTHN;
    let setFallbackMethod = typeOfMethod === "fallback" ? loginMethod : (loginMethod === WEBAUTHN ? "none" : undefined);
    dispatch({ type: ACTION_TYPE.METHOD, payload: { method: setMethod, fallbackMethod: setFallbackMethod } });

    if (typeOfMethod === "fallback") {
      authConfig.CMLA_ENABLED = t('configurator.cmla') === loginMethod;
      authConfig.WEBAUTHN_ENABLED = true;
      authConfig.AUTHN_ENABLED = false;
      authConfig.WEBAUTHN_MD_ENABLED = false;
    } else if (typeOfMethod === "login") {
      if (AUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = true;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = true;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (WEBAUTHN === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = true;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = false;
      } else if (CMLA === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_MD_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = false;
        authConfig.CMLA_ENABLED = true;
      } else if (WEBAUTHN_ACCESS_KEY === loginMethod) {
        authConfig.AUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ENABLED = false;
        authConfig.WEBAUTHN_ACCESS_KEY_ENABLED = true;
        authConfig.CMLA_ENABLED = false;
      }
    }
    dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
  }

  let methods = [
    {
      "loginMethod": t('configurator.webauthn'),
      // "fallbackLoginMethods": isDomainIntegration !== 'true' ? [t('configurator.cmla'), t('configurator.none')] : [t('configurator.none')], CMLA DISABLED FOR NOW
      "numberOfDevices": t('configurator.devices_webauthn').split('\n').map((str, index) =>
        <>
          {index === 0 && <>{str} <br></br></>}
          {index === 1 && <div style={{ color: 'gray', marginLeft: '-5px' }}> {str} </div>}
        </>
      ),
      "formValue": "webauthn"
    },
    {
      "loginMethod":
        t('configurator.authn')
      ,
      "numberOfDevices": t('configurator.devices_all_methods').split('\n').map((str, index) => <>
        {index === 0 && <>{str} <br></br></>}
        {index === 1 && <b>{str.substring(0, str.indexOf(":"))}</b>}
        {index === 1 && str.substring(str.indexOf(":"), str.length)}
      </>),
      "formValue": "authn"
    }
  ]
  if (showAccessKey &&
    (authenticationSettingsDto === null || (!authenticationSettingsDto.CLIENT_CERTIFICATE_ENABLED))) {
    methods.push({
      "loginMethod": t('configurator.webauthn_access_key'),
      "numberOfDevices": t('configurator.devices_webauthn_access_key').split('\n').map((str, index) =>
        <>
          {index === 0 && <>{str} <br></br></>}
          {index === 1 && <span style={{ color: 'gray', marginLeft: '-5px' }}> {str} </span>}
        </>
      ),
      "formValue": "webauthnaccess"
    })
  }


  //filter undefined values
  methods = methods.filter(function (element) {
    return element !== undefined;
  });

  const loginMethodsTheme = createTheme({
    palette: {
      primary: {
        main: '#293dc7',
      },
      text: {
        primary: '#000000',
        secondary: '#7e7e7e',
      }
    },
    typography: {
      fontFamily: 'Source Sans Pro',
    },
  });

  const LoginMethodsContainer = {
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: '1px solid',
    borderColor: '#eeeeee',
    display: 'flex',
    maxWidth: '592px',
    flexDirection: 'column',
  };

  const LoginMethodsHeader = styled(Box)({
    alignItems: 'center',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: '#eeeeee',
    display: 'flex',
    width: '100%',
    gap: '16px',
    padding: '16px',
  });

  const LoginMethodsHeaderText = styled(Typography)({
    letterSpacing: '0.14px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1,
    '&:first-of-type': {
      width: '160px',
    },
  });




  return (
    <>
      <ThemeProvider theme={loginMethodsTheme}>
        <Box style={LoginMethodsContainer}>
          <LoginMethodsHeader>
            <LoginMethodsHeaderText>{t('configurator.login_method')}</LoginMethodsHeaderText>
            <LoginMethodsHeaderText>{t('configurator.number_of_devices')}</LoginMethodsHeaderText>
          </LoginMethodsHeader>
          {methods.map((method) => (
            <LoginMethodsOption
              disabled={disabled}
              method={method}
              isSelected={state.method === method.formValue}
              onSelect={() => updateStatus(method.formValue, "login")}
            />
          ))}
        </Box>
      </ThemeProvider>
    </>

  );
}

const LoginMethodsOptionWrapper = {
  borderBottom: '1px solid',
  borderColor: '#eeeeee',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  overflow: 'hidden',
};

const LoginMethodsOptionContent = {
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  gap: '16px',
  flexWrap: 'wrap',
  padding: '8px 16px',
};

const LoginMethodsTitleSection = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  width: '160px',
};

const LoginMethodsOptionTitle = styled(Typography)({
  color: 'text.primary',
  letterSpacing: '0.09px',
  flex: 1,
  fontSize: '18px',
  lineHeight: '24px',
  whiteSpace: 'pre-line',
});

const LoginMethodsDetailsSection = styled(Box)({
  display: 'flex',
  minWidth: '240px',
  flexDirection: 'column',
  letterSpacing: '0.09px',
  flex: 1,
  fontSize: '18px',
});

const LoginMethodsDescription = styled(Typography)({
  color: 'text.secondary',
  lineHeight: '24px',
});



function LoginMethodsOption({ method, isSelected, onSelect, disabled }) {
  return (
      <Box style={LoginMethodsOptionWrapper}>
        <Box style={LoginMethodsOptionContent}>
          <Box style={LoginMethodsTitleSection}>
            <Radio
                className="loginMethods"
                checked={isSelected}
                checkedIcon={<img src={disabled? radioButtonIconCheckedDisabled : radioButtonIconChecked} style={{ width: '20px', height: '20px' }} />}
                onChange={onSelect}
                inputProps={{ 'aria-label': method.loginMethod }}
                disabled={disabled}
                size="sm"
                variant="outlined"
                sx={{color: '#293dc7',
                  padding: '4px',
                  '&.Mui-disabled': {
                    color: '#808080',
                  },
                  '&.Mui-checked': {
                    padding: '2px',
                  }
                }}
            />
            <LoginMethodsOptionTitle>{method.loginMethod}</LoginMethodsOptionTitle>
          </Box>
          <LoginMethodsDetailsSection>
            <LoginMethodsDescription>{method.numberOfDevices}</LoginMethodsDescription>
          </LoginMethodsDetailsSection>
        </Box>
      </Box>
  );
}