import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthRoute from "./AuthRoute"
import LoginPage from "./pages/login/LoginPage"
import { getTheme } from "./theme/theme";
import Api from './helpers/service';
import { log, tryLocalStorageSetItem } from './helpers/utils'
import { context, ACTION_TYPE } from "./State";
import MainLayout from "./pages/main/MainLayout";
import { Switch } from "react-router-dom";
import SetupPage from "./pages/registration/SetupPage";
import ConfigManager from "./config/config"
import CustomDialog from "./components/DialogText";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom"
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

export const configManager = new ConfigManager().getInstance();

export const publicUrl = process.env.PUBLIC_URL;

export default function App() {

    const { t } = useTranslation();
    const location = useLocation();
    const { state, dispatch } = React.useContext(context);
    const theme = createTheme(getTheme());
    const api = new Api();
    let refreshSessionQrIntervalId;
    let refreshSessionQrInterval = 2; // in seconds
    const [openAccessDeniedDialog, setOpenAccessDeniedDialog] = React.useState(false);
    const [email, setEmail] = React.useState("");
    msalConfig.auth.clientId = configManager.azureAppClientId;
    const msalInstance = new PublicClientApplication(msalConfig);

    useEffect(
        () => {
            checkSessionQr();
            refreshSessionQrIntervalId = setInterval(() => checkSessionQr(), refreshSessionQrInterval * 1000);
        },
        []
    );

    useEffect(() => () => clearInterval(refreshSessionQrIntervalId), []); // on unmount

    function closeAccessDeniedDialog() {
        setOpenAccessDeniedDialog(false);
        window.location.reload(false);
    }


    function checkSessionQr() {
        api.checkSession().then(function (res) {
            log(res);
            if (!!res.accessDenied) {
                setOpenAccessDeniedDialog(true);
                clearInterval(refreshSessionQrIntervalId);
            } else if (!!res.sessionActive) {
                log("Logged in user:  " + res.email);
                setEmail(res.email);
                tryLocalStorageSetItem("email", res.email);
                if (location.pathname === "/setup") {
                    dispatch({ type: ACTION_TYPE.SETUP_SUCCESS, payload: true })
                } else {
                    if (!state.isAuthUser) {
                        dispatch({ type: ACTION_TYPE.AUTH_USER, payload: true });
                        window.location.reload(false);
                    } 
                } 
            }
        }).catch(function (res) {
            if (state.isAuthUser && res.message !== "timeout of 30000ms exceeded") {
                dispatch({ type: ACTION_TYPE.AUTH_USER, payload: false });
                window.location.reload(false);
            }
        })
    }

    return (
        <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
            <Switch>
                <AuthRoute path="/login" type="guest" isAuthUser={state.isAuthUser}>
                    <LoginPage />
                    <CustomDialog open={openAccessDeniedDialog} title={t('dialog.heading_access_rights')} subtitle={t('dialog.text_access_rights')}
                        confirmBtnText={t('dialog.button_okay')}
                        onConfirm={closeAccessDeniedDialog}
                        singleButton={true} />
                </AuthRoute>
                <AuthRoute path="/setup" type="guest" isAuthUser={state.isAuthUser}>
                    <SetupPage />
                    <CustomDialog open={openAccessDeniedDialog} title={t('dialog.heading_access_rights')} subtitle={t('dialog.text_access_rights')}
                        confirmBtnText={t('dialog.button_okay')}
                        onConfirm={closeAccessDeniedDialog}
                        singleButton={true} />
                </AuthRoute>
                <AuthRoute path="/" type="private" isAuthUser={state.isAuthUser}>
                    <MainLayout email={email} />
                </AuthRoute>
            </Switch>
        </ThemeProvider>
        </MsalProvider>
    );
}