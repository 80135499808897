import {useTranslation} from "react-i18next";
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import {availableServiceProviders} from "../helpers/utils";
import {styled} from "@mui/material";
import IconButton from "@mui/material/IconButton";

export function IntegrationTypeSelector(props) {

    const { setIsAddModeFirstStepOn, setIsAddModeSecondStepOn, integrationDetails, setIntegrationDetails} = props;
    const { t } = useTranslation();


    const handleClickOnSP = (serviceProvider, event) => {
        event.preventDefault();
        setIsAddModeSecondStepOn(true);
        setIsAddModeFirstStepOn(false);
        setIntegrationDetails({...integrationDetails,
                                integrationType: serviceProvider.integrationType});
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Typography style={{color: 'var(--Black, #000)',
                fontFamily: "Source Sans Pro",
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.18px'}}>
                {t('add_integration.select_application')}
            </Typography>
            <Grid container xs alignItems="left" rowGap={"24px"}>
                {availableServiceProviders.map((sp, index) => {
                    return (sp.integrationType !== 'OIDC' && sp.integrationType !== 'CUSTOM') ? <Grid item xs={3} >
                                <ServiceProviderIconButton id={sp.elementId + "-img"} key={index} onClick={(event) => { handleClickOnSP(sp, event) }}>
                                    <img id={sp.elementId + "-button"} src={sp.icon} title={sp.title} alt={sp.title} style={{ display: 'flex', height: '52px', padding: '2px 6px',
                                        justifyContent: 'center', alignItems: 'center'}} />
                                </ServiceProviderIconButton>
                            </Grid> : null
                })}
            </Grid>
            <Typography style={{color: 'var(--Black, #000)',
                fontFamily: "Source Sans Pro",
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '0.14px'}}> Custom integrations
            </Typography>
            <Grid container xs alignItems="left" rowGap={"24px"}>
                {availableServiceProviders.map((sp, index) => {
                    return (sp.integrationType === 'OIDC' || sp.integrationType === 'CUSTOM') ? <Grid item xs={3} >
                        <ServiceProviderIconButton id={sp.elementId + "-img"} key={index} onClick={(event) => { handleClickOnSP(sp, event) }}>
                            <img id={sp.elementId + "-button"} src={sp.icon} title={sp.title} alt={sp.title} style={{ display: 'flex', height: '52px', padding: '2px 6px',
                                justifyContent: 'center', alignItems: 'center'}} />
                        </ServiceProviderIconButton>
                    </Grid> : null
                })}
            </Grid>
        </Box>
    );
}

const StyledIconButton = styled(IconButton)({
    "&:hover": {
        backgroundColor: "white"
    },
    "&:active": {
        backgroundColor: "white"
    }
});

const ServiceProviderIconButton = styled(StyledIconButton)({
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: '1px solid var(--Blue---primary-10, #F0F5FF)',
    background: 'var(--White, #FFF)',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)'
});