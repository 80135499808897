import * as React from 'react';
import TextFieldCopy from "./TextFieldCopy";
import {availableServiceProviders} from "../helpers/utils";

export default function ScimComponent(props){

    const {isScimEnabled, integrationType, isEditOrAddModeOn, isLoadedScimCredentials, setIsLoadedScimCredentials, scimCredentials, setScimCredentials} = props;

    let sp = availableServiceProviders.filter(serviceProvider => serviceProvider.integrationType === integrationType);
    let paramJSON = [];
    if (sp !== undefined && sp[0] !== undefined){
        sp[0].idPParameters.map(param => {
            let labelKey = param['label'];
            let paramType = param['type'];
            let labelValue = '';

            if(paramType==="scimendpointurl" || paramType==="scimsecrettoken"){
                paramJSON.push({
                    [labelKey]: [labelValue],
                    'type': [paramType]
                });
            }
            return null;
        })
    }

    return (<>
        {paramJSON.map((param) => {
            let type = param["type"];
            let labelKey = Object.keys(param).filter(key => key !== "type")[0];
            let infoDescription = {
                "type": type !== undefined ? type[0] : "",
                "label": labelKey
            }
            return <>
            {isEditOrAddModeOn && (!isScimEnabled || (isScimEnabled && infoDescription.type === "scimendpointurl")) &&
                <TextFieldCopy infoDescription={infoDescription} isOidc={integrationType === 'OIDC'}
                               isEditable={false} isCopied={true} isLoadedScimCredentials={isLoadedScimCredentials} setIsLoadedScimCredentials={setIsLoadedScimCredentials} scimCredentials={scimCredentials} setScimCredentials={setScimCredentials}/>
            }
            </>
        })
        }


    </>)



}

