import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from "@mui/material";
import {styled} from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

export default function DrawerMessage(props) {

    const {text, isError, ref, messageWidth} = props;
    const MessageContainer = styled(Box)(({ isError }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        borderRadius: "12px",
        backgroundColor: !isError ? "#E5FFEA" : "#ffe5e5",
        gap: "4px",
        color: !isError ? "#00a825" : "#b52626",
        letterSpacing: "0.09px",
        flexWrap: "wrap",
        padding: "12px 8px",
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "18px",
        lineHeight: 1,
        fontWeight: 400,
        width: messageWidth ? messageWidth : '100%'
    }));

    const MessageText = styled(Typography)({
        flex: 1,
        flexBasis: "0%",
        "@media (max-width: 991px)": {
            maxWidth: "100%",
        },
    });

    return (
        <MessageContainer isError={isError} ref={ref}>
            {!isError && <CheckIcon fontSize="inherit" style={{height: '22px', width: '22px', color: "#00a825"}}/>}
            {isError && <CloseIcon fontSize="inherit" style={{height:'22px', width: '22px', color: "#b52626"}} />}
            <MessageText>{text}</MessageText>
        </MessageContainer>
    );



}