import * as React from 'react';
import {createTheme, styled} from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ToggleSwitch } from './ToggleSwitch';
import MuiDivider from "@mui/material/Divider";

function DrawerSection(props) {
    const {content, stepLabel, needsTitle, needsToggle, title, divide, needsDescription, description, needsDescriptionNote, descriptionNote, initialChecked, isViewModeOn, setCreateContract, setCheckedToggle, disabled, isEditModeOn, isAddModeOn, setSelectedContract, assignedContract, id, classMessage} = props;
    const [checked, setChecked] = React.useState(initialChecked);

    return (
        <>
            <StyledSection needsTitle={needsTitle}>
                <HeaderSection content={content} disabled={disabled} isViewModeOn={isViewModeOn} isAddModeOn={isAddModeOn} isEditModeOn={isEditModeOn} checked={checked} needsToggle={needsToggle} style={{height: needsTitle ? "100%" : "0px"}}>
                    <div>
                        {!isViewModeOn && <StepLabel variant="subtitle2" theme={theme}>
                            {stepLabel}
                        </StepLabel>}
                        {needsTitle &&
                            <TitleWrapper theme={theme} needsTitle={needsTitle}>
                                {(needsToggle && !disabled) && <ToggleSwitch isChecked={checked} setIsChecked={setChecked} setCreateContract={setCreateContract} setCheckedToggle={setCheckedToggle} setSelectedContract={setSelectedContract} assignedContract={assignedContract} sectionTitle={title}/>}
                                <SectionTitle id={id} className={classMessage} variant="h1" theme={theme}>
                                    {title}
                                </SectionTitle>
                            </TitleWrapper>}
                    </div>
                    <div style={{marginTop: needsDescription ? "24px" : "0px"}}>
                        {needsDescription && <StyledDescription>{description}</StyledDescription>}
                        {needsDescriptionNote && <StyledNote>{descriptionNote}</StyledNote>}
                    </div>
                </HeaderSection>
                {!disabled &&
                    <ContentSection checked={checked} needsToggle={needsToggle}>
                        {content}
                    </ContentSection>}
            </StyledSection>
            {divide && <Divider/>}
        </>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#4d70ea',
            dark: '#293dc7',
        },
        text: {
            primary: '#202020',
            secondary: '#7e7e7e',
        },
        grey: {
            300: '#d7d7d7',
            400: '#bdbdbd',
        },
    },
    typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
        h5: {
            fontSize: '20px',
            lineHeight: 1.2,
            fontWeight: 700,
        },
        subtitle2: {
            fontSize: '14px',
            lineHeight: 1,
            fontWeight: 600,
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        borderRadius: 8,
                    },
                },
            },
        },
    },
});

const StyledSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch'
}));

const HeaderSection = styled(Box)(({content, disabled, isViewModeOn, checked, isAddModeOn, isEditModeOn, needsToggle}) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: isViewModeOn || (isAddModeOn && content !== null && content !== undefined && !disabled) || (isEditModeOn && checked) || (isEditModeOn && !needsToggle) ? "24px" : "0px"
}));

const StepLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    letterSpacing: '0.14px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1,
    fontFamily: 'Source Sans Pro, sans-serif',
    marginBottom: "8px"
}));

const TitleWrapper = styled(Box)(({ needsTitle }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    height: needsTitle ? '100%' : '0px'
}));

const SectionTitle = styled(Typography)(() => ({
    color: "var(--Black, #000)",
    letterSpacing: "0.3px",
    flex: 1,
    flexBasis: "0%",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: 1.2,
    fontFamily: "Source Sans Pro, -apple-system, Roboto, Helvetica, sans-serif",
    margin: 0,
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
}));

const Divider = styled(MuiDivider) (() => ({
    width: "100%",
}));

const ContentSection = styled(Box)(({checked, needsToggle }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    visibility: needsToggle ? (checked ? 'visible' : 'hidden') : 'visible',
    height: needsToggle ? (checked ? '100%' : 0) : '100%',
}));

const StyledDescription = styled(Typography)(() => ({
    color: "#000000",
    fontFamily: "Source Sans Pro, -apple-system, Roboto, Helvetica, sans-serif",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    letterSpacing: "0.09px"
}));

const StyledNote = styled(Typography)(() => ({
    color: "#000000",
    letterSpacing: "0.14px",
    fontFamily: "Source Sans Pro, -apple-system, Roboto, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    marginTop: "8px",
}));

export default DrawerSection;