import React, {useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import WestIcon from "@mui/icons-material/West";
import {serviceProvidersIcon} from "../helpers/utils";
import {styled} from "@mui/material";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import {context} from "../State";

export function IntegrationConfigHeader(props) {
    const {isEditModeOn, isViewModeOn, isAddModeFirstStepOn, isAddModeSecondStepOn, isAddModeThirdStepOn, integrationName, integrationType, backClick} = props;

    const [title, setTitle] = React.useState("");
    const { state } = React.useContext(context);

    useEffect(() => {
        if (isEditModeOn || isViewModeOn) {
            setTitle("Integration Details");
        }

        if (isAddModeFirstStepOn) {
            setTitle("Add an Integration");
        }

        if (isAddModeSecondStepOn) {
            setTitle("Name a new integration");
        }

        if (isAddModeThirdStepOn) {
            setTitle("Configure " + integrationName);
        }

        if (state.openNotificationsDrawer) {
            setTitle("Notifications");
        }
    }, [integrationName, isAddModeFirstStepOn, isAddModeSecondStepOn, isAddModeThirdStepOn, isEditModeOn, isViewModeOn]);

    return (
        <HeaderContainer>
            <IconButton id="backButton" onClick={backClick}>
                <WestIcon/>
            </IconButton>
            { !isAddModeFirstStepOn && !state.openNotificationsDrawer &&
                <IntegrationLogo
                    component="img"
                    loading="lazy"
                    src={serviceProvidersIcon[integrationType]}
                    alt="Integration logo"
                />}
            <HeaderTitle id={(isEditModeOn? "edit" : (isViewModeOn? "view" :  "new")) + integrationType} variant="h1">{title}</HeaderTitle>
        </HeaderContainer>
    );
}

const HeaderContainer = styled(Box)({
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    alignItems: "center"
});

const IntegrationLogo = styled(Box)({
    aspectRatio: "1",
    objectFit: "contain",
    objectPosition: "center",
    width: "40px",
    borderRadius: "4px",
    margin: "auto 0"
});

const HeaderTitle = styled(Typography)({
    color: "var(--Black, #000)",
    letterSpacing: "0.36px",
    flexBasis: "auto",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
    "@media (max-width: 991px)": {
        maxWidth: "100%"
    }
});