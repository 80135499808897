import * as React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {Button, LicenseButton} from "./CustomUI";
import IconButton from "@mui/material/IconButton";
import EditIcon from "../img/edit.svg"
import SettingsIcon from "../img/settings_groups.svg";
import Api from "../helpers/service";
import MoveIcon from "../img/move_icon.svg"
import {log} from "../helpers/utils";
import GroupMenu from "./GroupMenu";
import {ACTION_TYPE, context} from "../State";
import CustomDialog from "./DialogText";
import GroupSettings from "./GroupSettings";
const api = new Api();

function changeGroupName(containerName, containerId) {
    api.changeContainerName(containerName, containerId).catch(function (error) {
        log(error);
    });
}

const GroupStats = (props) => {
    const {owner, groupContainerId, integrationsNumber, firstGroupName, isShared, t} = props;
    const [isEditing, setIsEditing] = React.useState(false);
    const [groupName, setGroupName] = React.useState(firstGroupName);
    const [lastSavedGroupName, setLastSavedGroupName] = React.useState(firstGroupName);

    const handleIconClick = () => {
      setIsEditing(true);
    };
  
    const handleBlur = () => {
      setIsEditing(false);
      setGroupName(lastSavedGroupName)
    };
  
    const handleChange = (event) => {
      setGroupName(event.target.value);
    };
  
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
       setIsEditing(false);
       changeGroupName(groupName, groupContainerId);
       setLastSavedGroupName(groupName)
      } 
    };
  return (
    <StatsWrapper className="groupStats">
      {isEditing ? (
        <StyledTextField
          className="groupNameEditing"
          autoFocus
          value={groupName}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          variant="standard"
          inputProps={{
              maxLength: 50,
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <GroupName className="groupName">{groupName}</GroupName>
      )}
        {!isShared && <IconButton className="editGroupNameButton" style={{minWidth: '24px', width: '24px', height: '24px', pointerEvents: 'auto'}}>
            <img onClick={handleIconClick} tabIndex={0} role="button" src={EditIcon}/>
        </IconButton>}
      <Typography variant="h5" style={{ fontFamily: "Source Sans Pro", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.03px" }}>
        |
      </Typography>
        <StatColumn className="groupColumn">
            <StatLabel>{t("dialog.search_user_integrations")}</StatLabel>
            <StatValue className="integrationNumber">{integrationsNumber}</StatValue>
        </StatColumn>
      <Typography variant="h5" style={{ fontFamily: "Source Sans Pro", fontSize: "20px", fontWeight: 700, lineHeight: "24px", letterSpacing: "0.03px" }}>
        |
      </Typography>
        <StatColumn className="groupColumn">
            <StatLabel>{t("dialog.owner")}</StatLabel>
            <StatValue className="groupOwner">{owner}</StatValue>
        </StatColumn>
    </StatsWrapper>
  );
  };
  
  const StatsWrapper = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    minWidth: "240px",
    alignItems: "center",
    gap: "8px",
    letterSpacing: "0.3px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flex: 1,
    flexBasis: "0%",
    margin: "auto 0",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "20px",
    lineHeight: 1.2,
    marginLeft: "20px",
    "@media (max-width: 991px)": {
      maxWidth: "100%"
    }
  });
  
  const GroupName = styled(Box)({
    color: "#000",
    textAlign: "center",
    fontWeight: 700,
    alignSelf: "stretch",
    margin: "auto 0"
  });
  
  const StyledTextField = styled(TextField)({
    "& input": {
      color: "#000",
      textAlign: "center",
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Source Sans Pro, sans-serif",
      padding: 0
    },
    width: "auto",
    margin: "auto 0"
  });
  
  const StatColumn = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    whiteSpace: "nowrap",
    letterSpacing: "0.14px",
    lineHeight: 1,
    justifyContent: "flex-start",
    margin: "auto 0",
    "@media (max-width: 991px)": {
      whiteSpace: "initial"
    }
  });
  
  const StatLabel = styled(Box)({
    color: "#7e7e7e",
    fontWeight: 400
  });
  
  const StatValue = styled(Box)({
    color: "#000",
    textAlign: "center",
    fontWeight: 600,
    alignSelf: "flex-start"
  });


  const GroupActions = (props) => {

    const { setIsAddModeOn, t, groupContainerId, setOpenDrawer, isMoveActive, ownedGroups, samlIntegrations, oidcIntegrations, setOwnedGroups, groupName } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newGroupName, setNewGroupName] = React.useState('');
    const [openSettingsState, setOpenSettingsState] = React.useState(false);
    const [openCreateNewGroup, setOpenCreateNewGroup] = React.useState(false);

    const {dispatch} = React.useContext(context);

    const handleClick = (event) => {
      getAllGroups()
      setAnchorEl(event.currentTarget);
    }

    const getAllGroups = () => {
      api.getOwnedGroupsByUser().then(function (res) {
        let ownedGroupsWithoutCurrent = [];
        Object.entries(res).map(group => {
          if (group[1].containerId != groupContainerId) {
            if (group[1].name === null) {
              group[1].name = "My group"
            }
            ownedGroupsWithoutCurrent.push(group[1])
          }
        });
        setOwnedGroups(ownedGroupsWithoutCurrent);
      }).catch(function (error) {
        log(error);
      })
    }
    const handleClose = () => setAnchorEl(null);

      const moveIntegrations = (destinationContainerId) => {
          if (destinationContainerId) {
              api.moveIntegrations(destinationContainerId, oidcIntegrations, samlIntegrations).then(() => window.location.reload());
          }
          handleClose();
      }


      const onClickAddIntegration = () => {
          dispatch({type: ACTION_TYPE.CONTAINER_ID, payload: groupContainerId});
          setIsAddModeOn(true);
          setOpenDrawer(true);
      }

    const openSettings = () => {
        setOpenSettingsState(true);
    }


      const handleDialogCreateNewGroup = () => {
          api.addNewContainer(newGroupName).then(function (res) {
              log(res.data);
              moveIntegrations(res.data.containerId);
          }).catch(function (error) {
              log(error);
          })

      }

    return (
      <ActionsWrapper className="mainHeaderButton">
            <IconButton className="groupSettingsButton" onClick={openSettings} sx={{borderRadius: '24px', height: '40px', width: '40px', pointerEvents: 'auto', marginRight: '20px'}}>
                <img src={SettingsIcon} alt="settings icon"></img>
            </IconButton>
          {!isMoveActive &&
              <Button className="addIntegrationButton" onClick={onClickAddIntegration} sx={{pointerEvents: 'auto'}}>
                  <AddIcon/>
                  {t("add_integration.add_integration_button_heading")}
            </Button>}
          {isMoveActive &&
              <LicenseButton className="moveIntegrationButton" onClick={handleClick} style={{padding: "12px", gap: "4px", display: "flex", width: "100%", height: "48px"}}>
                <img src={MoveIcon}></img>
                <Typography style={{ fontFamily: "Source Sans Pro", fontSize: "17px", fontWeight: 600, lineHeight: "24px", letterSpacing: "0.03px" }}>{t("group.move_integration")}</Typography>
              </LicenseButton>}
            <GroupMenu anchorEl={anchorEl} handleClose={(destinationContainerId) => moveIntegrations(destinationContainerId)} ownedGroups={ownedGroups} setOpenCreateNewGroup={setOpenCreateNewGroup} />
          <GroupSettings open={openSettingsState} currentSettingsGroupName={groupName} groupContainerId={groupContainerId} setOpenSettingsState={setOpenSettingsState}></GroupSettings>
          <CustomDialog
              open={openCreateNewGroup}
              onConfirm={handleDialogCreateNewGroup}
              id={"createGroupDialog"}
              newGroupName={newGroupName}
              setNewGroupName={setNewGroupName}
              title={t('create_new_group.title_dialog')}
              confirmBtnText={t('create_new_group.button_confirm')}
              cancelBtnText={t('create_new_group.button_cancel')}
              onCancel={()=> setOpenCreateNewGroup(false)}
              />
      </ActionsWrapper>
    );
  };

  const ActionsWrapper = styled(Box)({
    alignSelf: "stretch",
    display: "flex",
    minWidth: "240px",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "20px"
  });

export {GroupStats, GroupActions}

