import React, {useEffect} from "react";
import {styled} from "@mui/material/styles";
import {Box, Typography} from '@mui/material';
import DownloadIcon from '../img/download_white.svg';
import TabsComponent from "./TabsComponent";
import Api from "../helpers/service";
import { log } from "../helpers/utils"
import { context, ACTION_TYPE } from "../State";
import { useTranslation } from "react-i18next";
import { Button } from './CustomUI';

const SettingsComponent = () => {
  const { t } = useTranslation();
  const api = new Api();
  const [trials, setTrials] = React.useState(null);
  const { dispatch } = React.useContext(context);


  useEffect(
    () => {
      loadIntegrations();
    },
    []
  );

  return (
      <Box sx={{ width: 'inherit', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h1" sx={{ marginLeft: '16px' }}> {t('settings.settings_heading')} </Typography>
        <TabsComponent firstTabTitle={"Reports"}
        firstTabContent={<StyledActivityReport><ActivityReportHeader dispatch={dispatch} t={t}></ActivityReportHeader></StyledActivityReport>} settings={true} />
      </Box>)

  function loadIntegrations() {
    api.getOwnedIntegrationsByUser().then(function (res) {
      let trialIntegrations = [];

      Object.entries(res).map(integration => {
        if (integration[1].licenseType === 'Trial') {
          trialIntegrations.push(integration[1])
        }
      });
      setTrials(trialIntegrations)
    }).catch(function (error) {
      log(error);
    })
  }
};

const ActivityReportHeader = (props) => {
  const { t } = props

  const api = new Api();

  function downloadReport() {
    api.downloadReport();
  }

  return (
    <HeaderWrapper>
      <ContentContainer>
        <Typography variant="h6">{t('dialog.heading_download_activity_report')}</Typography>
        <Typography variant="h4_column">{t('dialog.text_download_activity_report')}</Typography>
      </ContentContainer>
      <ActionContainer>
        <Button sx={{gap: '4px'}} onClick={downloadReport}>
          <img src={DownloadIcon} alt="Download activity report" />
          {t('dialog.button_download_activity_report')}
        </Button>
      </ActionContainer>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Source Sans Pro, sans-serif',
  textAlign: 'center',
  justifyContent: 'space-between',
  padding: '0 16px 0 24px',
}));

const ContentContainer = styled(Box)(() => ({
  alignSelf: 'stretch',
  display: 'flex',
  minWidth: '240px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

const ActionContainer = styled(Box)(() => ({
  alignSelf: 'stretch',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '17px',
  color: '#010101',
  fontWeight: 600,
  justifyContent: 'flex-end',
  margin: 'auto 0',
}));


const StyledActivityReport = styled('div')({
  border: '1px solid #d7d7d7',
  borderRadius: '16px',
  padding: '16px 0',
  width: 'inherit',
  margin: '0 auto',
  '@media (max-width: 640px)': {
    borderRadius: '8px',
    padding: '12px 0',
  },
});



export default SettingsComponent
