import React, { useEffect } from 'react';
import {styled} from "@mui/material";
import {withRouter, useLocation} from "react-router-dom";
import {MainMenuButton} from "./CustomUI";
import Box from "@mui/material/Box";
import DashboardIcon from "../img/integrations.svg";
import DashboardIconBlack from "../img/integrationsBlack.svg";
import SettingsIcon from "../img/settings.svg";
import SettingsBlue from "../img/settings_groups.svg";

const StyledBox = styled(Box)(() => ({
    border: '0px',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    marginLeft: "40px"
}));

const HorizontalMenu = (props) => {

    const { history } = props;
    let location = useLocation();
    const [selected, setSelected] = React.useState(location.pathname);

    useEffect(
        () => {
            if (selected !== location.pathname) {
                setSelected(location.pathname)
            }
        },
        [location.pathname]
    );

    const handleChange = (event, path) => {
        if (path !== null) {
            setSelected(path);
            history.push(path);
        }
    };

    return (
            <StyledBox>
                <MainMenuButton id='dashboardButton' sx={{backgroundColor: selected === "/integrations" ? "#F0F5FF" : "#FFFFFF", color: selected === "/integrations" ? "#293DC7" : "#000000"}} value={"/integrations"}  aria-label={"Dashboard"} onClick={(e) => handleChange(e,"/integrations")}>
                    <img src={selected === "/integrations" ? DashboardIcon : DashboardIconBlack} style={{width: '24px', height: '24px', }} alt="dashboard"/>
                    {"Dashboard"}
                </MainMenuButton>
                <MainMenuButton id='settingsButton' sx={{backgroundColor: selected === "/settings" ? "#F0F5FF" : "#FFFFFF", color: selected === "/settings" ? "#293DC7" : "#000000"}} value={"/settings"} aria-label={"Settings"} onClick={(e) => handleChange(e,"/settings")}>
                    <img src={selected === "/settings" ? SettingsBlue : SettingsIcon}  style={{width: '24px', height: '24px'}}  alt="settings"/>
                    {"Settings"}
                </MainMenuButton>
            </StyledBox>
    );
};

export default withRouter(HorizontalMenu)