import * as React from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SubscriptionCard } from '../pages/subscription/SubscriptionCard';
import Api from "../helpers/service";
import Divider from "@mui/material/Divider";
import {InverseButton} from "./CustomUI";
import {t} from "i18next";
import Box from "@mui/material/Box/Box";

function DropDown(props) {
    const { type, label, items, actions, values, isEdit, isAdd, width, disabled } = props

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuData, setMenuData] = React.useState([]);

    const open = Boolean(anchorEl);
    const api = new Api();

    //Common methods
    const handleButtonClick = (event) => {
        if (items !== null && items !== undefined) {
            setMenuData(items);
        } else if (!values.createContract && type === "Contract") {
            loadContractsForUser();
        }
        setAnchorEl(event.currentTarget);

    };

    const handleClose = (event, item) => {
        if (type === "Contract") {
            setAnchorEl(null);
        } else if (type === "SIEM") {
            if (item !== undefined) {
                actions.handleChangeSelection(item.value);
                actions.setDropdownText(item.label);
            }
            setAnchorEl(null);
        } else if (type === "Domain federation") {
            if (item) {
                actions.selectDomain(item.id);
            }
            setAnchorEl(null);
        } else if (type === "Azure user") {
            if (item) {
                actions.setDomain(item.id);
                actions.setDropdownText(item.id);
            }
            setAnchorEl(null);
        } else if (type === "Group") {
            if (item) {
                actions.setGroupId(item.containerId);
                actions.setDropdownText(item.name);
            }
            setAnchorEl(null);
        }
    };

    //Methods for contracts
    const loadContractsForUser = () => {
        api.getContractsForUser("/app/getContractsForUser").then( data => {
            setMenuData(data);
        });

    }

    function MenuItemContent(props) {
        const {item} = props;
        if (type === "Contract") {
            return <SubscriptionCard contract={item} selectedContact={values.selectedContract} setSelectedContract={actions.setSelectedContract} setNewContractId={actions.setNewContractId} closeIconNeeded={false} titleNeeded={true} contractLabelNeeded={false} setShowSelectedContract={actions.setShowSelectedContract} setDropdownText={actions.setDropdownText} />
        }
        if (type === "Domain federation") {
           return   <ListItem className='domainListItem'>
                        {item.id}
                    </ListItem>
        }
        if (type === "SIEM") {
            return  <ListItem className='siemDropDownItem' onClick={() => actions.setDropdownText(item.value)}>
                        {item.label}
                    </ListItem>
        }
        if (type === "Azure user") {
            return  <ListItem>
                        {item.id}
                    </ListItem>
        }
        if (type === "Group") {
            return  <ListItem>
                        {item.name}
                    </ListItem>
        }
    }

    return (
        <>
            <SelectorContainer $createContract={values !== undefined && values.createContract !== undefined ? values.createContract : true} width={width}>
                {label && <SelectorLabel htmlFor="menu-select">{label}</SelectorLabel> }
                <SelectFieldButton
                    className="menu-select"
                    aria-controls={open ? "dropdown-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleButtonClick}
                    disabled={ disabled !== undefined ? disabled : !isEdit && !isAdd}
                    $createContract={values !== undefined && values.createContract !== undefined ? values.createContract : true}
                    width={width}
                >
                    {values.dropdownText}
                    <SelectFieldIcon
                        loading="lazy"
                        alt="Contract selection icon"
                    />
                </SelectFieldButton>
            <StyledMenu
                className="dropdown-menu"
                id="dropdown-menu"
                width={width}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
                MenuListProps={{
                    'aria-labelledby': 'menu-select',
                }}
            >
                {menuData.map((item, index) => (
                    <>
                        <MenuItem className='dropDownMenuItem' key={index} onClick={(event) => handleClose(event, item)} disableRipple>
                            <MenuItemContent item={item}/>
                        </MenuItem>
                        {type === "Domain federation" && index !== (items.length) && <Divider />}
                        {type === "Domain federation" && index === (items.length - 1) && <InverseButton style={{ backgroundColor: 'black', color: 'white', marginBottom: 0, }} onClick={() => {actions.selectPrimaryDomain(); setAnchorEl(null);}} >{t('domain_federation.change_domain_to_primary')}</InverseButton>}
                    </>
                ))}
            </StyledMenu>
            </SelectorContainer>
            {values.showSelectedContract &&
                <SubscriptionCard contract={values.selectedContract} setSelectedContract={actions.setSelectedContract} setNewContractId={actions.setNewContractId}
                               closeIconNeeded={true} titleNeeded={false} contractLabelNeeded={false} setShowSelectedContract={actions.setShowSelectedContract} />}
        </>
    );
}

const SelectorContainer = styled("div")(({ $createContract, width }) => ({
    display: "flex",
    width: width ? width : "592px",
    flexDirection: "column",
    fontFamily: "Source Sans Pro, sans-serif",
    opacity: $createContract ? 0.8 : 1
}));

const SelectorLabel = styled("label")({
    width: "100%",
    fontSize: "14px",
    color: "var(--Gray-6, #7e7e7e)",
    fontWeight: 600,
    letterSpacing: "0.14px",
    lineHeight: 1,
    marginBottom: "4px",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const SelectFieldButton = styled(Button)(({ $createContract, disabled, width }) => ({
    disabled: $createContract,
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid var(--Gray-3, #d7d7d7)",
    background: disabled ? "#EEEEEE" : "var(--White, #fff)",
    padding: "12px",
    gap: "8px",
    width: width ? width : "542px",
    fontSize: "18px",
    color: "var(--Black, #000)",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: 1,
    cursor: "pointer",
    textAlign: "left",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus": {
        outline: "0px",
        outlineOffset: "0px",
    },
    "@media (max-width: 991px)": {
        maxWidth: "100%",
        whiteSpace: "initial",
    },
}));

const SelectFieldIcon = styled(KeyboardArrowDownIcon)({
    aspectRatio: 1,
    objectFit: "contain",
    objectPosition: "center",
    width: "24px",
    marginLeft: "auto",
});

const StyledMenu = styled(Menu)(({ width }) => ({
    width: width ? width : '100%',
    '& .MuiPaper-root': {
        borderRadius: '0 0 8px 8px',
        marginTop: '4px',
        maxWidth: '592px',
        border: '1px solid #d7d7d7',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: width ? width : '100%'
    },
    '& .MuiMenuItem-root': {
        width: width ? width : '100%',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const ListItem = styled(Box) (({ width }) =>({
    width: width ? width : '100%',
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch'
}));

export default DropDown;