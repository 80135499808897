import React, {useEffect} from 'react';
import AppBar from "../../components/AppBar";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";
import {Route, Redirect, withRouter, useLocation, Switch, useParams} from "react-router-dom";
import SettingsIcon from '../../img/settings.svg';
import IntegrationsPage from "./IntegrationsPage";
import {ACTION_TYPE, context} from "../../State";
import {useTranslation} from "react-i18next";
import CustomDialog from '../../components/DialogText';
import downloadIcon from '../../img/download.svg'
import thumbIcon from '../../img/icon-thumb.svg';
import Api from "../../helpers/service";
import { useIdleTimer } from 'react-idle-timer'
import {log, tryLocalStorageSetItem} from "../../helpers/utils";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import { Typography } from "@mui/material";
import DashboardIcon from '../../img/integrations.svg';
import { configManager } from '../../App';
import { ReviewButton } from '../../components/CustomUI';
import g2Icon from '../../img/g2icon.svg'
import SettingsComponent from '../../components/SettingsComponent';

const menuItems = [
    {
        title: "Dashboard",
        path: "/integrations",
        icon: <img src={DashboardIcon} style={{width: '24px', height: '24px', }} alt="dashboard"/>,
        layout: <IntegrationsPage/>
    },
    /*{
        title: "Branding",
        path: "/branding",
        icon: <LabelIcon />,
        layout: <BrandingPage/>
    },*/
    {
        title: "Settings",
        path: "/settings",
        icon: <img src={SettingsIcon}  style={{width: '24px', height: '24px', color: "#293DC7"}}  alt="settings"/>,
        layout: <SettingsComponent/>
    },
];

function getMenuItemByPath(path) {
    let item = menuItems.find((item) => {
        return item.path === path
    });
    if (item === undefined) { return menuItems[0]}
    return item;
}

function isValidPath(path) {
    return menuItems.find((item) => {
        return item.path === path
    }) !== undefined;
}

let lastSessionRefresh = Date.now();

// Broadcast that session is refreshed to other browser tabs
tryLocalStorageSetItem("lastSessionRefresh", lastSessionRefresh);

const MainLayout = (props) => {

    const {state, dispatch} = React.useContext(context);
    const { email } = props;
    const location = useLocation();
    const { t } = useTranslation();
    const api = new Api();
    const sessionRefreshInterval = 1000 * 60 * 5; // 5min in ms
    const idleInterval = 1000 * 60 * 10; // 10min in ms
    const [idleTimerTimeout, setIdleTimerTimeout] = React.useState(idleInterval);
    const [refreshSessionDialogProgress, setRefreshSessionDialogProgress] = React.useState(0);
    const sessionRemainingTime = 1000 * 60 * 5; // 5min in ms
    const progressUpdateInterval = 1000; // in ms
    const notificationsUpdateInterval= 1000 * 30; //30sec in ms

    useEffect(() => {
        const timer = setInterval(() => {
            if (getRemainingTime() === 0 ) {
                setRefreshSessionDialogProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        onLogout();
                        return 0;
                    }
                    const diff = 100 / (sessionRemainingTime / progressUpdateInterval);
                    let progress = Math.min(oldProgress + diff, 100);

                    // Broadcast the change to other browser tabs
                    tryLocalStorageSetItem("refreshSessionDialogProgress", progress);

                    return progress;
                });
            }
        }, progressUpdateInterval);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        api.listNotifications().then((res) => {
            let notifications = res.filter(notification => notification.data.expiryTimestamp > Date.now());
            dispatch({type: ACTION_TYPE.NOTIFICATIONS_FOR_USER, payload: notifications});
            });

        let timer = setInterval(() => {
            api.listNotifications().then((res) => {
                let notifications = res.filter(notification => notification.data.expiryTimestamp > Date.now());
                dispatch({type: ACTION_TYPE.NOTIFICATIONS_FOR_USER, payload: notifications});
            });
        }, notificationsUpdateInterval);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const onIdle = () => {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload:true});
    };

    const onAction = () => {
        setIdleTimerTimeout(getRemainingTime()); // update timer interval to remaining time
        if (Date.now() - lastSessionRefresh >= sessionRefreshInterval && !state.isOpenedDialogRefreshSession) {
            refreshSession();
        }
    };

    const {
        getRemainingTime,
    } = useIdleTimer({
        onIdle,
        onAction,
        timeout: idleTimerTimeout,
        promptBeforeIdle: -1, //because we dont use onPrompt 
        events: [
            'keydown',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: true,
        syncTimers: 0
    });

    // Listen to LocalStorage events
    window.addEventListener('storage', (e) => {
        if (e.key === "lastSessionRefresh") {
            let val = parseInt(e.newValue);
            if (!isNaN(val)) {
                lastSessionRefresh = val;
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
                setIdleTimerTimeout(idleInterval);
            }
        } else if (e.key === "refreshSessionDialogProgress") {
            let progress = parseFloat(e.newValue);
            if (!isNaN(progress)) {
                if (refreshSessionDialogProgress < progress) { // Update progress only if it is larger than current
                    setRefreshSessionDialogProgress(progress);
                }
            }
        }
    });

    function onLogout() {
        api.logout().then(function (res) {
            log(res);
            dispatch({type: ACTION_TYPE.AUTH_USER , payload:false});
            dispatch({
                type: ACTION_TYPE.USER_DATA,
                payload: {
                    email: ""
                }
            });
            window.location.reload(false);
        }).catch(function (error) {
            log(error);
        });
    }

    function refreshSession() {
        setRefreshSessionDialogProgress(0); // reset progress bar
        api.refreshSession().then(function (res) {
            log(res);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
            setIdleTimerTimeout(idleInterval);
            lastSessionRefresh = Date.now();
            // Broadcast the change to other browser tabs
            tryLocalStorageSetItem("lastSessionRefresh", lastSessionRefresh);
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_REFRESH_SESSION, payload: false});
            setIdleTimerTimeout(idleInterval);
        });
    }

    function sendMailToSales() {
        api.sendMailToSales(false, null, null, null).then(function() {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload:false});
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:true});
            
        }).catch(function () {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});
        });
    }

    function handleCloseSuccessSendMail() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});    
    }

    function handleCloseDialogAccountCheckFailure() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:false});    
    }

    function handleCloseDialogAccountAccessRevoke() {
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:false});
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:true});    
    }

    function handleDialogAccountDelete() {
        api.deleteAccount().then(function() {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
            dispatch({type: ACTION_TYPE.AUTH_USER , payload:false});
            //TODO stop session timer and redirect to registration page
            
        }).catch(function () {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
        });
    }

    function handleDialogDeviceDelete() {
        log("Handle dialog device delete ...");
        log("Device data: " + state.deviceData);
        api.deleteDevice(state.deviceData).then(function() {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
            dispatch({ type: ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, payload: true });
            window.location.reload();
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
        })
    }

    function handleDialogUserDelete() {
        log("Handle dialog delete user ...");
        log("User data: " + state.userDataEmail);
        api.deleteUser(state.userDataEmail).then(function() {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload: false});
            dispatch({ type: ACTION_TYPE.SHOW_USER_DELETED_SNACK, payload: true });
            window.location.reload();
        }).catch(function (error) {
            log(error);
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload:false});
        })
    }

    function openReview() {
        window.open(configManager.g2ReviewLink, '_blank');
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} disableGutters sx={{padding: '40px'}}>
                <AppBar email={email} onLogout={onLogout} items={menuItems}/>
                <div style={{display:'flex', flexDirection: "column", alignItems: 'flex-start', width: 'inherit'}}>
                <Box sx={{ marginTop: '32px', width: 'inherit'}} >
                    <Switch>
                        { !isValidPath(location.pathname) && <Redirect to={menuItems[0].path}/>}
                        <Route path="/:path">
                            <MenuItemLayout/>
                        </Route>
                    </Switch>
                </Box>
                <ReviewButton onClick={openReview}>
                    <img alt={'g2 logo'} src={g2Icon} style={{
                        display: 'flex',
                        width: '24px',
                        height: '24px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} />
                    <span style={{
                        textAlign: 'center',
                        fontFamily: 'Source Sans Pro',
                        fontSize: '17px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '24px'
                    }}>{t('review_btn')}</span>
                </ReviewButton>
                </div>

                <CustomDialog 
                    id = "upgradePlanDialog"
                    open={state.isOpenedDialogUpgradePlan} 
                    title={t('dialog.heading_upgrade_plan')} 
                    subtitle={t('dialog.text_upgrade_plan')} 
                    confirmBtnText= { t('dialog.button_confirm_upgrade_plan')} 
                    cancelBtnText = {t ('dialog.button_cancel')} 
                    isCentered={true} 
                    onConfirm={sendMailToSales}
                    hideCloseButton= {true}/>

                <CustomDialog 
                    id = "successUpgradePlanDialog"
                    open={state.isOpenedDialogSuccessUpgradePlan} 
                    onConfirm={handleCloseSuccessSendMail}
                    onCancel={handleCloseSuccessSendMail}
                    title={t('dialog.heading_sales_request_sent')} 
                    subtitle={t('dialog.text_sales_request_sent')} 
                    confirmBtnText={t('dialog.button_sales_request_sent')} 
                    iconUrl = {thumbIcon} 
                    singleButton = {true}
                    hideCloseButton= {true}/>
                    
                <CustomDialog
                    id = "failUpgradePlanDialog"
                    open={state.isOpenedDialogAccountCheckFailure} 
                    onConfirm={handleCloseDialogAccountCheckFailure}
                    title={t('dialog.heading_delete_account_error')} 
                    subtitle={t('dialog.text_delete_account_error')} 
                    confirmBtnText={t('dialog.button_okay')} 
                    singleButton = {true}
                    hideCloseButton= {true}/>

                <CustomDialog
                    open={state.isOpenedDialogAccountAccessRevoke} 
                    onConfirm={handleCloseDialogAccountAccessRevoke}
                    title={t('dialog.delete_account_access_revoke_title')} 
                    subtitle={t('dialog.delete_account_access_revoke_text')} 
                    confirmBtnText={t('dialog.button_okay')} 
                    cancelBtnText={t('dialog.button_cancel')}
                    isCentered={true}
                /> 

                <CustomDialog 
                    id = "deleteAccountDialog"
                    open={state.isOpenedDialogDeleteAccount}
                    onConfirm={handleDialogAccountDelete}
                    title={t('dialog.heading_delete_account')}  
                    confirmBtnText={t('dialog.button_confirm_delete_account')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    isCentered={true}/>

                <CustomDialog 
                    id = "deleteDeviceDialog"
                    open={state.isOpenedDialogDeleteDevice}
                    onConfirm={handleDialogDeviceDelete}
                    title={t('dialog.delete_device_heading')}  
                    confirmBtnText={t('dialog.delete_device_confirm')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    isCentered={true}/>

                <CustomDialog 
                    open={state.isOpenedDialogDeleteUser} 
                    title={t('dialog.are_you_sure')} 
                    subtitle={t('dialog.delete_user_subtitle')}        
                    confirmBtnText={t('dialog.delete_user_confirm')} 
                    cancelBtnText={t('dialog.button_cancel')} 
                    email={state.userDataEmail}
                    isCentered={true}
                    onConfirm={handleDialogUserDelete}/>

                <CustomDialog
                    id = "refreshSessionDialog"
                    open={state.isOpenedDialogRefreshSession}
                    onConfirm={refreshSession}
                    title={t('dialog.refresh_session_title')}
                    linearProgress={true}
                    linearProgressSubtitle={
                        <>
                            <Typography variant="h4" color="textPrimary">
                            {t('dialog.refresh_session_message')}
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={refreshSessionDialogProgress}
                                sx={{margin:'20px',
                                    backgroundColor:'transparent',
                                    boxShadow:'inset 0px 0px 6px #00000055',
                                    borderRadius:'7px',
                                    height:'13px'}}/>
                        </>
                    }
                    confirmBtnText={t('dialog.refresh')}
                    singleButton = {true}
                    hideCloseButton = {true}/>
            </Container>
        </React.Fragment>
    );
};

function MenuItemLayout() {
    let { path } = useParams();
    return (
        getMenuItemByPath(`/${path}`).layout
    );
}

export default withRouter(MainLayout);
