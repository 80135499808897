import React from 'react';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box/Box";
export default function FederationOptionRow(props) {

    const {contentText, isFunctionalityShown, contentFunctionality} = props;

    return (
        <StatusItemContainer>
            <div style={{width: '50%', alignItems:'flex-start'}}>
                {contentText}
            </div>
            <div style={{marginLeft: 50, width: '50%', alignItems:'flex-start'}}>
                {isFunctionalityShown && contentFunctionality}
            </div>
        </StatusItemContainer>
    );
}

const StatusItemContainer = styled(Box)(() => ({
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid #EEEEEE',
    display: 'flex',
    width: '100%',
    gap: '8px',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    padding: '10px',
    '&:last-child': {
        borderBottom: 'none'
    },
    '@media (max-width: 991px)': {
        maxWidth: '100%'
    }
}));
