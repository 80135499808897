import * as React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import closeIcon from "../img/close.svg"

function ContractHeader({ contractNumber, setCreateContract, setNewContractId }) {

    const handleClose = () => {
        setCreateContract(false);
        setNewContractId(null);
    }
    return (
        <HeaderContainer>
            <Typography variant="body1" sx={{ fontWeight: 400 }}>
                New Contract:
            </Typography>
            <ContractNumber>{contractNumber}</ContractNumber>
            <HeaderIcon
                component="img"
                src={closeIcon}
                onClick={handleClose}
            />
        </HeaderContainer>
    );
}

const HeaderContainer = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px"
}));

const ContractNumber = styled(Typography)(() => ({
    fontWeight: 600,
    flex: 1
}));

const HeaderIcon = styled(Box)({
    width: "24px",
    height: "24px",
    objectFit: "contain"
});

export default ContractHeader;