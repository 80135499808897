import * as React from "react";
import { Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

function EditionSelector({setAvailableIntegrations, setNumberOfIdentities, edition, setEdition}) {


    const handleEditionChange = (e) => {
        setEdition(e.target.value);
        let edition = e.target.value;
        // eslint-disable-next-line default-case
        switch(edition) {
            case "Business":
                setNumberOfIdentities(1000000);
                setAvailableIntegrations(5);
                break;
            case "Enterprise":
                setNumberOfIdentities(1000000);
                setAvailableIntegrations(1000);
                break;
            case "SSO":
                setNumberOfIdentities(1000000);
                setAvailableIntegrations(1);
                break;

        }


    }

    return (
        <SelectorContainer>
            <FormControl fullWidth>
                <InputLabel id="edition-select-label">Select Edition</InputLabel>
                <StyledSelect
                    labelId="edition-select-label"
                    id="edition-select"
                    value={edition}
                    onChange={(e) => handleEditionChange(e)}
                    label="Select Edition"
                >
                    <MenuItem id="businessContract" value="Business">Business</MenuItem>
                    <MenuItem id="enterpriseContract" value="Enterprise">Enterprise</MenuItem>
                    <MenuItem id="ssoContract" value="SSO">SSO</MenuItem>
                </StyledSelect>
            </FormControl>
        </SelectorContainer>
    );
}

const SelectorContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    width: "100%"
}));

const StyledSelect = styled(Select)(() => ({
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px"
}));

export default EditionSelector;