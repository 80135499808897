import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateTimePickerCustom(props) {
  const {disabled, setValidFromTime} = props;
  //initial value is random date-time because it is never used
  const [value, setValue] = React.useState(dayjs());

  function setTime(time) {
    setValue(time);
    let pickedTime = new Date(time);
    setValidFromTime(pickedTime.getTime());
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker 
          ampm = {false}
          disabled = {disabled}
          onChange = {value => setTime(value)}
          closeOnSelect = {false}
          value={value}
          sx={{alignItems: "center",
            borderRadius: "8px",
            border: disabled ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
            background: disabled ? "var(--Gray-2, #eee)" : "#FFFFFF",
            display: "flex",
            justifyContent: "start",
            minWidth: '229px',
            ".MuiOutlinedInput-notchedOutline": {
              border: disabled ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: disabled ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: disabled ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
            },
            "&.MuiTextField-root": {
                minWidth: '229px',
                fontFamily: "Source Sans Pro, sans-serif",
                fontSize: '18px',
                border: "0px transparent" ,

            }

          }}
          />
      </DemoContainer>
    </LocalizationProvider>
  );
}