import {useTranslation} from "react-i18next";
import * as React from "react";
import Box from "@mui/material/Box";
import {Button} from "./CustomUI";
import TextInput from "./TextInput";
import EastIcon from '@mui/icons-material/East';

export function IntegrationNameInput(props) {
    const { setIsAddModeSecondStepOn, setIsAddModeThirdStepOn, integrationDetails, setIntegrationDetails } = props;
    const { t } = useTranslation();
    const handleNameChange = (event) => {
        setIntegrationDetails({
            ...integrationDetails,
            integrationName: event.target.value
        });
    }

    const goToConfigureIntegration = () => {
        setIsAddModeSecondStepOn(false);
        setIsAddModeThirdStepOn(true);
    }

    return (
        <Box
            component="form"
            sx={{
                display: "flex",
                maxWidth: "640px",
                paddingLeft: { xs: "20px", md: "48px" },
                alignItems: "flex-start",
                gap: "16px",
                justifyContent: "flex-start",
                flexWrap: "wrap",
            }}
        >
            <TextInput
                width={'400px'}
                placeholder={t('add_integration.integration_name')}
                value={integrationDetails.integrationName}
                onChange={(event) => handleNameChange(event)} />
            <Button id="continueButton" disabled={integrationDetails.integrationName === ""} onClick={goToConfigureIntegration} style={{marginTop: '7px'}}>
                {t('add_integration.continue')}
                <EastIcon sx={{maxWidth: '2.5vw', maxHeight: '2.5vh'}}/>
            </Button>
        </Box>
    )
}