import * as React from "react";
import { Box, Typography, Menu, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {styled} from "@mui/material/styles";
import Divider from "@mui/material/Divider";

function GroupMenu(props) {
    const {anchorEl, handleClose, ownedGroups, setOpenCreateNewGroup} = props

    const open = Boolean(anchorEl);

    const StyledMenu = styled(Menu)({
        "& .MuiPaper-root": {
            borderRadius: "8px",
            border: "1px solid #d7d7d7",
            backgroundColor: "#fff",
            width: "262px",
            padding: "4px 0",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
            marginTop: "5px"
        }
    });

    const handleCreateNewGroup = () => {
        setOpenCreateNewGroup(true);
        handleClose(null);
    }

    function DropdownHeader() {
        return (
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    width: '100%',
                    gap: '8px',
                    justifyContent: 'start',
                    cursor: 'pointer',
                    color: '#000',
                    padding: '0px',
                }}
            >
                <AddIcon
                    sx={{
                        aspectRatio: '1',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        width: '24px',
                        alignSelf: 'stretch',
                        margin: 'auto 0'
                    }}
                />
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        flex: 1,
                        flexBasis: '0%',
                        margin: 'auto 0'
                    }}
                >
                    Create new group
                </Typography>
            </Box>
        );
    }

    function DropdownItem({ text, onClick }) {
        return (
            <MenuItem
            className="groupMenuItem"
                sx={{
                    alignSelf: 'stretch',
                    flex: 1,
                    backgroundColor: '#fff',
                    width: '100%',
                    gap: '8px',
                    padding: '12px 16px',
                    cursor: 'pointer'
                }}
                onClick={onClick}
            >
                <Typography>{text}</Typography>
            </MenuItem>
        );
    }

    return (

            <StyledMenu className="gruopsOptionMenu" anchorEl={anchorEl} open={open} onClose={() => handleClose(null)}>
                <DropdownItem text={<DropdownHeader/>} onClick={handleCreateNewGroup}/>
                <Divider/>
                <MenuItem disabled sx={{padding: '0px'}}>
                    <Typography
                        variant="h6"
                        sx={{
                            alignSelf: 'stretch',
                            width: '100%',
                            fontSize: '14px',
                            fontWeight: '600',
                            letterSpacing: '0.14px',
                            padding: '8px 16px 4px'
                        }}
                    >
                        My groups:
                    </Typography>
                </MenuItem>
                {ownedGroups.map((group, index) => (
                    <DropdownItem key={index} text={group.name} onClick={() => handleClose(group.containerId)} />
                ))}
            </StyledMenu>
    );
}

export default GroupMenu;