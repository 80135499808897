import { Box, Typography, styled } from "@mui/material";

export function InfoBlock({ label, value }) {
  return (
    <Container>
      <Label variant="h3">{label}</Label>
      <Value className={label.replace(/\s/g, '')}>{value}</Value>
    </Container>
  );
}

const Container = styled(Box)({
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "auto",
  margin: "auto 0",
  "@media (max-width: 991px)": {
    whiteSpace: "initial"
  }
});

const Label = styled(Typography)({
  color: "var(--Gray-7, #5f5f5f)",
  fontWeight: 600,
  margin: 0,
  fontSize: "inherit",
  lineHeight: "inherit"
});

const Value = styled(Typography)({
  color: "var(--Gray-9, #202020)",
  fontWeight: 400,
  marginTop: "4px",
  fontSize: "inherit",
  lineHeight: "inherit"
});