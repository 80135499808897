import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {useTranslation} from "react-i18next";
import {ACTION_TYPE, context} from "../State";
import {styled} from "@mui/material/styles";
import {ThreeDotsIcon} from "../img/3-dots-icon";
import IconButton from "@mui/material/IconButton";


export default function SelectItemList(props) {
    const { rowId, tableId, rowExpirationDate, rowLicenseType, groups, setInviteUserIntegrationId, setInviteUserIntegrationDomain, setIntegrationToDelete } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectMenuData, setSelectMenuData] = React.useState(null);
    const {dispatch} = React.useContext(context);
    const {t} = useTranslation();
    let today = new Date().getTime();

    const handleDialogClose = () => {
        setAnchorEl(null)
    };

    const handleClick = (event) => {
        let expired = isExpired()
        let canClick = !(expired && (rowLicenseType == 'Licensed'))
        if (canClick) {
            let integration = groups[tableId].integrations.find(integration => integration.id === rowId);
            setSelectMenuData({
                domainVerified: integration.domainVerified,
                clientCertificateMdEnabled: integration.authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED,
                clientCertificateSdEnabled: integration.authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED,
                clientCertificateByodEnabled: integration.authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED,
                integrationType: integration.clientType !== null ? integration.clientType : integration.integrationType,
                isShared: integration.shared
            })
            setAnchorEl(event.currentTarget);
        }
    };

    function isExpired() {
        return rowExpirationDate - today <= 0;
    }

    function handleOpenDialogInviteUser() {
        let integration = groups[tableId].integrations.find(integration => integration.id === rowId);
        setInviteUserIntegrationId(integration.id);
        setInviteUserIntegrationDomain(integration.domain);
        handleDialogClose();
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER, payload: true});
    }

    function handleOpenDialogDeleteIntegration() {
        let integration = groups[tableId].integrations.find(integration => integration.id === rowId);
        handleDialogClose();
        setIntegrationToDelete(integration);
        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: true})
    }

    return (
            <>
                <IconButton className="optionsButton" sx={{width: '40px', height: '40px'}} onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e);
                }}>
                    <ThreeDotsIcon/>
                </IconButton>
                <Menu
                    onClick={e => e.stopPropagation()}
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    sx={{
                        "& .MuiPaper-root": {
                            borderRadius: "8px",
                            border: "1px solid #d7d7d7",
                            backgroundColor: "#fff",
                            width: "220px",
                            padding: "4px 0",
                            boxShadow: "0px rgba(0, 0, 0, 0.05)"
                        }
                    }}
                    onClose={handleDialogClose}

                >
                    <StyledMenuItem
                        id="inviteUserOption"
                        disabled={ selectMenuData && (!selectMenuData.domainVerified || ((selectMenuData.CLIENT_CERTIFICATE_SD_ENABLED || selectMenuData.CLIENT_CERTIFICATE_MD_ENABLED) && !selectMenuData.CLIENT_CERTIFICATE_BYOD_ALLOWED) || isExpired())}
                        className={ (selectMenuData && (!selectMenuData.domainVerified || ((selectMenuData.CLIENT_CERTIFICATE_SD_ENABLED || selectMenuData.CLIENT_CERTIFICATE_MD_ENABLED) && !selectMenuData.CLIENT_CERTIFICATE_BYOD_ALLOWED)))? "domainNotVerifiedOrCBEwithOutByodEnabled" : "inviteUserOptionEnabled"}
                        onClick={handleOpenDialogInviteUser}>{t('select_menu.invite_user')}
                    </StyledMenuItem>
                    <StyledMenuItem id="deleteIntegrationOption" className={selectMenuData && selectMenuData.isShared? "integrationIsShared" : "enableToDeleteIntegration"}  disabled={selectMenuData && selectMenuData.isShared} onClick={handleOpenDialogDeleteIntegration} sx={{color: '#FF0100', border: '0px'}}>
                        {t('select_menu.delete_integration')}
                    </StyledMenuItem>
                </Menu>
            </>);

}

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: "#fff",
    padding: "12px 16px",
    color: "#000",
    letterSpacing: "0.09px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    lineHeight: 1,
    fontWeight: 400,
    justifyContent: "flex-start",
    textAlign: "left",
    transition: "background-color 0.2s ease",

    "&:hover": {
        backgroundColor: "#f5f5f5"
    },

    "&:focus-visible": {
        outline: "0px",
        backgroundColor: "#fff",
    },

    "&:active": {
        backgroundColor: "#e0e0e0"
    }
});