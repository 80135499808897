
import oktaIcon from "../img/okta.png";
import googleIcon from "../img/google.svg";
import salesforceIcon from "../img/salesforce.png";
import zendeskIcon from "../img/zendesk.png";
import officeIcon from "../img/office.png";
import awsIcon from '../img/aws.png';
import oidcIcon from "../img/OIDC.png";
import teamviewerIcon from "../img/teamviewer.png"
import keeperIcon from "../img/keeper.png"
import customSaml from "../img/SAML.png"

let MICROSOFT = "Microsoft";
let OKTA = "Okta";
let SALESFORCE = "Salesforce";
let AWS = "AWS Single Sign On";
let GSUITE = "Google";
let ZENDESK = "Zendesk";
let CUSTOM = "SAML application";
let OIDC = "OIDC application";
let TEAMVIEWER = "TeamViewer";
let KEEPER = "Keeper";

export default class Integration {
    constructor(id,
                imagePath,
                name,
                domain,
                licenseType ,
                contractId,
                licenseLimit,
                licenseTotal,
                enrolledUsers,
                scim,
                scimStatus,
                branding,
                expirationDate,
                numberOfUsers,
                domainVerified,
                integrationType) {
        this._id = id;
        this._imagePath = imagePath;
        this._name = name;
        this._domain = domain;
        this._licenseType = licenseType;
        this._contractId = contractId;
        this._licenseLimit = licenseLimit;
        this._licenseTotal = licenseTotal;
        this._enrolledUsers = enrolledUsers;
        this._scim = scim;
        this._scimStatus = scimStatus;
        this._branding = branding;
        this._expirationDate = expirationDate;
        this._numberOfUsers = numberOfUsers;
        this._domainVerified = domainVerified;
        this._integrationType= integrationType;
    }

    get id() {
        return this._id;
    }

    get imagePath() {
        return this._imagePath;
    }

    get name() {
        return this._name;
    }

    get domain() {
        return this._domain;
    }

    get licenseType() {
        return this._licenseType;
    }

    get lUsers() {
        return this._lUsers;
    }

    get enrolledUsers() {
        return this._enrolledUsers;
    }

    get scim() {
        return this._scim;
    }

    get scimStatus() {
        return this._scimStatus;
    }

    get branding() {
        return this._branding;
    }

    get expirationDate() {
        return this._expirationDate;
    }

    get licenseLimit() {
        return this._licenseLimit;
    }

    get licenseTotal() {
        return this._licenseTotal;
    }

    get numberOfUsers() {
        return this._numberOfUsers;
    }

    get domainVerified() {
        return this._domainVerified;
    }
    get integrationType() {
        return this._integrationType;
    }

    get contractId() {
        return this._contractId;
    }

    static tableHeaders() {
        return [
            '',
            'Name',
            'Domain',
            'License-Type',
            'Licensed Users',
            'Enrolled Users',
            'SCIM on/off',
            'SCIM status',
            '',
            ''
        ];
    }
    
    

    /**
     * Converts Integration obj gotten from the server to the one used for displaying in the view
     * @param integration
     * @returns {Integration}
     */
    static toIntegration(integration) {
        let type = integration.clientType !== null ? integration.clientType : integration.integrationType;
        var integrationIcon = null;
        var title = null;
        switch(type) {
            case "OKTA":
                integrationIcon = oktaIcon;
                title = OKTA;
                break;
            case "MICROSOFT":
                integrationIcon = officeIcon;
                title = MICROSOFT;
                break;
            case "GSUITE":
                integrationIcon = googleIcon;
                title = GSUITE;
                break;
            case "SALESFORCE":
                integrationIcon = salesforceIcon;
                title = SALESFORCE;
                break;
            case "AWS":
                integrationIcon = awsIcon;
                title = AWS;
                break;
            case "ZENDESK":
                integrationIcon = zendeskIcon;
                title = ZENDESK;
                break;
            case "TEAMVIEWER":
                integrationIcon = teamviewerIcon;
                title = TEAMVIEWER;
                break;
            case "OIDC":
                integrationIcon = oidcIcon;
                title = OIDC;
                break;
            case "KEEPER": 
                integrationIcon = keeperIcon;
                title = KEEPER;
                break;
            case "CUSTOM":
                integrationIcon = customSaml;
                title = CUSTOM;
                break;
        }

        return new Integration(
            integration.id,
            integrationIcon,
            integration.integrationName,
            integration.domain,
            integration.licenseType,
            integration.contract.contractId,
            integration.licenseLimit,
            integration.licenseTotal,
            integration.enrolledUsers,
            integration.scimEnabled,
            integration.scimStatus,
            integration.branding,
            integration.expirationDate,
            integration.numberOfUsers,
            integration.domainVerified,
            integration.integrationType
        )
        }

}