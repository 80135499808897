import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { context, ACTION_TYPE } from "../../State";
import {useEffect, useRef} from "react";
import Group from '../../components/Group'
import {log, tryLocalStorageGetItem, tryLocalStorageSetItem} from "../../helpers/utils";
import { Button } from "../../components/CustomUI";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadIcon from '@mui/icons-material/Upload';
import CircleIcon from '@mui/icons-material/Circle';
import { DragDropContext } from "react-beautiful-dnd";
import Api from "../../helpers/service";
import GroupObj from "../../model/Group";
import CircularProgress from '@mui/material/CircularProgress';
import CustomSnackbar from "../../components/Snackbar";
import CustomDialog from '../../components/DialogText';
import Drawer from '../../components/Drawer';
import { CustomTooltip } from '../../components/CustomUI';
import AddIcon from '@mui/icons-material/Add';
import { AdminIcon } from '../../img/addmin-icon';
import TabsComponent from "../../components/TabsComponent";
import {SearchUser} from "../../components/SearchUser";
import CustomDialogTextBox from "../../components/DialogTextBox";
import ManageAllGroupsDialog from "../../components/ManageAllGroupsDialog";


export default function IntegrationsPage() {

    const [isShown, setIsShown] = React.useState(null);
    const [openAdminDialog, setOpenAdminDialog] = React.useState(false);
    const [didLoad, setDidLoad] = React.useState(false);
    const [hasIntegrations, setHasIntegrations] = React.useState(false);
    const [groups, setGroups] = React.useState([]);
    const {t} = useTranslation();
    const {state, dispatch} = React.useContext(context);
    const [isAddModeOn, setIsAddModeOn] = React.useState(false);
    const [isEditModeOn, setIsEditModeOn] = React.useState(false);
    const [isViewModeOn, setIsViewModeOn] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [isSharedGroup, setIsSharedGroup] = React.useState(false);
    const [inviteMenuIndex, setInviteMenuIndex] = React.useState(null);
    const [profileEmail, setProfileEmail] = React.useState(null);
    const [ownedGroups, setOwnedGroups] = React.useState([]);
    const [inviteUserIntegrationId, setInviteUserIntegrationId] = React.useState(null);
    const [inviteUserIntegrationDomain, setInviteUserIntegrationDomain] = React.useState(null);
    const [integrationToDelete, setIntegrationToDelete] = React.useState(null);
    const [repeatSearchUser, setRepeatSearchUser] = React.useState(false);

    const groupRef = useRef([]);
    const api = new Api();


    const handleClose = () => {
        setIsShown(false);
    };

    useEffect(
        () => {
            loadIntegrations();
            if (tryLocalStorageGetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK) === 'true') {
                dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, payload: true});
                tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, false);
            }

            if (tryLocalStorageGetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK) === 'true') {
                dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: true});
                tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, false);
            }
        },
        []
    );

    function loadIntegrations() {
        let defaultContainer = null;
        api.getDefaultContainerId().then((res) => { defaultContainer = res.data});
        api.getIntegrationsByOwner().then(function (res) {
            let groupArray = [];
            let ownedGroupsArray = [];
            Object.entries(res).map(group => {
                const containerId = group[0];
                const integrations = group[1];
                const containerName = integrations[0].containerName;
                const ownerEmail = integrations[0].ownerEmail;
                let groupObj = createGroup(containerName, ownerEmail, containerId, integrations, false);
                groupArray.push(groupObj)
                if (!groupObj.shared) {
                    ownedGroupsArray.push(groupObj);
                }
            });
            let notSharedGroupArray = groupArray.filter(function (group) {
                return group.shared === false;
            });
            if (notSharedGroupArray.length === 0) {
                let groupDefault = createGroup(defaultContainer.name,'Me', defaultContainer.containerId, [], true);
                groupArray.unshift(groupDefault);
            }
            groupArray = groupArray.sort(function (x, y) {return x.shared - y.shared})
            setGroups(...groups, groupArray);
            setOwnedGroups(...ownedGroups, ownedGroupsArray);
            setDidLoad(true);
            setHasIntegrations(groupArray.length > 0);
        }).catch(function (error) {
            log(error);
        })
    }

    function createGroup(groupName, owner, containerId, integrations, isDefaultGroup) {
        let isShared = isDefaultGroup === false ? integrations[0].shared : false;
        let group = new GroupObj();
        group.name = isShared ? "Shared group" : (groupName ? groupName : "My group");
        group.owner = isShared ? owner : 'Me';
        group.containerId = containerId;
        group.shared = isShared;
        group.color = isShared ? "#F4F5F4" : "#EAEFF8";
        group.integrations = integrations.sort(function (x, y) {
            return x.creationTimestamp - y.creationTimestamp;
        });
        group.dragAndDrop = !isShared;
        return group;
    }

    // define group actions
    const groupActions = (id) => (
        < >
            <CustomTooltip title={t('tooltip.delete_group')} placement='top'>
                <DeleteForeverIcon style={{webkitMaskImage: '-webkit-radial-gradient(white, black)'}}
                                   sx={{
                                       marginRight: '20px',
                                       display: 'none',
                                       '&:hover': {backgroundColor: '#FFFFF7', borderRadius: 18}
                                   }}
                                   onClick={(e) => deleteGroup(id, e)}
                />
            </CustomTooltip>

            <CustomTooltip title={t('tooltip.transfer_group')} placement='top'>
                <UploadIcon style={{webkitMaskImage: '-webkit-radial-gradient(white, black)'}}
                            sx={{
                                marginRight: '20px',
                                display: 'none',
                                '&:hover': {backgroundColor: '#FFFFF7', borderRadius: 18}
                            }}
                            onClick={(e) => shareGroup(id, e)}
                />
            </CustomTooltip>

            <CustomTooltip title={t('tooltip.manage_admins')} placement='top'>
                <AdminIcon style={{webkitMaskImage: '-webkit-radial-gradient(white, black)', borderRadius: '50%'}}
                           onClick={(e) => manageGroupAdmin(id, e)}
                           onMouseOver={(e) => e.target.style.background = '#FFFFF7'}
                           onMouseLeave={(e) => e.target.style.background = 'transparent'}
                />
            </CustomTooltip>
            <Button
                sx={{marginLeft: '20px', marginBottom: '0px', marginTop: '0px'}}
                onClick={handleOpenAddIntegration}
            >
                <AddIcon sx={{maxWidth: '2.5vw', maxHeight: '2.5vh'}}/>
                {t('add_integration.add_integration_button_heading')}
            </Button>
        </>
    );

    const deleteGroup = (groupId, event) => {
        event.stopPropagation();
        if (groups[groupId].tableRows.length > 0) {
            alert("Cannot delete group with integrations in it!")
        } else {
            const newState = [...groups];
            newState.splice(groupId, 1);
            setGroups(newState);
        }
    };

    const shareGroup = (groupId, event) => {
        event.stopPropagation();
    };

    const manageGroupAdmin = (groupId, event) => {
        event.stopPropagation();
        setOpenAdminDialog(true)
    };

    const addIntegrationToGroup = (groupId, event) => {
        event.stopPropagation();
        // get the last id in all groups
        let rows = [];
        {
            groups.map((group, index) => (
                rows.push(...group.tableRows)
            ))
        }
        rows = rows.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        let lastIndex = parseInt(rows[rows.length - 1].id) + 1;

        let newIntegration = createIntegration(`${lastIndex}`, `Integration-${lastIndex}`, "getidee.de", "Trial: 12days left", "-", "1", "on", "4 mins ago",
            <CircleIcon style={{color: lastIndex % 2 === 0 ? "cyan" : "yellow"}}/>, false)
        const newState = [...groups];
        newState[groupId].tableRows.push(newIntegration);
        setGroups(newState);
    };

    // end group actions

    function createIntegration(id, name, domain, licenceType, lUsers, eUsers, scim, scimStatus, branding, isTrialExpired) {
        return {id, name, domain, licenceType, lUsers, eUsers, scim, scimStatus, branding, isTrialExpired};
    }

    const getMockIntegrations = (count, offset = 0) =>
        Array.from({length: count}, (v, k) => k).map(k => (
                createIntegration(`${k + offset}`, `Integration-${k + offset}`, "getidee.de", "Trial: 12days left", "-", "1", "on", "4 mins ago",
                    <CircleIcon style={{color: k % 2 === 0 ? "cyan" : "yellow"}}/>, false)
            )
        );
    log("Integration data");
    log(state.integrationData);

    const handleSnackAddedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_SNACK, payload: false});
    }

    const handleSnackChangedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: false});
    }

    const handleSnackAddedOIDCIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_ADDED_OIDC_SNACK, payload: false});
    }

    const handleSnackCreateAzureUserClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({type: ACTION_TYPE.SHOW_CREATE_AZURE_USER_SNACK, payload: false});
    }

    const handleSnackDeletedIntegrationClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, false);
        dispatch({type: ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, payload: false});
    }

    const handleSnackDeletedDeviceClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, false);
        dispatch({type: ACTION_TYPE.SHOW_DEVICE_DELETED_SNACK, payload: false});
    }

    const handleSnackDeletedUserClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_USER_DELETED_SNACK, false);
        dispatch({type: ACTION_TYPE.SHOW_USER_DELETED_SNACK, payload: false});
    }

    function handleOpenViewDetailsIntegration() {
        handleClose();
        dispatch({type: ACTION_TYPE.CONTAINER_ID, payload: null});
        setIsViewModeOn(true);
        setIsEditModeOn(false);
        setOpenDrawer(true);
    }

    function handleOpenAddIntegration() {
        setIsAddModeOn(true);
        setOpenDrawer(true);
    }

    const handleRowInfoClicked = (index, event, rowId, tableId) => {

        let integration = groups[tableId].integrations.find(integration => integration.id === rowId);
        log("Integration logs....");
        log(integration);
        if (integration) {
            //decide about login method, fallback login method, number of devices
            let loginMethod = "Web-AuthN";
            let fallbackMethod = "none";
            let numberOfDevices = "Multi-device";
            if (integration.authenticationSettingsDto.AUTHN_ENABLED) {
                loginMethod = "AuthN";
                numberOfDevices = "Multi-device";
            } else if (integration.authenticationSettingsDto.WEBAUTHN_ENABLED) {
                if (integration.authenticationSettingsDto.CMLA_ENABLED) {
                    fallbackMethod = "Secure Magic-Link";
                }
            } else if (integration.authenticationSettingsDto.CMLA_ENABLED) {
                loginMethod = "Secure Magic-Link";
                numberOfDevices = "none";
            } else if (integration.authenticationSettingsDto.WEBAUTHN_ACCESS_KEY_ENABLED) {
                loginMethod = "Web-AuthN + Access Key"
                numberOfDevices = "Multi-device"
            } else {
                loginMethod = "none";
                fallbackMethod = "none";
                numberOfDevices = "none";
            }
            dispatch({
                type: ACTION_TYPE.INTEGRATION_DATA,
                payload: {
                    domain: integration.domain,
                    integrationId: integration.id,
                    integrationType: integration.clientType !== null ? integration.clientType : integration.integrationType,
                    siteId: integration.siteId,
                    clientMetadataId: integration.clientMetadataId,
                    contract: integration.contract,
                    integrationName: integration.integrationName,
                    containerId: integration.containerId,
                    loginMethod: loginMethod,
                    fallbackMethod: fallbackMethod,
                    numberOfDevices: numberOfDevices,
                    metadataUrl: integration.metadataUrl,
                    authenticationSettingsDto: integration.authenticationSettingsDto,
                    isShared: integration.shared,
                    numberOfSyncedUsers: integration.numberOfSyncedUsers,
                    scimEndpointUrl: integration.scimEndpointUrl,
                    scimEnabled: integration.scimEnabled,
                    scimSecret: integration.scimSecret,
                    licenseType: integration.licenseType,
                    expirationDate: integration.expirationDate,
                    startDate: integration.startDate,
                    clientId: integration.clientId,
                    siemEnabled: integration.siemEnabled,
                    workspaceId: integration.workspaceId,
                    serverHostname: integration.serverHostname,
                    serverPort: integration.serverPort,
                    siemType: integration.siemType,
                    serverName: integration.serverName,
                    domainVerified: integration.domainVerified,
                    oidcAuthenticationMethod: integration.authenticationMethod ? integration.authenticationMethod.toLowerCase() : null,
                    oidcSigningAlgorithm: integration.supportedSigningAlgorithm,
                    oidcEncryptionAlgorithm: integration.supportedEncryptionAlgorithm === null ? "none" : integration.supportedEncryptionAlgorithm,
                    oidcEncryptionMethod: integration.supportedEncryptionMethod === null ? "none" : integration.supportedEncryptionMethod
                }
            });
            dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: integration.authenticationSettingsDto });
        }
    };

    function handleDialogIntegrationDelete() {
        log("Integration to delete: ");
        log(integrationToDelete);
        let integrationType = integrationToDelete.clientType ? integrationToDelete.clientType : integrationToDelete.integrationType;
        switch (integrationType) {
            case "MICROSOFT":
            case "SALESFORCE":
            case "OKTA":
            case "ZENDESK":
            case "AWS":
            case "TEAMVIEWER":
            case "KEEPER":
                api.deleteIntegration(integrationToDelete.id).then(function () {
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                });
                break;
            case "CUSTOM":
                if (integrationToDelete.clientMetadataId === null) {
                    api.deleteUrl(integrationToDelete.id).then(function () {
                        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                        window.location.reload();
                    }).catch(function (error) {
                        log(error);
                        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                    });
                } else {
                    api.deleteIntegration(integrationToDelete.id).then(function () {
                        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                        window.location.reload();
                    }).catch(function (error) {
                        log(error);
                        dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                    });
                }
                break;
            case "GSUITE":
                api.deletegSuiteClientandSDKConnection(integrationToDelete.siteId).then(function () {
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                });
                break;
            case "OIDC":
                api.deleteOIDCIntegration(integrationToDelete.id).then(function () {
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                    tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_DELETED_SNACK, true);
                    window.location.reload();
                }).catch(function (error) {
                    log(error);
                    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload: false});
                });


        }
    }

    // end group content

    // drag and drop helper methods

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        let destClone = Array.from(destination);

        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;

        // dropped outside the table
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        log(`dragEnd ${result.source.index} to  ${result.destination.index}`);

        if (sInd === dInd) { // same table
            const items = reorder(groups[sInd].integrations, source.index, destination.index);
            const newState = [...groups];
            newState[sInd].integrations = items;
            setGroups(newState);
        } else { // move between tables
            let draggedIntegration =  groups[sInd].integrations[source.index];
            let groupToDrop = groups[dInd];

            const result = move(groups[sInd].integrations, groups[dInd].integrations, source, destination);
            const newState = [...groups];
            newState[sInd].integrations = result[sInd];
            newState[dInd].integrations = result[dInd];
            setGroups(newState);

            let oidcIntegrations = [];
            let samlIntegrations = [];
            let draggedIntegrationType = draggedIntegration.integrationType;
            let draggedIntegrationId = draggedIntegration.id;


            if (draggedIntegrationType === 'OIDC') {
                oidcIntegrations.push(draggedIntegrationId);
            } else {
                samlIntegrations.push(draggedIntegrationId);
            }

            api.moveIntegrations(groupToDrop.containerId, oidcIntegrations, samlIntegrations);
        }
    };

    // end drag and drop helper methods

    const NoIntegrationView = (
        <Box sx={{
            width: '90%',
            padding: '150px 50px',
            textAlign: 'center',
            border: '2px dashed #D6D6D6',
            borderRadius: '36px',
            marginTop: '35px'
        }}>
            <Typography variant="h3" component="div" gutterBottom
                        sx={{maxWidth: '420px', margin: '5px auto', fontSize: '19px'}}>
                Seems like you haven’t added an integration, yet. Add your first one in just 10 minutes.
            </Typography>
            <Button
                sx={{maxWidth: 140, margin: '15px'}}
                onClick={handleOpenAddIntegration}
            >
                + Add integration
            </Button>
        </Box>
    )

    return (
        <Box sx={{width: 'inherit', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="h1" sx={{marginLeft: '16px'}}> Dashboard </Typography>
            {didLoad && hasIntegrations &&
                <TabsComponent  firstTabTitle={"Integrations"}
                                secondTabTitle={"Users"}
                                firstTabContent={<div>
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        {groups.map((group, index) => (
                                                            <Group groupName={group.name}
                                                                   groupContainerId={group.containerId}
                                                                   groupDescription={group.owner}
                                                                   groupActions={group.shared ? '' : groupActions(index)}
                                                                   groupColor={group.color}
                                                                   tableHeaders={group.tableHeaders}
                                                                   tableRows={group.tableRows}
                                                                   isShared={group.shared}
                                                                   rowInfoClicked={handleRowInfoClicked}
                                                                   dragAndDrop={group.dragAndDrop}
                                                                   id={`${index}`}
                                                                   ref={el => groupRef.current[index] = el}
                                                                   setAddModeOn={setIsAddModeOn}
                                                                   setOpenDrawer={setOpenDrawer}
                                                                   handleOpenViewDetailsIntegration={handleOpenViewDetailsIntegration}
                                                                   ownedGroups={ownedGroups}
                                                                   setOwnedGroups={setOwnedGroups}
                                                                   groups={groups}
                                                                   setInviteUserIntegrationId={setInviteUserIntegrationId}
                                                                   setInviteUserIntegrationDomain={setInviteUserIntegrationDomain}
                                                                   setIntegrationToDelete={setIntegrationToDelete}
                                                                   setIsSharedGroup={setIsSharedGroup}
                                                                   />
                                                        ))}
                                                    </DragDropContext>

                                                    <Drawer isEditModeOn={isEditModeOn} isAddModeFirstStepOn={isAddModeOn} isViewModeOn={isViewModeOn}
                                                            isNotificationsModeOn={state.openNotificationsDrawer}
                                                            setIsViewModeOn={setIsViewModeOn} setIsEditModeOn={setIsEditModeOn}
                                                            setIsAddModeFirstStepOn={setIsAddModeOn}
                                                            openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}
                                                            isSharedGroup={isSharedGroup}
                                                            setIsSharedGroup={setIsSharedGroup}/>
                                                </div>}


                                secondTabContent={<SearchUser setInviteMenuIndex={setInviteMenuIndex}
                                                              profileEmail={profileEmail}
                                                              setProfileEmail={setProfileEmail}
                                                              groups={groups}
                                                              setRepeatSearchUser={setRepeatSearchUser}
                                                              repeatSearchUser={repeatSearchUser}/>}
                />

            }
            {didLoad && !hasIntegrations && NoIntegrationView}
            {!didLoad &&
                <CircularProgress color="primary" sx={{ position: 'absolute', top: '50%', left: '50%' }} />
            }
            <CustomSnackbar open={state.showIntegrationAddedSnack} onCancel={handleSnackAddedIntegrationClose}
                type={'info'} vertical={'bottom'} horizontal={'right'}
                text={t('notification.info_added_integration')} buttonText={t('notification.okay')}
                showIcon='false' />
            <CustomSnackbar open={state.showIntegrationChangedSnack} onCancel={handleSnackChangedIntegrationClose}
                type={'success'} vertical={'bottom'} horizontal={'right'}
                text={t('notification.changes_saved')} buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showIntegrationDeletedSnack} onCancel={handleSnackDeletedIntegrationClose}
                type={'success'} vertical={'bottom'} horizontal={'right'}
                text={t('notification.deleted_integration')} buttonText={t('notification.okay')}
                showIcon='true' />
            <CustomSnackbar open={state.showIntegrationAddedOIDCSnack} onCancel={handleSnackAddedOIDCIntegrationClose}
                type={'success'} vertical={'bottom'} horizontal={'right'}
                text={t('notification.info_added_oidc_integration')} buttonText={t('notification.okay')}
                showIcon='true' />
            <CustomSnackbar open={state.showCreateAzureUserSnack} onCancel={handleSnackCreateAzureUserClose}
                type={'success'} vertical={'bottom'} horizontal={'right'} text={state.createdAzureUserLink}
                buttonText={t('notification.okay')} showIcon='true' />
            <CustomSnackbar open={state.showDeviceDeletedSnack} onCancel={handleSnackDeletedDeviceClose}
                type={'success'} vertical={'bottom'} horizontal={'right'}
                text={t('notification.device_deleted')} buttonText={t('notification.okay')}
                showIcon='true' />
            <CustomSnackbar open={state.showUserDeletedSnack} onCancel={handleSnackDeletedUserClose} type={'success'}
                vertical={'bottom'} horizontal={'right'} text={t('notification.user_deleted')}
                buttonText={t('notification.okay')} showIcon='true' />
            <CustomDialog
                id="deleteIntegrationDialog"
                open={state.isOpenedDialogDeleteIntegration}
                onConfirm={handleDialogIntegrationDelete}
                title={t('dialog.heading_delete_integration')}
                subtitle={t('dialog.text_delete_integration', { integration_name: state.integrationData.integrationName })}
                confirmBtnText={t('dialog.button_confirm_delete_integration')}
                cancelBtnText={t('dialog.button_cancel')}
                hideCloseButton={true}
                isCentered={true} />
            <CustomDialogTextBox id = "inviteUserDialog"
                                 prefilledEmail={profileEmail}
                                 inviteMenuIndex={inviteMenuIndex}
                                 setInviteMenuIndex={setInviteMenuIndex}
                                 inviteUserIntegrationId={inviteUserIntegrationId}
                                 setInviteUserIntegrationId={setInviteUserIntegrationId}
                                 inviteUserIntegrationDomain={inviteUserIntegrationDomain}
                                 setInviteUserIntegrationDomain={setInviteUserIntegrationDomain}
                                 open={state.isOpenedDialogInviteNewUser}
                                 title={t('dialog.heading_invite_new_user')}
                                 label={t('dialog.enter_email_address')}
                                 confirmBtnText={t('dialog.create_magic_link')}
                                 confirmBtnTextAdditional={t('dialog.create_access_key')}
                                 cancelBtnText={t('dialog.button_cancel')}
                                 authenticationSettingsDto = {state.integrationData.authenticationSettingsDto}
                                 setRepeatSearchUser={setRepeatSearchUser}/>

            <ManageAllGroupsDialog open={state.isOpenedDialogManageAllGroups} groups={groups} setGroups={setGroups} />
        </Box>
    );
}
