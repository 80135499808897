import React, {useEffect} from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import { lowerAndTrimEmail, validateEmail} from "../helpers/utils";
import Api from "../helpers/service";
import TextInput from "./TextInput";

export default function SelectNewOwner(props) {
    const {open, onClose, anchorEl, admins, setCurrentEmail, setOpenTransferGroupOwnershipDialog} = props;

    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorTxt, setErrorTxt] = React.useState('');
    const [email, setEmail] = React.useState('');
    let validState = false
    const {t} = useTranslation();
    const api = new Api();

    useEffect(() => {
        setErrorTxt('');
        setOpenDialog(open);
    }, [open]);

    useEffect(() => {
        const adminEmailInputDelay = setTimeout(() => {
            checkEmail()
        }, 500);

        return () => clearTimeout(adminEmailInputDelay);
    }, [email])

    async function checkEmail() {
        let emailEdited = lowerAndTrimEmail(email);
        if (emailEdited !== '') {
            if (!validateEmail(emailEdited)) {
                setErrorTxt(t('dialog.error_text'));
                validState = false
            } else {
                await api.checkIfAdminExists(emailEdited).then(function () {
                    setErrorTxt('');
                    validState = true
                }).catch(function () {
                    setErrorTxt(t('admin_access.admin_not_found'));
                    validState = false
                })
            }
        }
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
        onClose();
        setEmail('');
    };

    const handleTransferOwnership = (email) => {
        setCurrentEmail(email);
        setOpenTransferGroupOwnershipDialog(true);
        handleDialogClose();
    };

    const handleTextChange = (e) => {
        setEmail(e.target.value);
        setErrorTxt('');
        validState = true
    };

    const onKeyPress = async (e) => {
        if(e.key === 'Enter' && errorTxt === '') {
            await checkEmail()
            if (validState) {
            setCurrentEmail(email);
            setOpenTransferGroupOwnershipDialog(true);
            e.stopPropagation();
            handleDialogClose();
            }
        }
    }


    return (
        <Menu
            keepMounted
            anchorEl={anchorEl}
            open={openDialog}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "8px",
                    border: "1px solid #d7d7d7",
                    backgroundColor: "#fff",
                    padding: "4px 0",
                    boxShadow: "0px rgba(0, 0, 0, 0)"
                }
            }}
            onClose={handleDialogClose}
        >
            <MenuItem disableRipple onKeyDown={(e) => e.stopPropagation()} sx={{
                backgroundColor: "#fff",
                padding: "8px 16px",
                "&:hover": {
                    backgroundColor: "#fff"
                },
                "&:focus-visible": {
                    outline: "0px",
                    backgroundColor: "#fff",
                },
                "&:active": {
                    backgroundColor: "#fff"
                },
                }}>
                <TextInput
                           id={"newGroupOwnerEmail"}
                           width={'248px'}
                           value={email}
                           placeholder={"Search or enter new email"}
                           onChange={handleTextChange}
                           setError={errorTxt !== ''}
                           errorText={errorTxt}
                           onKeyPress={onKeyPress}/>
            </MenuItem>
            {admins !==undefined && admins.map(email => (
                <StyledMenuItem key={email} sx={{gap: '60px'}} onClick={() => handleTransferOwnership(email)}>
                    {email}
                </StyledMenuItem>
            ))}
        </Menu>);
}

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: "#fff",
    padding: "8px 16px",
    color: "#000",
    letterSpacing: "0.09px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    lineHeight: '24px',
    fontWeight: 400,
    justifyContent: "flex-start",
    textAlign: "left",
    transition: "background-color 0.2s ease",
    gap: "8px",
    display: "flex",
    alignItems: "st",
    "&:hover": {
        backgroundColor: "#f5f5f5"
    },

    "&:focus-visible": {
        outline: "0px",
        backgroundColor: "#fff",
    },

    "&:active": {
        backgroundColor: "#e0e0e0"
    }
});