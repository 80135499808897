import React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {styled, Typography} from "@mui/material";
import Box from '@mui/material/Box';

const Snackbar = styled(MuiSnackbar)(() => ({
  boxShadow: '0px 3px 6px #00000060',
  opacity: 1
}));

const Alert = styled(MuiAlert)(() => ({
  font: 'normal normal normal 14px/20px Source Sans Pro',
  paddingLeft: '26px',
  maxWidth: '500px'
}))

export default function CustomSnackbar(props) {
    const {open, onCancel, type, text, buttonText, vertical, horizontal, showIcon, autoHide, isDomainFederation} = props;
    let icon = "";
    let color = "";
    let textColor = "";
    let isCustom = false;
    switch(type) {
        case 'success': 
            icon = showIcon === 'true' ? <CheckIcon fontSize="inherit" style={{height:'22px', width: '22px', color: "#00A825"}}/> : false
            color = "#E5FFEA"
            textColor = "#00A825"
            isCustom = false;
            break;
        case 'error': 
            icon = showIcon === 'true' ? <CloseIcon fontSize="inherit" style={{height:'22px', width: '22px', color: "#b52626"}} /> : false
            color = "#ffe5e5"
            textColor = "#b52626"
            isCustom = false;
            break;
        case 'info':
            icon = false;
            color = "#e5faff"
            textColor = "#0084a8"
            isCustom = false;
            break;
        case 'recommendation':
            icon = false;
            color = "#EEEEEE"
            textColor = "#000000"
            isCustom = true;
            break;
    }
    
    return (
      <div>
        <Snackbar
        className='snachBar'
        onClose={onCancel}
        open={open} 
        autoHideDuration={autoHide === 'false' ? null : 5000}
        anchorOrigin={{vertical, horizontal}}

        sx={{position: isDomainFederation === true ? 'absolute' : 'fixed',  bgcolor: color, color: textColor, borderRadius: '12px', border: '0px', fontWeight: 'normal', width: null, justifyContent: 'start',
            alignItems: 'flex-start',}}
        >
          <div>
          {isCustom && 
          <Alert 
            sx={{
              bgcolor: "inherit",
              color: "inherit",
              borderRadius: "inherit",
              border: "inherit",
              boxShadow: "inherit",
              '@media (min-width:2560px) ': {
                    
                maxWidth:'800px'
              }, 
              
            }} 
            icon={icon} 
            elevation="inherit"
            variant="outlined"
            >
              <Box sx={{display:'flex', 
              '@media (min-width:1366px) and (max-width:1919px)': {
                    
                maxWidth:'25vw', margin:'1%'
              }, 
              '@media (min-width:1920px) and (max-width: 2559px) ': {
                    
                margin:'2%'
              },  
              '@media (min-width:2560px) ': {
                    
                margin:'2%',
              }, 
            }}>
                <Typography sx={{
                   '@media (min-width:1366px) and (max-width:1919px)': {
                       fontSize:11,
                      
                   }, 
                   '@media (min-width:2560px) ': {
                       fontSize: 24,
                       
                   }, 
                }}>
                {text}
                </Typography>

                  <Box style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                     
                  }} sx={{width:'30%'}}>
                     <Button
                    className='closeSnackbar'
                    color="inherit" 
                    size="small"
                    onClick={onCancel}
                   
                    sx={{width:'50%', height:'50%', justifyContent:'center', alignItems:'center'}}
                    >
                      <Typography sx={{
                   '@media (min-width:1366px) and (max-width:1919px)': {
                    
                       fontSize:11,
                   }, 
                   '@media (min-width:2560px) ': {
                      
                    fontSize: 24,
                   }, 
                }} >
                        {buttonText}
                      </Typography>
                  </Button>
                </Box>
                
              </Box>
            
            
          </Alert>}
          {!isCustom && 
          <Alert 
            sx={{
              bgcolor: "inherit",
              color: "inherit",
              borderRadius: "inherit",
              border: "inherit",
              boxShadow: "inherit",
            }} 
            icon={icon} 
            elevation="inherit"
            variant="outlined"
            severity={type} 
            action={
              <Button
              id='closeSnackBarButton'
              color="inherit" 
              size="small" 
              onClick={onCancel}>
                {buttonText}
              </Button> }
          >
            {text}
          </Alert>}   
          </div>
          
        </Snackbar>
      </div>
    );
  }