import {styled} from "@mui/styles";
import {Typography} from "@mui/material";
import {Button, InverseButton} from "./CustomUI"
import {useTranslation} from "react-i18next";

export default function NotificationCard(props) {
    const {title, text, onAccept, onReject} = props;

    const { t } = useTranslation();
    const ActionButtons = () => {
        return (
            <ButtonContainer>
                <Button className="acceptNotificationButton" onClick={onAccept}> {t('group.notification_accept_button_text')} </Button>
                <InverseButton className="rejectNotificatinButton" onClick={onReject}> {t('group.notification_reject_button_text')} </InverseButton>
            </ButtonContainer>
        );
    };

    return (
        <NotificationCardWrapper>
            <Title> {title} </Title>
            <NotificationText> {text}</NotificationText>
            <ActionButtons></ActionButtons>
        </NotificationCardWrapper>
    )
};

const NotificationCardWrapper = styled("div")({
    alignSelf: "stretch",
    borderRadius: "12px",
    backgroundColor: "var(--Blue---primary-10, #f0f5ff)",
    display: "flex",
    maxWidth: "595px",
    flexDirection: "column",
    fontFamily: "Source Sans Pro, sans-serif",
    padding: "12px",
});

const Title = styled(Typography)({
    flex: 1,
    width: "100%",
    fontSize: "20px",
    fontWeight: 700,
    letterSpacing: "0.3px",
    lineHeight: 1.2,
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const NotificationText = styled(Typography)({
    flex: 1,
    marginTop: "4px",
    width: "100%",
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: "24px",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const ButtonContainer = styled("div")({
    alignSelf: "start",
    display: "flex",
    marginTop: "12px",
    alignItems: "start",
    gap: "12px",
    fontSize: "17px",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: 1,
});

