import React, {useEffect} from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import CheckIcon from "../img/check.svg";

export default function SelectAdminAccess(props) {
    const {open, onClose, anchorEl, setOpenAdminAccessRevokeDialog, setOpenTransferGroupOwnershipDialog, status} = props;

    const [openDialog, setOpenDialog] = React.useState(false);
    const [revokeCheckMark, setRevokeCheckMark] = React.useState(false);
    const [ownerCheckMark, setOwnerCheckMark] = React.useState(false);
    const [adminCheckMark, setAdminCheckMark] = React.useState(true);

    const {t} = useTranslation();

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOwnerCheckMark(false);
        setAdminCheckMark(true);
        setRevokeCheckMark(false);
        onClose();
    };

    function handleSetOwnerAccess() {
        setOwnerCheckMark(true);
        setAdminCheckMark(false);
        setRevokeCheckMark(false);

        setOpenDialog(false);
        handleDialogClose();
        setOpenTransferGroupOwnershipDialog(true);
    }

    function handleSetAdminAccess() {
        setOwnerCheckMark(false);
        setAdminCheckMark(true);
        setRevokeCheckMark(false);

        setOpenDialog(false);
        handleDialogClose();
    }

    function handleSetRevokeAccess() {
        setOwnerCheckMark(false);
        setAdminCheckMark(false);
        setRevokeCheckMark(true);

        setOpenDialog(false);
        handleDialogClose();
        setOpenAdminAccessRevokeDialog(true);
    }

    return (
        <Menu
            keepMounted
            anchorEl={anchorEl}
            open={openDialog}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "8px",
                    border: "1px solid #d7d7d7",
                    backgroundColor: "#fff",
                    width: "164px",
                    padding: "4px 0",
                    boxShadow: "0px rgba(0, 0, 0, 0)"
                }
            }}
            onClose={handleDialogClose}

        >
            <StyledMenuItem className="ownerAccess" disabled={status !== 'ACCEPTED' && status !== 'INVITED'} sx={{gap: '60px'}} onClick={handleSetOwnerAccess}>
                {t('admin_access.owner')}
                {ownerCheckMark && <img src={CheckIcon} alt="CheckIcon"/>}
            </StyledMenuItem>
            <StyledMenuItem className="adminAccess" disabled={status !== 'ACCEPTED'} sx={{gap: '60px'}} onClick={handleSetAdminAccess}>
                {t('admin_access.admin')}
                {adminCheckMark && <img src={CheckIcon} alt="CheckIcon"/>}
            </StyledMenuItem>
            <StyledMenuItem className="revokeAdminAccess" onClick={handleSetRevokeAccess} sx={{color: '#FF0100', border: '0px', borderTop: '1px solid #D7D7D7'}}>
                {t('admin_access.revoke_access')}
                {revokeCheckMark && <img src={CheckIcon} alt="CheckIcon"/>}
            </StyledMenuItem>
        </Menu>);
}

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: "#fff",
    padding: "8px 16px",
    color: "#000",
    letterSpacing: "0.09px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    lineHeight: 1,
    fontWeight: 400,
    justifyContent: "flex-start",
    textAlign: "left",
    transition: "background-color 0.2s ease",
    gap: "8px",
    display: "flex",
    alignItems: "st",
    "&:hover": {
        backgroundColor: "#f5f5f5"
    },

    "&:focus-visible": {
        outline: "0px",
        backgroundColor: "#fff",
    },

    "&:active": {
        backgroundColor: "#e0e0e0"
    }
});