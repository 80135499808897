import React from "react";
import { isValidBase64, isValidDomain } from "../helpers/utils";
import { isValidUUID } from "../helpers/utils";
import { context } from "../State";
import DropDown from "./DropDown";
import TextInput from "./TextInput";
import TextFieldCopy from "./TextFieldCopy";

export default function SIEMSection(props) {

    const { t, inSidebar, selectedSiem, setSelectedSiem, values, siemEnabled, isEditModeOn, setIntegrationDetails, integrationDetails} = props;
    const { state } = React.useContext(context)
    const [workspaceIdSection, setWorkspaceIdSection] = React.useState(inSidebar ? state.integrationData.workspaceId : '');
    const [primaryKeySection, setPrimaryKeySection] = React.useState('');
    const [serverHostnameSection, setServerHostnameSection] = React.useState(inSidebar ? state.integrationData.serverHostname : '');
    const [serverPortSection, setServerPortSection] = React.useState(inSidebar ? state.integrationData.serverPort : '');
    const [errorPrimaryKey, setErrorPrimaryKey] = React.useState(false);
    const [errorMessagePrimaryKey, setErrorMessagePrimaryKey] = React.useState('');
    const [errorWorkspaceId, setErrorWorkspaceId] = React.useState(false);
    const [errorMessageWorkspaceId, setErrorMessageWorkspaceId] = React.useState('');
    const [errorServerHostname, setErrorServerHostname] = React.useState(false);
    const [errorMessageServerHostname, setErrorMessageServerHostname] = React.useState('');
    const [errorServerPort, setErrorServerPort] = React.useState(false);
    const [errorMessageServerPort, setErrorMessageServerPort] = React.useState('');
    const [timer, setTimer] = React.useState(null);
    const [dropdownText, setDropdownText] = React.useState(selectedSiem==="SENTINEL"? "Microsoft Sentinel" : "Q-Radar Syslog");

    const handleWorkspaceIdChange = event => {
        let typedText = event.target.value
        setWorkspaceIdSection(event.target.value);
        setIntegrationDetails({
            ...integrationDetails,
            values: {
                "workspaceId": event.target.value,
                "primaryKey": primaryKeySection,
                "serverHostname": serverHostnameSection,
                "serverPort": serverPortSection
            }
        });

        clearTimeout(timer)
        //timer is added to delay validation until user finishes typing
        const newTimer = setTimeout(() => {
            if ((!isValidUUID(typedText) || typedText === "") && selectedSiem === "SENTINEL") {
                setErrorMessageWorkspaceId(t('add_integration.errors.invalid_workspace_id'));
                setErrorWorkspaceId(true);
            } else {
                setErrorMessageWorkspaceId("");
                setErrorWorkspaceId(false);
            }
        }, 500)

        setTimer(newTimer); 
    }

    const handlePrimaryKeyChange = event => {
        let typedText = event.target.value
        setPrimaryKeySection(event.target.value);
         setIntegrationDetails({
            ...integrationDetails,
            values: {
                "workspaceId": workspaceIdSection,
                "primaryKey": event.target.value,
                "serverHostname": serverHostnameSection,
                "serverPort": serverPortSection
            }
        });

        clearTimeout(timer)
        //timer is added to delay validation until user finishes typing
        const newTimer = setTimeout(() => {
            if ((!isValidBase64(typedText) || typedText === "") && selectedSiem === "SENTINEL") {
                setErrorMessagePrimaryKey(t('add_integration.errors.invalid_primary_key'));
                setErrorPrimaryKey(true);
            } else {
                setErrorMessagePrimaryKey("");
                setErrorPrimaryKey(false);
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerHostnameChange = event => {
        let typedText = event.target.value
        setServerHostnameSection(event.target.value);
        setIntegrationDetails({
            ...integrationDetails,
            values: {
                "workspaceId": workspaceIdSection,
                "primaryKey": primaryKeySection,
                "serverHostname": event.target.value,
                "serverPort": serverPortSection
            }
        });

        clearTimeout(timer)
        //timer is added to delay validation untill user finishes typing 
        const newTimer = setTimeout(() => {
            if (!isValidDomain(typedText) && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerHostname(t('add_integration.errors.invalid_server_hostname'));
                setErrorServerHostname(true);
            } else {
                setErrorMessageServerHostname("");
                setErrorServerHostname(false);
            }
        }, 500)

        setTimer(newTimer);
    }

    const handleServerPortChange = event => {
        let typedText = event.target.value
        setServerPortSection(event.target.value);
        setIntegrationDetails({
            ...integrationDetails,
            values: {
                "workspaceId": workspaceIdSection,
                "primaryKey": primaryKeySection,
                "serverHostname": serverHostnameSection,
                "serverPort": event.target.value
            }
        });

        clearTimeout(timer)
        //timer is added to delay validation until user finishes typing
        const newTimer = setTimeout(() => {
            if ((isNaN(typedText) || Number(typedText) < 1 || Number(typedText) > 65535) && selectedSiem === "SYSLOG_QRADAR") {
                setErrorMessageServerPort(t('add_integration.errors.invalid_server_port'));
                setErrorServerPort(true);
            } else {
                setErrorMessageServerPort("");
                setErrorServerPort(false);
            }
        }, 500)

        setTimer(newTimer);

    }

    const handleChangeSelection = (siemType) => {
        setSelectedSiem(siemType);
    };

    const siemOptions = [
        {value: "SENTINEL", label: "Microsoft Sentinel"},
        {value: "SYSLOG_QRADAR", label: "Q-Radar Syslog"}
    ];

    const actions = {
        handleChangeSelection: handleChangeSelection,
        setDropdownText: setDropdownText
    };

    const stateValues = {
        dropdownText: dropdownText
    }


    return (
        
        <div style={{ whiteSpace: 'pre-wrap', display: "flex", maxWidth: "592px", flexDirection: "column", justifyContent: "flex-start", gap: "24px" }}>

                <DropDown items={siemOptions} type={"SIEM"} label={t('add_integration.siem_dropdown_heading')} actions={actions} values={stateValues} isEdit={isEditModeOn} width={542}/>

                {selectedSiem === "SENTINEL" && isEditModeOn &&
                    <>
                    <TextInput infoLabel={t('add_integration.siem_workspace_id')} value={workspaceIdSection} placeholder={"Enter here"} onChange={(event) => handleWorkspaceIdChange(event)}
                               setError={errorWorkspaceId} errorText={errorMessageWorkspaceId}/>
                    <TextInput infoLabel={t('add_integration.siem_primary_key')} value={(isEditModeOn && !siemEnabled)? primaryKeySection : null} placeholder={"Enter here"} onChange={(event) => handlePrimaryKeyChange(event)}
                               setError={errorPrimaryKey} errorText={errorMessagePrimaryKey}/>
                    </>}
                {selectedSiem === "SENTINEL" && !isEditModeOn &&
                    <>
                     <TextFieldCopy infoDescription={{type: "siem_worspace_id",label: t('add_integration.siem_workspace_id')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    </>}

                {selectedSiem === "SYSLOG_QRADAR" && isEditModeOn &&
                    <>
                    <TextInput infoLabel={t('add_integration.siem_server_hostname')} value={serverHostnameSection} placeholder={"Enter here"} onChange={(event) => handleServerHostnameChange(event)}
                               setError={errorServerHostname} errorText={errorMessageServerHostname}/>
                    <TextInput infoLabel={t('add_integration.siem_server_port_number')} disabled={false} value={serverPortSection} placeholder={"Enter here"} onChange={(event) => handleServerPortChange(event)}
                               setError={errorServerPort} errorText={errorMessageServerPort}/>

                    </>}
                {selectedSiem === "SYSLOG_QRADAR" && !isEditModeOn &&
                    <>
                    <TextFieldCopy infoDescription={{type: "siem_server_hostname",label: t('add_integration.siem_server_hostname')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    <TextFieldCopy infoDescription={{type: "siem_server_port",label: t('add_integration.siem_server_port_number')}} isEditable={false} isCopied={true}></TextFieldCopy>
                    </>}

        </div> )
    

}