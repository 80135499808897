import React, {useEffect} from 'react';
import {Button, Dialog, DialogContent, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box/Box";
import warning from "../img/warning.svg";

const DialogHeader = () => {
    return (
        <HeaderContainer>
            <WarningIcon
                loading="lazy"
                src={warning}
                alt="Warning"
                role="img"
            />
            <HeaderTitle id="confirmation-dialog-title">
                Users will lose access
            </HeaderTitle>
        </HeaderContainer>
    );
};

const HeaderContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '8px',
    color: '#000'
});

const WarningIcon = styled(Box)({
    aspectRatio: '1',
    objectFit: 'contain',
    objectPosition: 'center',
    width: '32px',
    height: '32px'
});

const HeaderTitle = styled(Typography)({
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: 1,
    flex: 1,
    '@media (max-width: 991px)': {
        fontSize: '28px'
    }
});

const DialogMessage = () => {
    return (
        <MessageText id="confirmation-dialog-description">
            You have removed the assigned contract for this integration. Users will
            lose their access to this integration if you save this change. Are you
            sure you want to continue?
        </MessageText>
    );
};

const MessageText = styled(Typography)({
    color: '#000',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.09px',
    marginTop: '24px',
    '@media (max-width: 991px)': {
        fontSize: '16px'
    }
});

const DialogActions = ({ setShow, setRemoveContract }) => {
    const handleSave = () => {
        setRemoveContract(true);
    };

    const handleCancel = () => {
        setShow(false);
        setRemoveContract(false);
    }

    return (
        <ActionsContainer>
            <ActionButton
                id="contractCancelButton"
                label="No"
                variant="secondary"
                onClick={handleCancel}
                aria-label="Cancel changes"
            />
            <ActionButton
                id="contractSaveButton"
                label="Save anyway"
                variant="primary"
                onClick={handleSave}
                aria-label="Confirm and save changes"
            />
        </ActionsContainer>
    );
};

const ActionsContainer = styled(Box)({
    display: 'flex',
    marginTop: '24px',
    width: '100%',
    alignItems: 'center',
    gap: '40px',
    justifyContent: 'flex-end',
    '@media (max-width: 991px)': {
        flexDirection: 'column-reverse',
        gap: '16px'
    }
});

const ActionButton = ({ label, id, variant, onClick, 'aria-label': ariaLabel }) => {
    return (
        <StyledButton
            id={id}
            variant={variant}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {label}
        </StyledButton>
    );
};

const StyledButton = styled(Button)(({variant }) => ({
    borderRadius: '24px',
    backgroundColor: variant === 'primary' ? '#293dc7' : '#fff',
    color: variant === 'primary' ? '#fff' : '#293dc7',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: 1,
    padding: '12px 24px',
    minWidth: variant === 'secondary' ? '100px' : '120px',
    textTransform: 'none',
    border: variant === 'secondary' ? '1px solid #293dc7' : 'none',
    '&:hover': {
        backgroundColor: variant === 'primary' ? '#1f2e99' : '#f0f5ff',
    },
    '&:focus-visible': {
        outline: '2px solid #293dc7',
        outlineOffset: '2px'
    },
    '@media (max-width: 991px)': {
        width: '100%'
    }
}));


const ContractRemovalConfirmationDialog = ({ show, setShow, removeContract, setRemoveContract, save }) => {

    useEffect(() => {
        if (removeContract) {
            save();
        }
    }, [removeContract, save]);

    return (
        <StyledDialog
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <StyledDialogContent>
                <DialogHeader />
                <DialogMessage />
                <DialogActions setShow={setShow} setRemoveContract={setRemoveContract} save={save} />
            </StyledDialogContent>
        </StyledDialog>
    );
};

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: '16px',
        border: '1px solid #f0f5ff',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
        maxWidth: '656px',
        width: '100%',
        margin: '16px',
        fontFamily: 'Source Sans Pro, sans-serif'
    },
    '& .MuiBackdrop-root': {
        backgroundColor: '#05084980'
    }
});

const StyledDialogContent = styled(DialogContent)({
    padding: '56px',
    '@media (max-width: 991px)': {
        padding: '20px'
    }
});

export default ContractRemovalConfirmationDialog;