import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import MuiButton from "@mui/material/Button";
import DeleteRedSrc from "../img/delete-red.svg";
import ArrowDownIcon from "../img/arrow_down.svg"
import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {log} from "../helpers/utils";
import Api from "../helpers/service";
import SelectNewOwner from "./SelectNewOwner";
import CustomDialog from "./DialogText";
import DeleteGroupDialog from "./DeleteGroupDialog";
import {CheckButton, CheckedHeaderButton} from "./CustomUI";

export default function ManageAllGroupsTable(props){
    const { groups } = props;
    const { t } = useTranslation();

    const [checkedHeader, setCheckedHeader] = React.useState(false);
    const [checkedGroups, setCheckedGroups] = React.useState({});
    const [admins, setAdmins] = React.useState([]);
    const [openSelectNewOwner, setOpenSelectNewOwner] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [containerIds, setContainerIds] = React.useState([]);
    const [openTransferGroupOwnershipDialog, setOpenTransferGroupOwnershipDialog] = React.useState(false);
    const [currentEmail, setCurrentEmail] = React.useState('');
    const [openTransferContractErrorDialog, setOpenTransferContractErrorDialog] = React.useState(false);
    const [openTransferBrandingErrorDialog, setOpenTransferBrandingErrorDialog] = React.useState(false);
    const [openGenericErrorDialog, setOpenGenericErrorDialog] = React.useState(false);
    const [errorTextIds,setErrorTextIds] = React.useState(null);
    const [checkedGroupsNames, setCheckedGroupsNames] = React.useState([]);
    const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = React.useState(false);
    const [currentGroupToDelete, setCurrentGroupToDelete] = React.useState('');
    const [currentGroupToDeleteName, setCurrentGroupToDeleteName] = React.useState('');
    const [emptyGroup, setEmptyGroup] = React.useState(false);
    const [groupsToDelete, setGroupsToDelete] = React.useState(groups);

    const api = new Api();

    useEffect(() => {
        let initialCheckedGroups = {};
        api.getOwnedGroupsByUser().then(function (allGroups) {
            let newGroup = []
            newGroup.push(...groups);
            for (let i = 0; i < allGroups.length; i++) {
                let contain = false
                for (let j = 0; j < groups.length; j++) {
                    if (groups[j].containerId == allGroups[i].containerId) {
                        contain = true
                        break
                    }
                }
                if (!contain) {
                    newGroup.push(allGroups[i])
                }
            }
            for (let i=0; i<newGroup.length; i++) {
                if (newGroup[i].name === null) {
                    newGroup[i].name = "My group"
                  }
            }
            setGroupsToDelete(newGroup)
        }).catch(function (error) {
            log(error);
        })
        groupsToDelete.map(group => initialCheckedGroups[group.containerId] = null);
        setCheckedGroups(initialCheckedGroups);
    }, []);

    useEffect(() => {
        handleCheckedHeader();
        let checkedGroupsNames= groupsToDelete.filter(group => checkedGroups[group.containerId]);
        setCheckedGroupsNames(checkedGroupsNames.map(group => group.name));
    }, [checkedGroups]);

    const handleReset = () => {
        setCheckedHeader(false);
        let initialCheckedGroups = {};
        groupsToDelete.map(group => initialCheckedGroups[group.containerId] = null);
        setCheckedGroups(initialCheckedGroups);
    }

    const handleGroupCheckedChange = (groupId) => {
        setCheckedGroups({
            ...checkedGroups,
            [groupId]: !checkedGroups[groupId]
        });
    }

    const handleCheckedHeader = () => {
        setCheckedHeader(Object.values(checkedGroups).reduce((t, value) => t || value, false));
    }

    const fetch = async (groupContainerIds) => {
        try {
            const {data} = await api.getAdmins(groupContainerIds);
            let acceptedAdmins = data.filter(admin => admin.status === 'ACCEPTED' || admin.status === 'INVITED');
            let acceptedAdminsEmails = acceptedAdmins.map((admin) => admin.emailAddress);
            setAdmins([...new Set(acceptedAdminsEmails)]);
        }catch (err) {
            log(err);
        }
    }

    const handleTransferOwnershipBtn = (event) => {
        let checkedGroupKeys = Object.keys(checkedGroups);
        let containerIds = checkedGroupKeys.filter(key => checkedGroups[key]);
        setContainerIds(containerIds);
        fetch(containerIds);
        setAnchorEl(event.currentTarget);
        setOpenSelectNewOwner(true);
    }

    const handleTransferGroupOwnership = () => {
        api.transferContainers(currentEmail, containerIds).then(function (res) {
            log(res.data);
            window.location.reload();
            setCurrentEmail('');
        }).catch(function (error) {
            log(error);
            setCurrentEmail('');
            if(error.response.data.status === "CONTRACTS_IN_OTHER_CONTAINERS") {
                setErrorTextIds(error.response.data.contracts);
                setOpenTransferContractErrorDialog(true);

            } else if(error.response.data.status === "BRANDINGS_IN_OTHER_CONTAINERS") {
                setErrorTextIds(error.response.data.brandings);
                setOpenTransferBrandingErrorDialog(true);

            } else {
                setOpenGenericErrorDialog(true);
            }

        })
    };

    const handleCloseSelectNewOwner = () => {
        setOpenSelectNewOwner(false);
    };

    const handleDeleteGroup = (containerId, groupName) => {
        setCurrentGroupToDelete(containerId);
        setCurrentGroupToDeleteName(groupName);

        setOpenDeleteGroupDialog(true);
    }

    const setIsEmptyGroup = (groupId) => {
        let emptyGroup  = (groupsToDelete.filter(group => group.containerId === groupId)[0]).integrations === undefined;
        setEmptyGroup(emptyGroup);
    }

    const TableContainerStyle = {
        minWidth: "784px",
        borderTop: "1px solid var(--Gray-2, #EEE)",
        maxHeight: '320px',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D9D9D9',
            borderRadius: '12px'
        }
    }

    const TransferOwnershipButtonStyle = {
        backgroundColor: '#FFFFFF',
        color: '#293DC7',
        border: '0px',
        padding: '8px 12px',
        borderRadius: 50,
        cursor: 'pointer',
        fontSize: 14,
        textAlign: "center",
        fontWeight: 600,
        height: 32,
        width: '184px',
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        minWidth: "min-content",
        textTransform: "none",
        '&:hover': {
            color: '#293DC7',
            backgroundColor: '#F0F5FF'
        },
        '&:disabled': {
            color: '#293DC7',
            backgroundColor: '#FFFFFF',
            opacity: '0.5',
        },
    };

    const DeleteGroupButtonStyle = {
        backgroundColor: '#FFFFFF',
        color: "#FF0100",
        border: '0px',
        padding: '8px 12px',
        borderRadius: 50,
        cursor: 'pointer',
        fontSize: 14,
        textAlign: "center",
        fontWeight: 600,
        height: 32,
        gap: '4px',
        width: '164px',
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        minWidth: "min-content",
        textTransform: "none",
    };

    return (
        <>
        <TableContainer sx={TableContainerStyle}>
            <Table sx={{minWidth: "784px"}}>
                <TableHead>
                    <TableRow sx={{height: '40px'}}>
                        <TableCell align="start"  sx={{width: '32px', padding: '12px 8px 12px 4px'}} key={'select'}> { checkedHeader && <CheckedHeaderButton className="selectGroup" isChecked={checkedHeader} onClick={(e) => {e.stopPropagation(); handleReset()}}/>} </TableCell>
                        <TableCell align="start"  sx={{width: '282px', padding: '12px 4px'}} key={"Group name"}><Typography variant="h4">{t('group_ownership.manage_groups_table_col_one')}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '282px', padding: '12px 4px'}} key={"Number of integrations"}><Typography variant="h4">{t('group_ownership.manage_groups_table_col_two')}</Typography></TableCell>
                        <TableCell align="right"  sx={{width: '164px', padding: '12px 16px'}} key={"Transfer ownership"}>
                            <MuiButton id="transferOwnershipButton" sx={TransferOwnershipButtonStyle} onClick={handleTransferOwnershipBtn} disabled={!checkedHeader}>
                                Transfer ownership
                                <img src={ArrowDownIcon} alt="arrow down"/>
                            </MuiButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groupsToDelete !== undefined && groupsToDelete.map((group, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0}, height: '56px'}}>
                            <TableCell align="start" style={{width: '32px', padding: '12px 8px 12px 4px'}}> <CheckButton isChecked={checkedGroups[group.containerId]} onClick={(e) => {e.stopPropagation(); handleGroupCheckedChange(group.containerId);}} /> </TableCell>
                            <TableCell align="start"  sx={{width: '282px', padding: '12px 4px'}}><Typography variant="h4_column" className="groupName" >{group.name}</Typography></TableCell>
                            <TableCell align="start"  sx={{width: '282px', padding: '12px 4px'}}><Typography variant="h4_column" className="groupLength" >{group.integrations ? group.integrations.length : 0}</Typography></TableCell>
                            <TableCell align="right"  sx={{width: '164px', padding: '12px 16px'}}>
                                <MuiButton className="deleteGroup" sx={DeleteGroupButtonStyle} onClick={() => {
                                    handleDeleteGroup(group.containerId, group.name);
                                    setIsEmptyGroup(group.containerId);
                                }}>
                                    <img src={DeleteRedSrc} alt="delete"/>
                                    Delete group
                                </MuiButton>
                            </TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
            <SelectNewOwner open={Boolean(openSelectNewOwner)} admins={admins} anchorEl={anchorEl} onClose={handleCloseSelectNewOwner} setCurrentEmail={setCurrentEmail} setOpenTransferGroupOwnershipDialog={setOpenTransferGroupOwnershipDialog}></SelectNewOwner>
            <CustomDialog
                open={openTransferGroupOwnershipDialog}
                title={t("group_ownership.title_dialog")}
                subtitle={<Trans t={t} i18nKey="group_ownership.subtitle_dialog_two" >Are you sure you want to make <b>{{new_owner: currentEmail}}</b> the owner of <b>the selected groups</b> ? <b>You can’t undo this action.</b> </Trans>}
                hideCloseButton={true}
                confirmBtnText={t("group_ownership.confirm_button_text")}
                cancelBtnText={"Cancel"}
                onCancel={() => {
                    setOpenTransferGroupOwnershipDialog(false);
                    setCurrentEmail('');
                }}
                onConfirm={handleTransferGroupOwnership}/>
        <CustomDialog
            open={openTransferContractErrorDialog}
            singleButton={true}
            title={t('group_ownership.error_title')}
            onConfirm={()=> {
                setOpenTransferContractErrorDialog(false);
                setErrorTextIds(null);
            }}
            subtitle={<Trans t={t} i18nKey="group_ownership.error_contracts_text" >You are attempting to transfer groups, but they do not contain all integrations for Contract(s) <b>[{{contract_ids: errorTextIds}}]</b>. Before you can transfer these groups, please move all integrations for Contract(s) <b>[{{contract_ids: errorTextIds}}]</b> into Groups <b>[{{names: checkedGroupsNames}}]</b>. <br/> <br/>And then try one more time.</Trans>}
            confirmBtnText={"Ok"}
            hideCloseButton={true}
        />
        <CustomDialog
            open={openTransferBrandingErrorDialog}
            singleButton={true}
            title={t('group_ownership.error_title')}
            onConfirm={()=> {
                setOpenTransferBrandingErrorDialog(false);
                setErrorTextIds(null);
            }}
            subtitle={<Trans t={t} i18nKey="group_ownership.error_brandings_text" >You are attempting to transfer groups, but they do not contain all integrations for Branding(s) <b>[{{branding_ids: errorTextIds}}]</b>. Before you can transfer these groups, please move all integrations for Branding(s) <b>[{{branding_ids: errorTextIds}}]</b> into Groups <b>[{{names: checkedGroupsNames}}]</b>. <br/> <br/>And then try one more time.</Trans>}
            confirmBtnText={"Ok"}
            hideCloseButton={true}
        />
            <CustomDialog
                open={openGenericErrorDialog}
                singleButton={true}
                title={t('group_ownership.error_title')}
                onConfirm={()=> {
                    setOpenGenericErrorDialog(false);
                    setErrorTextIds(null);
                }}
                subtitle={t('group_ownership.error_generic_text')}
                confirmBtnText={"Ok"}
                hideCloseButton={true}/>

            <DeleteGroupDialog
                openDeleteGroupDialog={openDeleteGroupDialog}
                groups={groupsToDelete}
                setOpenDeleteGroupDialog={setOpenDeleteGroupDialog}
                currentGroupToDelete={currentGroupToDelete}
                setCurrentGroupToDelete={setCurrentGroupToDelete}
                currentGroupToDeleteName={currentGroupToDeleteName}
                setCurrentGroupToDeleteName={setCurrentGroupToDeleteName}
                setEmptyGroup={setEmptyGroup}
                emptyGroup={emptyGroup}
            />

        </>

    );
}
