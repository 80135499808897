import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box/Box";

const RadioInput = ({label, value, checked, setter, id}) => {
	return (
    <div>
	  <label style={{background: 'transparent'}}>
	    <input style={{accentColor: '#293DC7'}} id={id} type="radio" checked={checked === value} onChange={(event) => setter(value, event)}/>
	    <span>
        {label}
      </span>
	  </label>
    </div>
	);
};

export default function OidcMethodSelector (props) {
  const {t} = useTranslation();

  const { integrationDetails, setIntegrationDetails } = props;

  const [oidcConfiguration, setOidcConfiguration] = React.useState(integrationDetails.oidcConfiguration);

  useEffect(() => {
    setOidcConfiguration(integrationDetails.oidcConfiguration);
  }, []);



  //algorithms and methods 
  let signingAlgorithms = ["HS256", "HS384", "HS512", "RS256", "RS384", "RS512", "ES256", "ES384", "ES512" ]; // default RS256
  let encryptionAlgorithms = [ "none", "RSA-OAEP-256", "RSA-OAEP", "RSA1_5", "A128KW", "A192KW", "A256KW", "dir", "ECDH-ES", "ESDH-ES+A128KW", "ESDH-ES+A192KW", "ESDH-ES+A256KW" ];  //default none
  let encryptionAlgorithms_enc = ["none", "A128CBC-HS256", "A192CBC-HS384", "A256CBC-HS512","A128GCM", "A192GCM", "A256GCM", "A128CBC+HS256", "A256CBC+HS512" ]; //default none
  let authenticationMethods = ["client_secret_post", "client_secret_basic", "client_secret_jwt", "private_jwt"]; //default client_secret_post

  function updateSigningAlgorithm(alg) {
    let newConfiguration = {
      signingAlgorithm: alg,
      encryptionAlgorithm: oidcConfiguration.encryptionAlgorithm,
      encryptionMethod: oidcConfiguration.encryptionMethod,
      authenticationMethod: oidcConfiguration.authenticationMethod
    }
    setOidcConfiguration(newConfiguration);
    setIntegrationDetails({
      ...integrationDetails,
      oidcConfiguration: newConfiguration
    });
  }

  function updateEncryptionAlgorithm(alg) {
    let newConfiguration = {
      signingAlgorithm: oidcConfiguration.signingAlgorithm,
      encryptionAlgorithm: alg,
      encryptionMethod: oidcConfiguration.encryptionMethod,
      authenticationMethod: oidcConfiguration.authenticationMethod
    }
    setOidcConfiguration(newConfiguration);
    setIntegrationDetails({
      ...integrationDetails,
      oidcConfiguration: newConfiguration
    });
  }

  function updateEncryptionMethod(meth) {
    let newConfiguration = {
      signingAlgorithm: oidcConfiguration.signingAlgorithm,
      encryptionAlgorithm: oidcConfiguration.encryptionAlgorithm,
      encryptionMethod: meth,
      authenticationMethod: oidcConfiguration.authenticationMethod
    }
    setOidcConfiguration(newConfiguration);
    setIntegrationDetails({
      ...integrationDetails,
      oidcConfiguration: newConfiguration
    });
  }

  function updateAuthenticationMethod(meth) {
    let newConfiguration = {
      signingAlgorithm: oidcConfiguration.signingAlgorithm,
      encryptionAlgorithm: oidcConfiguration.encryptionAlgorithm,
      encryptionMethod: oidcConfiguration.encryptionMethod,
      authenticationMethod: meth
    }
    setOidcConfiguration(newConfiguration);
    setIntegrationDetails({
      ...integrationDetails,
      oidcConfiguration: newConfiguration
    });
  }

  const ColumnStyle = {
    borderRadius: '8px',
    padding: '9px',
    minWidth: '168px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: 'var(--Gray-1, #F7F7F7)',
    marginBottom: '8px'
  }

  const BoxContainerStyle = {
    maxWidth: '592px',
    display: 'flex',
    gap: '16px',
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#FFFFFF'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D9D9D9',
      borderRadius: '12px'
    }
  }

  const StyledTypography = {
    color: "var(--Black, #000)",
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.14px',
    marginBottom: '8px'
  }

  return (
      <Box sx={BoxContainerStyle} >
            <Box style={{...ColumnStyle, minWidth: '132px'}}>
              <Typography sx={StyledTypography}> {t('add_integration.signing_alg')} </Typography>
              {signingAlgorithms.map((alg) => (
                  <RadioInput
                    id={alg}
                    element="div"
                    label={alg}
                    value={alg}
                    checked={oidcConfiguration.signingAlgorithm}
                    setter={(alg, event) => updateSigningAlgorithm(alg, event)}/>
              ))
              }
            </Box>

            <Box style={ColumnStyle}>
              <Typography sx={StyledTypography}> {t('add_integration.encryption_alg')} </Typography>
              {
                encryptionAlgorithms.map((alg) => (
                    <RadioInput
                      id={alg !== "none"? alg : "noneEncryption"}
                      element="div"
                      label={alg}
                      value={alg}
                      checked={oidcConfiguration.encryptionAlgorithm}
                      setter={(alg, event) => updateEncryptionAlgorithm(alg, event)}/>
                ))
              }
            </Box>

            <Box style={ColumnStyle}>
              <Typography sx={StyledTypography}> {t('add_integration.encryption_meth')} </Typography>
              {
                encryptionAlgorithms_enc.map((method) => (
                    <RadioInput
                      id={method !== "none"? method : "noneMethod"}
                      element="div"
                      label={method}
                      value={method}
                      checked={oidcConfiguration.encryptionMethod}
                      setter={(method, event) => updateEncryptionMethod(method, event)}/>
                ))
              }
            </Box>

            <Box style={ColumnStyle}>
              <Typography sx={StyledTypography}> {t('add_integration.authentication_meth')} </Typography>
              {
                authenticationMethods.map((method) => (
                  <RadioInput
                    id={method}
                    element="div"
                    label={method}
                    value={method}
                    checked={oidcConfiguration.authenticationMethod}
                    setter={(method, event) => updateAuthenticationMethod(method, event)}/>
                ))
              }
            </Box>
      </Box>
  );
}