import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import DropDown from "./DropDown";
import {CreateContractButton} from "./CreateContractButton";
import { createTheme } from "@mui/material/styles";
import ContractComponent from "./ContractComponent";
import {SubscriptionCard} from "../pages/subscription/SubscriptionCard";
import Api from "../helpers/service";
import {useEffect} from "react";

export const theme = createTheme({
    typography: {
        fontFamily: "Source Sans Pro, sans-serif",
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
});

export function ContractSection(props) {
    const {setNewContractId, identitiesLimit, setIdentitiesLimit, integrationsLimit, setIntegrationsLimit, contractReference, setContractReference, selectedContract, setSelectedContract, createContract, setCreateContract, showSelectedContract, setShowSelectedContract, contract, isEdit, isAdd, edition, setEdition, assignedContract, setAssignedContract, assignContractChecked} = props

    const [contractId, setContractId] = React.useState("");
    const [isMspAdmin, setIsMspAdmin] = React.useState(false);
    const [dropdownText, setDropdownText] = React.useState("");

    const values = {
        selectedContract: selectedContract,
        createContract: createContract,
        contract: contract,
        showSelectedContract: showSelectedContract,
        dropdownText: dropdownText
    }

    const actions= {
        setSelectedContract: setSelectedContract,
        setNewContractId: setNewContractId,
        setShowSelectedContract: setShowSelectedContract,
        setDropdownText: setDropdownText
    }

    useEffect( () => {
        setDropdownText(selectedContract !== null ? (selectedContract.caption ? selectedContract.caption : "") : (assignedContract  && assignedContract.caption ? assignedContract.caption : ""));

        const api = new Api();
        let customerId = "";
        let numberOfContracts = 0;
        api.getCustomerId().then((data) => {
            customerId = data.customerId;
            numberOfContracts = data.numberOfContracts;
            let suffix = (numberOfContracts + 1).toString().padStart(3,"0");
            let newContractId = customerId + '.' + suffix;
            setContractId(newContractId);
            setIsMspAdmin(customerId !== null && customerId !== undefined);
        })
    }, [assignedContract, selectedContract, setNewContractId]);

    if (isEdit || isAdd) {
        return (
            <Content>
                <InstructionText>
                    Please select the contract you would like to assign to this integration.
                </InstructionText>
                <DropDown type={"Contract"} label={"Select contract"} actions={actions} values={values} isEdit={isEdit} isAdd={isAdd} width={542} disabled={createContract}/>
                { assignedContract && assignedContract.contractId && !showSelectedContract ? <SubscriptionCard contract={contract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} closeIconNeeded={true} titleNeeded={false} setShowSelectedContract={setShowSelectedContract} setAssignedContract={setAssignedContract}/> : <></> }
                {
                    isMspAdmin &&
                    <>
                        <ActionContainer>
                            <OrText variant="body1">or</OrText>
                            <CreateContractButton setCreateContract={setCreateContract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} contractId={contractId} setShowSelectedContract={setShowSelectedContract} setAssignedContract={setAssignedContract}/>
                        </ActionContainer>
                        <ContractComponent  identitiesLimit={identitiesLimit}
                                            setIdentitiesLimit={setIdentitiesLimit}
                                            integrationsLimit={integrationsLimit}
                                            setIntegrationsLimit={setIntegrationsLimit}
                                            contractReference={contractReference}
                                            setContractReference={setContractReference}
                                            createContract={createContract}
                                            setCreateContract={setCreateContract}
                                            isChecked={assignContractChecked}
                                            contractId={contractId}
                                            edition={edition}
                                            setEdition={setEdition}
                                            setNewContractId={setNewContractId}/>
                    </>
                }
            </Content>
        );
    } else {
        return(
                <div style={{marginBottom: 20}}>
                    <SubscriptionCard contract={contract} setSelectedContract={setSelectedContract} setNewContractId={setNewContractId} closeIconNeeded={false} titleNeeded={true} contractLabelNeeded={true}/>
                </div>
        )
    }


}

const InstructionText = styled(Typography)({
    color: "var(--Black, #000)",
    letterSpacing: "0.09px",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const ActionContainer = styled(Box)({
    alignSelf: "flex-start",
    display: "flex",
    marginTop: "8px",
    alignItems: "flex-start",
    gap: "4px",
    justifyContent: "flex-start",
});

const OrText = styled(Typography)({
    color: "var(--Black, #000)",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.09px",
    width: "17px",
});

const Content = styled('div')(() => ({
    height: '100%',
    display: "flex",
    maxWidth: "592px",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: '24px'
}));