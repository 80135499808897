import * as React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import { publicUrl } from "../App";
import { context, ACTION_TYPE } from "../State";
import { InverseButton } from './CustomUI';
import LogoutIcon from '../img/logout.svg';
import CustomSnackbar from './Snackbar';
import { useTranslation } from 'react-i18next';
import { getCurrentBrowser } from '../helpers/utils';
import { useLocation } from 'react-router-dom';
import Menu from "./HorizontalMenu";
import Notification from '../img/notification.svg';
import NewNotification from '../img/new notification.svg';

const StyledAppBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "inherit"
}));

const Logo = styled('img')({
    width: '120px',
    height: '48px'
});



function ElevationScroll(props) {
    const { children } = props;
    return React.cloneElement(children, {
        elevation: useScrollTrigger() ? 4 : 0,
    });
}


function FadeInOnScroll(props) {
    const trigger = useScrollTrigger();
    return (
        <Fade in={trigger}>
            <Typography
                variant="h2"
                noWrap
                component="div"
                sx={{fontSize:'3vw', flexGrow: 1, alignSelf: 'center', marginLeft: '9vw' }}
            >
                {props.title}
            </Typography>
        </Fade>
    );
}

export default function AppBar(props) {
    const { onLogout, email, menuItems } = props;
    const { state, dispatch } = React.useContext(context);
    const { t } = useTranslation();
    const location = useLocation();

    const showResolutionRecommendationSnackFunction = ()=>{
        dispatch({ type: ACTION_TYPE.SHOW_RESOLUTION_RECOMMENDATION_SNACK, payload: true })
    }
    const handleResolutionRecommendationSnackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: ACTION_TYPE.SHOW_RESOLUTION_RECOMMENDATION_SNACK, payload: false });
    }

    const onNotificationButtonClick = () => {
        dispatch({ type: ACTION_TYPE.OPEN_NOTIFICATIONS_DRAWER, payload: true });
    }

    useEffect(()=>{
        if( getCurrentBrowser() !== ("Google Chrome or Chromium") && (location.pathname=== "/login"))
            showResolutionRecommendationSnackFunction();
    },[])

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <StyledAppBar id='appBar' sx={{ backgroundColor: location.pathname === "/setup" ? '#F7F7F7' : '#FFFFFF'}} >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Logo src={publicUrl + "/company-logo.svg"} alt="Logo" />
                        {onLogout &&
                            <Menu items={menuItems} />
                        }
                    </div>
                        <FadeInOnScroll {...props} />
                        { !onLogout && (location.pathname=== "/login") &&
                            <CustomSnackbar open={state.showResolutionRecommendationSnack} onCancel={handleResolutionRecommendationSnackClose} type={'recommendation'} vertical={'top'} horizontal={'center'} text={t('login.recommended_resolutions')} buttonText={t('notification.okay')} showIcon='true' autoHide='false' />
                        }
                        
                        { !onLogout && ((location.pathname=== "/login") || (location.pathname=== "/registration")) &&

                            <InverseButton id='getHelpButton' onClick={()=>{ window.open("https://getidee.atlassian.net/servicedesk/customer/portal/1", "_blank");}}>
                                {t('login.get_help')}
                            </InverseButton>

                        }

                        {onLogout &&
                            <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                            <Typography id="loggedInUser" variant="h4_column"> {email} </Typography>
                            <IconButton id='notificationButton' sx={{padding: 0}} onClick={onNotificationButtonClick}>
                                {state.notificationsForUser.length > 0 ? <img src={NewNotification} alt={"new notifications"}/> : <img src={Notification} alt={"notifications"} />}
                            </IconButton>
                            <IconButton
                                id='logoutButton'
                                disableRipple={true}
                                size="medium"
                                aria-label="logout"
                                edge="end"
                                color="inherit"
                                sx={{ color: "var(--Black, #000)",
                                    textAlign: "center",
                                    fontFamily: "Source Sans Pro",
                                    fontSize: "17px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "24px",
                                    padding: 0,
                                '&:hover': {
                                    backgroundColor: "transparent",
                                }

                            }}
                                onClick={onLogout}
                            >
                                <img src={LogoutIcon} style={{width:'24px', height:'24px'}} alt="logoutIcon"/>
                                 Logout
                            </IconButton>
                            </div>
                        }
                </StyledAppBar>
            </ElevationScroll>
        </React.Fragment>
    );
}
