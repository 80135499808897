import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Typography } from "@mui/material";

function TextInput(props) {
  const { infoLabel, value, content, disabled, placeholder, onChange, setError, errorText, width, onKeyPress, id } = props;
  return (
    <Box>
      <TextInputWrapper>
        <TextLabel variant="body2">
          {infoLabel}
        </TextLabel>
        {!disabled &&
          <StyledTextFieldWhite
            className="textFieldEnabled"
            onChange={onChange}
            value={value}
            variant="outlined"
            defaultValue={content}
            placeholder={placeholder}
            width={width === undefined ? '592' : width}
            error={setError}
            helperText={errorText}
            onKeyPress={onKeyPress}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              className: "inputField",
              id:id
            }}
          />}
        {disabled &&
          <StyledTextFieldGray
            className="textFieldDisabled"
            defaultValue={content}
            value={value}
            width={width === undefined ? '592' : width}
            variant="outlined"
            inputProps={{
              tabIndex: "0",
              readOnly: true ,
              className: "outputField",
              id:id
            }}
          />
        }

      </TextInputWrapper>
    </Box>
  );
}

const TextInputWrapper = styled(Box)({
  display: "flex",
  maxWidth: "100%",
  flexDirection: "column",
  fontFamily: "Source Sans Pro, sans-serif",
  justifyContent: "start",
});

const TextLabel = styled(Typography)({
  width: "100%",
  fontSize: "14px",
  color: "#7E7E7E",
  fontWeight: 600,
  letterSpacing: "0.14px",
  lineHeight: "16px",
  marginBottom: "4px",
  "@media (max-width: 991px)": {
    maxWidth: "100%",
  },
});

const StyledTextFieldGray = styled(TextField)(({ width }) => ({
  "& .MuiOutlinedInput-root": {
    width: width,
    borderRadius: "8px",
    backgroundColor: "var(--Gray-2, #eee)",
    "& fieldset": {
      borderColor: "var(--Gray-2, #eee)",
    },
    "&:hover fieldset": {
      borderColor: "var(--Gray-2, #eee)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--Gray-2, #eee)",
      borderWidth: "2px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
    fontSize: "18px",
    color: "var(--Gray-9, #202020)",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: 1,
  },
  "@media (max-width: 991px)": {
    maxWidth: "100%",
  },
}));

const StyledTextFieldWhite = styled(TextField)(({ width }) => ({
  "& .MuiOutlinedInput-root": {
    width: width,
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: 1,
    "& fieldset": {
      borderRadius: "8px",
      borderColor: "var(--Gray-3, #d7d7d7)",
    },
    "&:hover fieldset": {
      borderColor: "var(--Gray-3, #d7d7d7)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--Gray-3, #d7d7d7)",
      borderWidth: "2px",
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.14px",
    lineHeight: 1,
    color: "var(--Gray-6, #7e7e7e)",
  },
  "& .MuiInputBase-input": {
    padding: "12px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FF5140",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#FF5140",
  },
}));

export default TextInput;
