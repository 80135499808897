import Integration from "./Integration";

export default class GroupObj {
    constructor(name, owner,containerId, shared, color, integrations, dragAndDrop) {
        this._name = name;
        this._owner = owner;
        this._containerId = containerId;
        this._shared = shared;
        this._color = color;
        this._integrations = integrations;
        this._dragAndDrop = dragAndDrop;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }

    get owner() {
        return this._owner;
    }

    set owner(value) {
        this._owner = value;
    }

    get shared() {
        return this._shared;
    }

    set shared(value) {
        this._shared = value;
    }

    get color() {
        return this._color;
    }

    set color(value) {
        this._color = value;
    }

    get tableHeaders() {
        return Integration.tableHeaders();
    }

    get tableRows() {
        let tableRows = [];
        let sortedByTs = this.integrations.sort(function(x, y){return y.creationTimeStamp - x.creationTimeStamp;});
        Object.values(sortedByTs).map(integration => {
            tableRows.push(Integration.toIntegration(integration))
        });
        return tableRows;
    }

    get integrations() {
        return this._integrations;
    }

    set integrations(value) {
        this._integrations = value;
    }

    get dragAndDrop() {
        return this._dragAndDrop;
    }

    set dragAndDrop(value) {
        this._dragAndDrop = value;
    }

    get containerId() {
        return this._containerId;
    }

    set containerId(value) {
        this._containerId = value;
    }
}