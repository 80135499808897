import * as React from "react";
import { Box, Typography, IconButton, TextField, InputAdornment, Button, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import userIcon from "../img/user.svg"
import managerUserIcon from "../img/manage-user.svg"
import deleteIcon from "../img/delete-red.svg"
import Api from "../helpers/service";
import { serviceProvidersIcon, log, formatDatePart, formatTimePart } from "../helpers/utils";
import { useTranslation } from "react-i18next";
import Countdown from 'react-countdown';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {ACTION_TYPE, context} from "../State";
import {InverseButton, LicenseButton} from "./CustomUI";
import CreateUserInMsEntraIdDialog from "./CreateUserInMsEntraIdDialog";
import {useEffect} from "react";
export function SearchUser(props) {

  const { setInviteMenuIndex, profileEmail, setProfileEmail, groups, setRepeatSearchUser, repeatSearchUser } = props;
  const [integrations, setIntegrations] = React.useState(null);
  const [devices, setDevices] = React.useState(null);
  const [accessKey, setAccessKey] = React.useState(null);
  const [inviteLink, setInviteLink] = React.useState(null);
  const [userFound, setUserFound] = React.useState(false);
  const [userSearched, setUserSearched] = React.useState(false);
  const [userRegistered, setUserRegistered] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [multipleSameDomains, setMultipleSameDomains] = React.useState(false);
  const [cbeIntegration, setCbeIntegration] = React.useState(false);
  const [byodIntegration, setByodIntegration] = React.useState(false);
  const [notOwnedIntegration, setNotOwnedIntegration] = React.useState(false);
  const [scimEnabled, setScimEnabled] = React.useState(false);
  const [showCreateUserOnEntra, setShowCreateUserOnEntra] = React.useState(false);

  const { dispatch, state } = React.useContext(context);



  const { t } = useTranslation();
  const api = new Api();

  function calculateTimeLeft(time) {
    return time + 12 * 60 * 60 * 1000;
  }

  const renderer = ({ hours, minutes }) => {
    return <span>{hours}h {minutes} min</span>
  };

  function handleRevokeInviteLink() {
    api.revokeInviteLink(profileEmail).then(function (res) {
      log(res);
      setInviteLink(null);
    }).catch(function (error) {
      log(error);
    })
  }
  function handleRevokeCode() {
    api.revokeCode(profileEmail).then(function (res) {
      log(res);
      setAccessKey(null);
    }).catch(function (error) {
      log(error);
    })
  }

  function handleRemoveDevice(deviceUuid) {
    log("Device data: " + state.deviceData);
    api.deleteDevice(deviceUuid, profileEmail).then(function(res) {
      if (res.status === 200) {
        setRepeatSearchUser(true);
      }
    }).catch(function (error) {
      log(error);
    })
  }

  const handleInviteButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteUserButtonClick = () => {
    setDeleteDialog(true);
  };

  const handleInviteMenuClose = () => {
    setAnchorEl(null);
  }

  function handleMenuItemClick(index) {
    setInviteMenuIndex(index);
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER, payload:true});
    setAnchorEl(null);
  }
  function createUserOnAzure() {
    setShowCreateUserOnEntra(true);
  }


  return (
    <MyContainer id="searchUserComponent">
      <ProfileContainer id="profileContainer">
        <Box sx={{display: "flex", width: 'inherit', height: "70px", alignItems: "flex-start", gap: "16px", alignSelf: "stretch"}}>
          <EmailField repeatSearchUser={repeatSearchUser}></EmailField>
          <InverseButton sx={{maxWidth: "250px", minWidth: "200px", height: "50px"}} onClick={createUserOnAzure}>
             Create User in Entra ID 
          </InverseButton>
        </Box>
        {(!userSearched || notOwnedIntegration) &&
          <SearchTitle id="searchTitle" variant="h1">
            {t('dialog.search_user_initial_dialog')}
          </SearchTitle>
        }
        {userSearched && !notOwnedIntegration &&
          <HeaderWrapper id="headerWrapper">
            <ProfileIcon
              id="profileIcon"
              loading="lazy"
              src={userIcon}
              alt="User profile icon"
            />
            <UserInfo id="userInfo">
              <EmailContainer id="emailContainer">
                <EmailLabel id="emailLabel">{t('dialog.search_user_email')}</EmailLabel>
                <EmailValue id="profileEmail">{profileEmail}</EmailValue>
              </EmailContainer>
              <RegistrationContainer id="registrationContainer" userFound={userFound}>
                {userFound &&
                  <><RegistrationLabel id="registrationLabel">{t('dialog.search_user_registered')}: </RegistrationLabel>
                    <RegistrationDate id="userRegisteredDate">{userRegistered.substring(0, 10)}</RegistrationDate></>
                }
                {
                  !userFound &&
                  <RegistrationLabel id="registrationLabel">{t('dialog.search_user_not_exists')}</RegistrationLabel>
                }

              </RegistrationContainer>
            </UserInfo> 
            {/* if scim is enabled or integration is cbe without byod we disable manage user button */}
            <ActionButton id="manageUserButton" style={{visibility: scimEnabled || (cbeIntegration && !byodIntegration)? 'hidden' : 'visible'}}> 
              <img
                loading="lazy"
                src={managerUserIcon}
                alt="Primary action"
                onClick={handleInviteButtonClick}
              />
            </ActionButton>
            {/* if scim is enabled delete button is not shown */}
            {userFound && !scimEnabled &&
              <ActionButton id="deleteUserButton">
                <img
                  loading="lazy"
                  src={deleteIcon}
                  alt="Secondary action"
                  onClick={handleDeleteUserButtonClick}

                />
              </ActionButton>
            }

          </HeaderWrapper>}
        {userFound &&
          <ListContainer className="listContainer">
            <HeaderText className="headerText" variant="h2">{t('dialog.search_user_devices')}</HeaderText>
            {devices != null &&
              <ListContent className="listContent">
                <HeaderRow className="headerRow">
                  <NameColumn className="nameColumn" variant="h4">{t('dialog.search_user_name')}</NameColumn>
                  <RegisteredColumn className="registeredColumn" variant="h4">{t('dialog.search_user_registered')}</RegisteredColumn>
                  <ActionColumn id="actionColumnRemoveDevice"></ActionColumn>
                </HeaderRow>
                <DevicesContainer id="devicesContainer">
                  {devices.map((device) => (
                      <ItemContainer className="itemContainer">
                        <DeviceName className="deviceName">{device.name}</DeviceName>
                        <TimeContainer className="timeContainer">
                          <DateText className="deviceRegisteredDate" variant="h4_column">{formatDatePart(device.created)}</DateText>
                          <Time className="deviceRegisteredTime" variant="h4_column">{formatTimePart(device.created)}</Time>
                        </TimeContainer>
                        <Box style={{justifyContent: "flex-end", marginLeft: "auto"}}>
                          <RevokeButton className="removeDeviceButton" variant="text" color="error" onClick={() => handleRemoveDevice(device.deviceUuid)}>
                            {"Remove device"}
                          </RevokeButton>
                        </Box>
                      </ItemContainer>
                  ))}
                </DevicesContainer>
              </ListContent>}
          </ListContainer>}
        {userFound &&
          <ListContainer className="listContainer">
            <HeaderText className="headerText" variant="h2">{t('dialog.search_user_integrations')}</HeaderText>
            {integrations != null &&
              <ListContent className="listContent">
                <HeaderRow className="headerRow">
                  <div style={{width: "40px"}}/>
                  <NameColumn className="nameColumn" variant="h4">{t('dialog.search_user_name')}</NameColumn>
                  <DomainColumn id="domainColumn" variant="h4">{t('dialog.search_user_domain')}</DomainColumn>
                  <LicenseColumn id="licenseColumn" variant="h4">{t('dialog.search_user_license_type')}</LicenseColumn>
                  <LastLoginColumn id="lastLoginColumn" variant="h4">{t('dialog.search_user_last_login')}</LastLoginColumn>
                </HeaderRow>
                <IntegrationsContainer id="integrationsContainer">
                  {integrations.map((integration) => (
                    <ItemContainer className="itemContainer">
                      <SPIconName className="spIconName" src={serviceProvidersIcon[integration.type]} />
                      <IntegrationName className="integrationName" variant="h4_column">{integration.name}</IntegrationName>
                      <DomainText className="integrationDomain" variant="h4_column">{integration.domain}</DomainText>
                      <LicenseContainer>
                        <LicenseButton className="licenseType">
                          {integration.licensed ? "Licensed" : "Trial"}
                        </LicenseButton>
                      </LicenseContainer>
                      <TimeContainer className="timeContainer">
                        <DateText className="lastLoginDate" variant="h4_column">{formatDatePart(integration.last_login)}</DateText>
                        <Time className="lastLoginTime" variant="h4_column">{formatTimePart(integration.last_login)}</Time>
                      </TimeContainer>
                    </ItemContainer>
                  ))}
                </IntegrationsContainer>
              </ListContent>}
          </ListContainer>}
        {userFound &&
          <ListContainer className="listContainer">
            <HeaderText className="headerText" variant="h2">{t('dialog.access_key_or_link_title')}</HeaderText>
            {(accessKey != null || inviteLink != null) &&
              <ListContent className="listContent">
                <HeaderRow className="headerRow">
                  <NameColumn className="nameColumn" variant="h4">{t('dialog.search_user_name')}</NameColumn>
                  <UsedColumn id="usedColumn" variant="h4">{t('dialog.search_user_used_times')}</UsedColumn>
                  <ValidColumn id="validColumn" variant="h4">{t('dialog.search_user_valid_time')}</ValidColumn>
                  <ActionColumn id="actionColumn"></ActionColumn>
                </HeaderRow>

                {accessKey != null && <>
                  <ItemContainer className="itemContainer" sx={{borderBottom: inviteLink ? '1px solid #EEE' : '0px'}}>
                    <KeyName className="keyName" variant="h4_column">{t('dialog.search_user_access_key')}</KeyName>
                    <UsedTimes className="accessKeyUsedTims" variant="h4_column">{(accessKey.allowedTimes - accessKey.numberOfUsages) + '/ ' + accessKey.allowedTimes}</UsedTimes>
                    <ValidTime className="accessKeyValidTime" variant="h4_column">
                      {accessKey.validFromTime !== null
                        && accessKey.validFromTime <= Date.now() && <>for <Countdown date={calculateTimeLeft(accessKey.validFromTime)} daysInHours={true} renderer={renderer} /></>
                      }
                      {accessKey.validFromTime !== null
                        && accessKey.validFromTime > Date.now() && <>in <Countdown date={accessKey.validFromTime} daysInHours={true} renderer={renderer} /></>
                      }
                      {accessKey.validFromTime === null
                        && <>for <Countdown date={calculateTimeLeft(accessKey.created)} daysInHours={true} renderer={renderer} /></>
                      }
                    </ValidTime>
                    <RevokeButton className="accessKeyRevokeButton" variant="text" color="error" onClick={handleRevokeCode}>
                      {t('dialog.search_user_revoke_access')}
                    </RevokeButton>
                  </ItemContainer>
                </>}
                {inviteLink != null && <>
                  <ItemContainer className="itemContainer" >
                    <KeyName className="keyName" variant="h4_column">{t('dialog.search_user_invite_link')}</KeyName>
                    <UsedTimes className="usedTimes" variant="h4_column">{'0/1'}</UsedTimes>
                    <ValidTime className="inviteLinkValidTime" variant="h4_column">
                      for <Countdown className="dateTime" date={calculateTimeLeft(inviteLink.created)} daysInHours={true} renderer={renderer}></Countdown>
                    </ValidTime>
                    <RevokeButton className="revokeInviteLinkButton" onClick={handleRevokeInviteLink} >
                      {t('dialog.search_user_revoke_access')}
                    </RevokeButton>
                  </ItemContainer>
                </>}
              </ListContent>
            }
          </ListContainer>}

      </ProfileContainer>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleInviteMenuClose}
        role="menu"
        aria-label="Access options"
      >
          <StyledMenuItem
            id="createAccessKey"
            role="menuitem"
            disabled={multipleSameDomains || !state.integrationData.domainVerified} //we disable both access key and invite link if user's domain are in multiple integrations
            onClick={() => handleMenuItemClick(0)}
          >
            {"Create Access Key"}
          </StyledMenuItem>
        <StyledMenuItem
            id="createMagicLink"
            role="menuitem"
            disabled={multipleSameDomains || (cbeIntegration && byodIntegration) || !state.integrationData.domainVerified} //if byod is enabled we can invite user only with access key
            onClick={() => handleMenuItemClick(1)}
        >
          {"Create Magic Link"}
        </StyledMenuItem>
      </StyledMenu>
      {deleteDialog &&
        <ModalWrapper>
          <ModalOverlay>
            <ModalContainer role="dialog" aria-modal="true" aria-labelledby="delete-user-title">
              <ModalTitle id="delete-user-title" variant="h2">
                {t('dialog.search_user_delete_user')} [{profileEmail}]?
              </ModalTitle>
              <ModalActions onButtonClick={setDeleteDialog} api={api} userToDelete={profileEmail} setRepeatSearchUser={setRepeatSearchUser}/>
            </ModalContainer>
          </ModalOverlay>
        </ModalWrapper>
      }
    <CreateUserInMsEntraIdDialog show={showCreateUserOnEntra} setShow={setShowCreateUserOnEntra} userEmail={profileEmail}/>
    </MyContainer>

  );

  function EmailField(props) {

    const { repeatSearchUser } = props;
    const [savedUserEmail, setSavedUserEmail] = React.useState("");

    useEffect(() => {
      if (repeatSearchUser) {
        searchUser(profileEmail);
        setRepeatSearchUser(false);
      }
    }, [repeatSearchUser]);
    const handleTextFiledInput = (event) => {
      setSavedUserEmail(event.target.value);
    };

    const validateEmail = (email) => {
      let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.exec(String(email).toLowerCase())
    };

    //check if admin has access to user's integration before we let him search
    const adminHasSearchedIntegration = (user) => {
      let userForSearch = user ? user : savedUserEmail;
      let domain = userForSearch.toLowerCase().substring(userForSearch.indexOf('@') + 1);
      let adminGroups = groups.map(group => group.tableRows);
      let integrations = adminGroups.flat().filter(integration => integration.domain === domain);
      if (integrations.length === 0) {
        setNotOwnedIntegration(true)
        setUserFound(false);
        setIntegrations(null);
        setDevices(null);
        setInviteLink(null);
        setAccessKey(null);
        setUserRegistered("")
        return false
      } else {
        return true
      }
    }

    const checkIntegrationSettings = (user) => {
      let userForSearch = user ? user : savedUserEmail;
      let domain = userForSearch.toLowerCase().substring(userForSearch.indexOf('@') + 1);
      let adminGroups = groups.map(group => group.integrations);
      let integrations = adminGroups.flat().filter(integration => integration.domain === domain);

      //check if there are multiple integrations with same domain we disable invite user options
      if (integrations.length === 1) {
        setMultipleSameDomains(false);
        dispatch({
          type: ACTION_TYPE.INTEGRATION_DATA,
          payload: {
            ...state.integrationData,
            domain: integrations[0].domain,
            integrationId: integrations[0].id,
            domainVerified: integrations[0].domainVerified
          }
        });
      } else {
        setMultipleSameDomains(true);
      }
      //check for cbe settings
      if (integrations[0].authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED || integrations[0].authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED) {
        setCbeIntegration(true)
      } else {
        setCbeIntegration(false)
      }
      if (integrations[0].authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED) {
        setByodIntegration(true)
      } else {
        setByodIntegration(false)
      }
      //if there are more integrations with same domain, we check if there is one with scim enabled
      var scimSet = false
      for (var i = 0; i < integrations.length; i++) {
        if (integrations[i].scimEnabled) {
          setScimEnabled(true)
          scimSet = true
          break;
        }
      }
      if (!scimSet) {
        setScimEnabled(false)
      }
      console.log(scimEnabled)
    }

    const searchUser = (user) => {
      let userForSearch = user ? user : savedUserEmail;
      if (validateEmail(userForSearch)) {
        checkIntegrationSettings(userForSearch)
        if (adminHasSearchedIntegration(userForSearch)) {
          api.searchUsers(userForSearch).then(function (res) {
            setIntegrations(res.data.integrations);
            setDevices(res.data.devices);
            setAccessKey(res.data.managedCode);
            setInviteLink(res.data.inviteLink);
            setUserRegistered(res.data.registered)
            setUserFound(true);
            setNotOwnedIntegration(false)
          }).catch(function (error) {
            log(error)
            setUserFound(false);
            setIntegrations(null);
            setDevices(null);
            setInviteLink(null);
            setAccessKey(null);
            setUserRegistered("")
            setUserFound(false);
            setNotOwnedIntegration(false)
          })
          setProfileEmail(userForSearch)
          setUserSearched(true)
        }
      }
    };
    function handleClick() {
      setSavedUserEmail("")
    }

    const EmailIcon = React.memo(function EmailIcon() {
      return (
        <>
          {savedUserEmail !== "" && <CloseIcon style={{
            width: "24px",
            height: "24px",
            objectFit: "contain",
            objectPosition: "center",
            cursor: "pointer",
          }} onClick={handleClick}></CloseIcon>}
          {savedUserEmail === "" && <SearchIcon style={{
            width: "24px",
            height: "24px",
            objectFit: "contain",
            objectPosition: "center",
          }} ></SearchIcon>}
        </>
      );
    });

    const MemoizedEmailIcon = React.memo(EmailIcon);

    return (
        <StyledTextField
          style={{ height: '48px', marginBottom: 16, flexDirection: 'row', minWidth: "500px" }}
          id="emailInput"
          value={savedUserEmail}
          onChange={(event) => handleTextFiledInput(event)} onKeyPress={(event) => { if (event.key === 'Enter') searchUser() }}
          placeholder={t('dialog.search_user_type_email')}
          InputProps={{
            backgroundColor: "#FFFFFF",
            endAdornment: (
              <InputAdornment position="end">
                <MemoizedEmailIcon></MemoizedEmailIcon>
              </InputAdornment>
            ),
          }}
          fullWidth
          aria-label="Email address"
        />
    );
  }
}

function ModalActions({ onButtonClick, api, userToDelete, setRepeatSearchUser }) {
  const handleConfirm = () => {
    log("Handle dialog delete user ...");
    log("User data: " + userToDelete);
    api.deleteUser(userToDelete).then(function (res) {
      if (res.status === 200) {
        setRepeatSearchUser(true);
      }
    }).catch(function (error) {
      log(error);
    })
    onButtonClick(false)
  };

  const handleCancel = () => {
    onButtonClick(false)
  };

  const buttons = [
    { label: 'No', variant: 'secondary', onClick: handleCancel },
    { label: 'Yes', variant: 'primary', onClick: handleConfirm }
  ];

  return (
    <ButtonGroup>
      {buttons.map((button) => (
        <ModalButton
          key={button.label}
          label={button.label}
          variant={button.variant}
          onClick={button.onClick}
        />
      ))}
    </ButtonGroup>
  );
}

function ModalButton({ label, variant, onClick }) {
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      aria-label={label}
    >
      {label}
    </StyledButton>
  );
}

const StyledButton = styled(Button)(({ variant }) => ({
  borderRadius: '24px',
  background: variant === 'primary' ? '#293dc7' : '#fff',
  color: variant === 'primary' ? '#fff' : '#293dc7',
  width: '100px',
  padding: '12px 16px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '17px',
  '&:hover': {
    background: variant === 'primary' ? '#1f2e99' : '#f5f5f5',
  },
}));

const ButtonGroup = styled(Box)({
  display: 'flex',
  marginTop: '32px',
  width: '100%',
  alignItems: 'flex-start',
  gap: '100px',
  fontSize: '17px',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  lineHeight: 1,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const SPIconName = styled('img')({
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const StyledTextField = styled(TextField)({
  '& input:-webkit-autofill': {
    transition:
        'background-color 600000s 0s, color 600000s 0s'
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    border: "1px solid var(--Gray-3, #d7d7d7)",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.09px",
    paddingLeft: "16px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

const ProfileContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "inherit",
  gap: "24px",
  paddingLeft: "24px",
  paddingRight: "24px"
}));

const HeaderWrapper = styled(Box)(() => ({
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "12px",
  backgroundColor: "#F0F5FF",
  display: "flex",
  gap: "4px",
  flexWrap: "wrap",
  padding: "12px",
  minWidth: "500px",
  marginTop: '-24px'
}));

const ProfileIcon = styled("img")({
  aspectRatio: "1",
  objectFit: "contain",
  objectPosition: "center",
  width: "24px",
});

const UserInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  whiteSpace: "nowrap",
  justifyContent: "flex-start",
  flex: 1,
  flexBasis: "24px",
  gap: "4px"
}));

const EmailContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  fontSize: "18px",
  lineHeight: 1,
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "4px"
}));

const EmailLabel = styled(Box)({
  fontWeight: 400,
  letterSpacing: "0.09px",
});

const EmailValue = styled(Box)(() => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  flex: 1,
  flexBasis: "0%",
}));

const RegistrationContainer = styled(Box)(({ userFound }) => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  fontSize: "14px",
  letterSpacing: "0.14px",
  lineHeight: 1,
  justifyContent: userFound ? "center" : "start",
  flexWrap: "wrap",
}));

const RegistrationLabel = styled(Box)({
  fontWeight: 400,
});

const RegistrationDate = styled(Box)(() => ({
  fontWeight: 600,
  flex: 1,
  flexBasis: "0%",
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "24px",
  width: "48px",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  gap: "8px"
}));

const HeaderText = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: "0.3px",
  margin: 0,
}));

const ListContent = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: 1,
}));

const HeaderRow = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  width: "100%",
  fontSize: "14px",
  fontWeight: 600,
  letterSpacing: "0.14px",
  lineHeight: 1,
  padding: "16px",
  gap: "16px"
}));

const NameColumn = styled(Typography)({
  width: "220px",
});

const RegisteredColumn = styled(Typography)({
  width: "80px",
});

const DevicesContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  width: "100%",
  flexDirection: "column",
  overflow: "hidden",
  fontWeight: 400,
}));

const ItemContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  padding: "16px",
  gap: "16px"
}));

const DeviceName = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
  width: "220px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const TimeContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "220px",
});

const DateText = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
}));

const Time = styled(Typography)(() => ({
  fontSize: "14px",
  lineHeight: 1,
  letterSpacing: "0.14px",
}));

const DomainColumn = styled(Typography)({
  width: "140px",
});

const LicenseColumn = styled(Typography)({
  width: "100px",
});

const LastLoginColumn = styled(Typography)({
  width: "96px",
});

const IntegrationsContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  width: "100%",
  flexDirection: "column",
  overflow: "hidden",
  fontWeight: 400,
  gap: "16px"
}));

const IntegrationName = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
  width: "220px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const DomainText = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
  width: "140px",
}));

const LicenseContainer = styled(Box)({
  width: "100px",
});

const UsedColumn = styled(Typography)({
  width: "140px",
});

const ValidColumn = styled(Typography)({
  flex: 1,
});

const ActionColumn = styled(Typography)({
  width: "128px",
});

const KeyName = styled(Typography)(() => ({
  width: "220px",
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const UsedTimes = styled(Typography)(() => ({
  width: "140px",
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
}));

const ValidTime = styled(Typography)(() => ({
  flex: 1,
  fontSize: "18px",
  lineHeight: 1,
  letterSpacing: "0.09px",
}));

const RevokeButton = styled(Button)(() => ({
  borderRadius: "24px",
  fontSize: "17px",
  fontWeight: 600,
  textAlign: "center",
  lineHeight: "24px",
  fontFamily: "Source Sans Pro",
  minWidth: "auto",
  textTransform: "none",
  color: "#FF0100"
}));

const MyContainer = styled(Box)({
  alignSelf: "stretch",
  borderRadius: "16px",
  border: "1px solid var(--Gray-3, #d7d7d7)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  letterSpacing: "0.09px",
  justifyContent: "flex-start",
  padding: "16px 0",
  fontFamily: "Source Sans Pro, sans-serif",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: 1,
  width: "inherit",
  gap: "8px"
});

const SearchTitle = styled(Typography)({
  color: "#000",
  textAlign: "center",
  letterSpacing: "0.09px",
  padding: 0,
  margin: 0,
  fontFamily: "Source Sans Pro, sans-serif",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: 1,
});

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    border: "1px solid #d7d7d7",
    backgroundColor: "#fff",
    maxWidth: "176px",
    padding: "4px 0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)"
  }
});

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== "selected"
})(({ selected }) => ({
  backgroundColor: selected ? "#f5f5f5" : "#fff",
  padding: "12px 16px",
  color: "#000",
  letterSpacing: "0.09px",
  fontFamily: "Source Sans Pro, sans-serif",
  fontSize: "18px",
  lineHeight: 1,
  fontWeight: 400,
  justifyContent: "flex-start",
  textAlign: "left",
  transition: "background-color 0.2s ease",

  "&:hover": {
    backgroundColor: "#f5f5f5"
  },

  "&:focus-visible": {
    outline: "2px solid #0066ff",
    backgroundColor: "#f5f5f5"
  },

  "&:active": {
    backgroundColor: "#e0e0e0"
  }
}));

const ModalWrapper = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  fontFamily: 'Source Sans Pro, sans-serif',
  zIndex: 1300,
});

const ModalOverlay = styled(Box)({
  backgroundColor: 'rgba(5, 8, 73, 0.5)',
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '288px 80px 508px',
});

const ModalContainer = styled(Box)({
  borderRadius: '16px',
  border: '1px solid #f0f5ff',
  background: '#fff',
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  marginBottom: '-102px',
  width: '656px',
  maxWidth: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '56px',
});

const ModalTitle = styled(Typography)({
  alignSelf: 'stretch',
  flex: 1,
  width: '100%',
  fontSize: '32px',
  color: '#000',
  fontWeight: 700,
  lineHeight: 1,
});