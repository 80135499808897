import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {styled, Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {formatDate} from "../helpers/utils";
import MuiButton from "@mui/material/Button";
import ArrowDownIcon from "../img/arrow_down.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ManageAdminTable(props){
    const { admins, handleAccessLevelButtonClick, currentAdminAccessLevelKey } = props;
    const { t } = useTranslation();

    const TableContainerStyle = {
        minWidth: "754px",
        borderTop: "1px solid var(--Gray-2, #EEE)",
        maxHeight: '320px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D9D9D9',
            borderRadius: '12px'
        }
    }

    const StatusButton = styled(MuiButton)(({status}) => ({
        backgroundColor: (status === "ACCEPTED") ? '#E5FFEA' : '#FFF0EC',
        color: (status === "ACCEPTED") ? '#00A825' : '#EB0000',
        border: '0px',
        padding: '8px 12px',
        borderRadius: 50,
        cursor: 'pointer',
        fontSize: 14,
        textAlign: "center",
        fontWeight: 600,
        height: 32,
        width: '79px',
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        minWidth: "min-content",
        '&:hover': {
            backgroundColor: (status === "ACCEPTED") ? '#E5FFEA' : '#FFF0EC'
        },
    }));

    const AccessLevelButtonStyle = {
        backgroundColor: '#FFFFFF',
        color: '#293DC7',
        border: '0px',
        padding: '8px 12px',
        borderRadius: 50,
        cursor: 'pointer',
        fontSize: 14,
        textAlign: "center",
        fontWeight: 600,
        height: 32,
        width: '79px',
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        minWidth: "min-content",
        '&:hover': {
            color: '#293DC7',
            backgroundColor: '#F0F5FF'
        },
        '&:disabled': {
            color: '#293DC7',
            backgroundColor: '#FFFFFF',
            opacity: '0.5',
        },
    };

    const handleAccessLevelChange = (event, adminEmail, status) => {
        handleAccessLevelButtonClick(event, adminEmail, status);
    }

    const getStatus = (status) => {
        switch (status) {
            case "ACCEPTED":
                return "Accepted"
            case "REJECTED":
                return "Rejected"
            case "INVITED":
                return "Pending"
            default:
                return ""
        }
    }

    return (
        <TableContainer sx={TableContainerStyle}>
            <Table sx={{minWidth: "754px"}}>
                <TableHead>
                    <TableRow sx={{height: '40px'}}>
                        <TableCell align="start"  sx={{width: '280px', padding: '8px 0px'}}  id="emailAddressHeader" key={"Email address"}><Typography variant="h4">{t('admin_access.email_address')}</Typography></TableCell>
                        <TableCell align="center"  sx={{width: '80px', padding: '8px 16px'}} id="statusHeader" key={"Status"}><Typography variant="h4">{t('admin_access.status')}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '280px', padding: '8px 16px'}} id="lastEditedHeader" key={"Last edited"}><Typography variant="h4">{t('admin_access.last_edited')}</Typography></TableCell>
                        <TableCell align="center"  sx={{width: '96px', padding: '8px 0px'}}  id="accessLevelHeader" key={"Access level"}><Typography variant="h4">{t('admin_access.access_level')}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {admins !== undefined && admins.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0}, height: '56px'}}>
                            <TableCell align="start"  sx={{width: '280px', padding: '8px 0px'}}><Typography className="adminEmailAddress" variant="h4_column">{row.emailAddress}</Typography></TableCell>
                            <TableCell align="center"  sx={{width: '80px', padding: '8px 16px'}}><StatusButton className="adminStatus" status={row.status}>{getStatus(row.status)}</StatusButton></TableCell>
                            <TableCell align="start"  sx={{width: '280px', padding: '8px 16px'}}><Typography className="lastEdited" variant="h4_column">{formatDate(row.lastEdited)}</Typography></TableCell>
                            <TableCell align="center"  sx={{width: '96px', padding: '8px 0px'}}>
                                <MuiButton className="accessLevelButton" sx={{...AccessLevelButtonStyle, backgroundColor: row.emailAddress === currentAdminAccessLevelKey? '#F0F5FF' : '#FFFFFF'}} onClick={(event) => handleAccessLevelChange(event, row.emailAddress, row.status)}>
                                    Admin
                                    <img src={ArrowDownIcon} alt="arrow down"/>
                                </MuiButton>
                            </TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}