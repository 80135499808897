import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography/Typography";
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles"
import {ACTION_TYPE, context} from "../State";
import deleteAccountIcon from '../img/delete-red.svg'
import Api from '../helpers/service';
import { log } from '../helpers/utils';
import {InverseButton} from "./CustomUI";
import SettingsIconSrc from "../img/settings_groups.svg";
import {useTranslation} from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
    tabRoot: {
        fontWeight: 600,
        borderRadius: "16px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        fontSize: "17px",
        "&:hover": {
            background: "#F0F5FF",
            color: "#293DC7",
            opacity: 1
        }
    }
}));

export default function TabsComponent(props) {
    const {firstTabTitle, secondTabTitle, firstTabContent, secondTabContent, settings} = props
    const [value, setValue] = React.useState(0);
    const api = new Api();
    const { t } = useTranslation();
    const { dispatch } = React.useContext(context);


    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetIntegrationData();
    };

    const handleOpenManageAllGroups = () => {
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_MANAGE_ALL_GROUPS, payload: true });
    }

    function resetIntegrationData(){
        dispatch({
            type: ACTION_TYPE.INTEGRATION_DATA,
            payload:
            { domain:'',
                integrationId:'',
                clientType: '',
                siteId: '',
                contract: null,
                integrationName: '',
                loginMethod: '',
                fallbackLoginMethod: '',
                numberOfDevices: '',
                metadataUrl: null,
                metadataFile: null,
                clientMetadataId: 0,
                authenticationSettingsDto: null,
                file: null,
                isShared: false,
                numberOfSyncedUsers: 0,
                scimEndpointUrl: "",
                scimEnabled: false,
                scimSecret: "",
                licenseType: "",
                expirationDate: null,
                startDate: null,
                clientId: "",
                siemEnabled: false,
                workspaceId: "",
                serverHostname: "",
                serverPort: 0,
                siemType: "",
                serverName: null,
                domainVerified: false,
                oidcAuthenticationMethod: "client_secret_post",
                oidcSigningAlgorithm: "RS256",
                oidcEncryptionAlgorithm: "none",
                oidcEncryptionMethod: "none"
            }});
    }

    function showDeleteAccountDialog() {

        api.deleteAccountCheck().then(function() {
            dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:true});
        }).catch(function (error) {
            log(error.response.data.error);
            if (error.response.data.error === "HAS_SHARED_INTEGRATIONS") {
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:true});
            } else {
                dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:true});
            }
        })
    }

    return (
        <Box sx={{ marginTop: '24px', width: 'inherit' }}>
            <Box sx={{ padding: '0px 37px', display: 'flex', alignItems: 'center', gap: '4px', alignSelf: 'stretch', justifyContent: 'space-between'}}>
                    <Tabs forceRenderTabPanel={true} onChange={handleChange} >
                        <Tab id='integrationsTab' label={firstTabTitle} classes={{ root: classes.tabRoot }} style={{
                            backgroundColor: value === 0 ? "#F0F5FF" : "inherit",
                            color: value === 0 ? "#293DC7" : "inherit",
                            borderRadius: "16px",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            textTransform: 'none'
                        }}/>
                        {!settings && 
                        <Tab id='usersTab' label={secondTabTitle} classes={{root: classes.tabRoot}} style={{
                            backgroundColor: value === 1 ? "#F0F5FF" : "inherit",
                            color: value === 1 ? "#293DC7" : "inherit",
                            borderRadius: "16px",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            textTransform: 'none'
                        }}/>}
                    </Tabs>
                    {!settings && value === 0 &&
                                <InverseButton id='manageAllGroupsButton' sx={{border: '0px', gap: '4px'}} onClick={handleOpenManageAllGroups}>
                                  <img src={SettingsIconSrc} alt="settings"/>
                                  {t('group_ownership.manage_groups_btn_title')}
                                </InverseButton>}

                    {settings && <InverseButton onClick={showDeleteAccountDialog} sx={{gap: '4px', color: '#FF0100', marginBottom: '4px'}}>
                                <img src={deleteAccountIcon} alt="Delete account"></img>
                                 {t('dialog.button_confirm_delete_account')}
                                 </InverseButton>}
                </Box>
                <TabPanel value={value} index={0} style={{border: '0px', marginTop: '-16px', alignContent: "center"}}>
                    {firstTabContent}
            </TabPanel>
            <TabPanel value={value} index={1} style={{border: '0px', marginTop: '-16px',alignContent: "center" }}>
                    {secondTabContent}
            </TabPanel>

        </Box>
    );
}