import * as React from 'react';
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box/Box";
import {styled} from "@mui/styles";
import FileIconSrc from "../img/document.svg"
import {Card} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DownloadIconSrc from "../img/download.png"


export default function DownloadComponent(props) {
    const { filePath, fileName } = props;

    return (
        <StyledCard>
            <Box display="flex" alignItems="center" gap={1} width="100%">
                <StyledIcon src={FileIconSrc}/>
                <FileName>{fileName}</FileName>
                <ActionContainer>
                    <FileAction
                        filePath={filePath}
                    />
                </ActionContainer>
            </Box>
        </StyledCard>
    );
}

const StyledIcon = styled('img') ({
    width: "24px",
    height: "24px"
});

const StyledCard = styled(Card)({
    padding: "12px",
    maxWidth: "592px",
    backgroundColor: "var(--Gray-1, #f7f7f7)",
    border: "1px solid var(--Blue---primary-10, #f0f5ff)",
    borderRadius: "8px",
    fontFamily: "Source Sans Pro, sans-serif",
    boxShadow: "none"
});

const ActionContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginLeft: "auto"
});

function FileAction({ ariaLabel, filePath }) {
    const onDownload = () => {
        window.location.assign(filePath);
    }
    return (
        <StyledButton
            className='downloadButton'
            variant="text"
            aria-label={ariaLabel}
            onClick={onDownload}
        >
            <StyledIcon src={DownloadIconSrc}/>
        </StyledButton>
    );
}

const StyledButton = styled(IconButton)(() => ({
    height: "24px",
    width: "24px",
    color: "#000000",
    fontSize: "17px",
    fontWeight: 600,
    textAlign: "center",
    lineHeight: 1,
    borderRadius: "24px",
    padding: "4px 8px",
    minWidth: "unset",
    textTransform: "none",

    "&:focus-visible": {
        outline: "0px solid var(--Blue---primary-Blue---source, #293dc7)",
        outlineOffset: "0px"
    }
}));

function FileName({ children }) {
    return <StyledTypography className='fileName'>{children}</StyledTypography>;
}

const StyledTypography = styled(Typography)({
    color: "var(--Gray-9, #202020)",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.09px",
    flex: 1
});
