import React, {useEffect} from "react";
import FileUploader from "./FileUploader";
import TextInput from "./TextInput";
import {isValidDomain, isValidURL} from "../helpers/utils";

export default function ServiceProviderMetadataComponent(props) {
    const { isAddIntegration, isViewIntegration, integrationData, domainRef, integrationDetails, setIntegrationDetails, fileInput, errorMsg, setErrorMsg, serviceProviderSectionRef, inputError, setInputError } = props;

    const [showDomain, setShowDomain] = React.useState(false);
    const [showCustomerId, setShowCustomerId] = React.useState(false);
    const [showMetadata, setShowMetadata] = React.useState(false);
    const [showRedirectUri, setShowRedirectUri] = React.useState(false);

    let type = integrationData.integrationType ? integrationData.integrationType : integrationDetails.integrationType;

    useEffect(() => {
        switch (type) {
            case "OKTA":
            case "SALESFORCE":
            case "AWS":
            case "KEEPER":
            case "CUSTOM":
                setShowMetadata(true);
                break;
            case "GSUITE":
            case "MICROSOFT":
            case "ZENDESK":
                setShowDomain(true);
                break;
            case "TEAMVIEWER":
                setShowCustomerId(true);
                break;
            case "OIDC":
                setShowRedirectUri(true);
                break
            default:
                break;}
    }, [type]);

    const getDomain = (event) => {
        setIntegrationDetails({
            ...integrationDetails,
            domain: event.target.value
        });
       if (!isValidDomain(event.target.value)) {
           setInputError("Not valid domain");
       } else {
           setInputError(null);
       }
    }

    const getCustomerId = (event) => {
        setIntegrationDetails({
            ...integrationDetails,
            customerId: event.target.value
        });
    }

    const getRedirectUri = (event) => {
        setIntegrationDetails({
            ...integrationDetails,
            redirectUri: event.target.value
        });
        if (!isValidURL(event.target.value)) {
            setInputError("Not valid url");
        } else {
            setInputError(null);
        }
    }

    return (
        // tabIndex is set to be able to focus this component when error occurres
        <div ref={serviceProviderSectionRef} tabIndex="0" style={{display: "flex", maxWidth: "592px", flexDirection: "column", fontFamily: "Source Sans Pro, sans-serif"}}>
            {!isAddIntegration && showDomain && <TextInput id={"showDomain"} infoLabel={"Domain"} value={integrationData.domain} disabled={true}/>}
            {isAddIntegration && showDomain && <TextInput id={"showDomain"} inputRef={domainRef} infoLabel={"Domain"} value={integrationDetails.domain} setError={errorMsg || inputError} errorText={errorMsg ? errorMsg : inputError} onChange={(event) => getDomain(event)}/>}
            {!isAddIntegration && showCustomerId && <TextInput id={"customIdentifier"} infoLabel={"Customer identifier"} value={integrationData.customerId} disabled={true}/>}
            {isAddIntegration && showCustomerId && <TextInput id={"customIdentifier"} infoLabel={"Customer identifier"} value={integrationDetails.customerId} onChange={(event) => getCustomerId(event)} setError={errorMsg || inputError} errorText={errorMsg ? errorMsg : inputError}/>}
            {!isAddIntegration && showRedirectUri && <TextInput id={"redirectUri"} infoLabel={"Redirect URI"} value={integrationData.redirectUri} disabled={true}/>}
            {isAddIntegration && showRedirectUri && <TextInput id={"redirectUri"} infoLabel={"Redirect URI"} value={integrationDetails.redirectUri} setError={inputError} errorText={inputError} onChange={(event) => getRedirectUri(event)}/>}
            {!isViewIntegration && showMetadata && <FileUploader integrationType={type} integrationDetails={integrationDetails} setIntegrationDetails={setIntegrationDetails} fileInput={fileInput} errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
            {isViewIntegration && showMetadata && <TextInput id={"metadata"} infoLabel={"Metadata"} value={integrationData.integrationName + "_metadata.xml"} disabled={true}/>}
        </div>
    )
}
