import React, { useEffect } from "react";
import {Button, InverseButton} from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {styled, Typography} from "@mui/material";
import {context, ACTION_TYPE} from "../State";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import TextInput from "./TextInput";
import {validateGroupName} from "../helpers/utils";


export default function CustomDialog (props) {
  const {open, onConfirm, onCancel, title, subtitle, confirmBtnText, cancelBtnText, iconUrl, singleButton, hideCloseButton, email, id, newGroupName, setNewGroupName, linearProgress, linearProgressSubtitle } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errorTxt, setErrorTxt] = React.useState('');

  const {dispatch} = React.useContext(context);

  const {t} = useTranslation();

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

    useEffect(() => {
        const groupNameInputDelay = setTimeout(() => {
            if(newGroupName !== ''){
                if (!validateGroupName(newGroupName)) {
                    setErrorTxt(t('create_new_group.group_name_error_text'));
                } else {
                    setErrorTxt('');
                }
            }
        }, 500);
        return () => clearTimeout(groupNameInputDelay);
    }, [newGroupName]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_UPGRADE_PLAN, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_SUCCESS_UPGRADE_PLAN, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_CHECK_FAILURE, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT_ACCESS_REVOKE, payload:false})
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_ACCOUNT, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_INTEGRATION, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_DEVICE, payload:false});
    dispatch({type: ACTION_TYPE.OPEN_DIALOG_DELETE_USER, payload:false});
    if (onCancel !== undefined) {
      onCancel();
    }
  };

  const StyledTypography = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
  }));

    const handleTextChange = (e) => {
        setNewGroupName(e.target.value);
        setErrorTxt('');
    };

  return (
    <div>
      <Dialog
        id = {id}
        open={openDialog}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style : {
            borderRadius: "16px",
            border: "1px solid #f0f5ff",
            background: "#FFFFFF",
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
            maxWidth: "656px",
            padding: "56px",

          }}}
        sx={{'& .MuiBackdrop-root': {
            backgroundColor: '#05084980'
          }}}
      >
         {iconUrl !== undefined && <div style={{textAlign: 'center'}}> <img src={iconUrl}/></div>}
        <DialogTitle  id="alert-dialog-title"
                      sx={{display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p:0,
                      marginBottom: "16px"
                    }}>
          <StyledTypography > {title} </StyledTypography>
            {!hideCloseButton &&
                <IconButton id="closeDialogButton" onClick={handleDialogClose} aria-label="close"
                            size="large"
                            sx={{borderRadius: "24px",
                                width: "48px",
                                height: "48px",
                                padding: "12px",}}>
                    <CloseIcon />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent sx={{p:0, marginTop: '32px'}}>
          <Typography sx={{fontWeight: 400, fontSize: "18px", lineHeight: "24px", letterSpacing: "0.09px", fontFamily: "Source Sans Pro"}}>
            {subtitle}
          </Typography>
            {linearProgress && <>{linearProgressSubtitle}</>}
          {email !== undefined &&
            <div>
              <br/>
              <Typography component={'span'} variant="h4">
                <div style={{fontWeight: "bold"}}>
                    {email}
                </div>
              </Typography>
            </div>
          }
          {title === t('create_new_group.title_dialog') &&
              <TextInput width={'540px'}
                         infoLabel={t('create_new_group.group_name_label')}
                         placeholder={"Enter here"}
                         onChange={e => handleTextChange(e)}
                         setError={errorTxt !== ''}
                         errorText={errorTxt}
              />
          }
        </DialogContent>
        <DialogActions>
          <div style={{
            marginTop: '32px',
            display: "flex",
            alignItems: "center",
            justifyContent: singleButton? "flex-end" : "space-between",
            width: "100%",
            p: 0,
            gap: "100px",
            '@media (max-width: 991px)': {
              gap: '40px',
              flexWrap: 'wrap'
            }
          }}>
            {(singleButton === 'false' || singleButton === undefined) &&
                <InverseButton id="cancelButton" onClick={handleDialogClose} sx={{border: '0px'}}> {cancelBtnText} </InverseButton>
            }

            <Button id="confirmButton" onClick={() => {onConfirm(); handleDialogClose();}} style={{backgroundColor: (confirmBtnText === t('dialog.button_confirm_delete_integration') || confirmBtnText === t('group_ownership.confirm_button_text')) ? '#FF0100' : '#293DC7'}}>{confirmBtnText}</Button>
            </div>
        </DialogActions>
      </Dialog>
    </div>
);
}