import { Client, ResponseType } from '@microsoft/microsoft-graph-client';
import { log, lowerAndTrimEmail } from "./utils";
import ConfigManager from '../config/config';



export default class GraphApi  {
    STATUS_SUCCESS_INSERT = 201;
    STATUS_SUCCESS_UPDATE = 204;

    init = (accessToken) => {
        this.graphClient = this.getGraphClient(accessToken)
        this.configManager = new ConfigManager().getInstance();
    };

    /**
    * Creating a Graph client instance via options method. For more information, visit:
    * https://github.com/microsoftgraph/msgraph-sdk-javascript/blob/dev/docs/CreatingClientInstance.md#2-create-with-options
    * @param {String} accessToken
    * @returns
    */
    getGraphClient = (accessToken) => {
        // Initialize Graph client
        const graphClient = Client.init({
            // Use the provided access token to authenticate requests
            authProvider: (done) => {
                done(null, accessToken);
            },
        });

        return graphClient;
    };


    graphClientInstance = () => {
        return this.graphClient;
    }


    /**
     * Check if domain is primary domain 
     * @param {String} domain 
     * @returns info if domain is primary
     */

    async isPrimary (domain)  {
        return this.graphClient
        .api('/domains/' + domain)
        .responseType(ResponseType.JSON)
        .get()
        .then(function(res) {
            return res.isDefault;
        })
        .catch((error) => {
            log(error);
            return false;
        })
    }

    /**
     * Check if domain is root
     * @param {String} domain 
     * @returns info if domain is root
     */

    async isRoot (domain)  {
        return this.graphClient
        .api('/domains/' + domain)
        .responseType(ResponseType.JSON)
        .get()
        .then(function(res) {
            return res.isRoot;
        }).catch((error) => {
            log(error);
            return false;
        });
    }

    /**
     * Check if domain is federated
     * @param {String} domain 
     * @returns info if domain is federated
     */

    async isFederated (domain)  {
        return this.graphClient
        .api('/domains/' + domain)
        .responseType(ResponseType.JSON)
        .get()
        .then(function(res) {
            return res.authenticationType;
        });
    }

    
    /**
     * Update isDefault value for the domain
     * @param {String} domain 
     * @param {Boolean} status 
     * @param {function} callback 
     */

    async updateDefaultStatus (domain, status, callback, errorCallback) {
        //create request as JSON
        const updateRequest = {
            "isDefault" : status
        }

        //api call
        await this.graphClient
        .api("/domains/" + domain)
        .responseType(ResponseType.RAW)
        .patch(updateRequest)
        .then((response) => {
            log(response);
            if (response) {
                if (!response.ok) {
                    log("Request not valid");
                    //update frontend to show error if updating default status wasn't successful
                    if (errorCallback !== undefined) {
                        response.json().then(body => {
                            try {
                                errorCallback(body.error.message);
                            } catch (error) {}
                            
                        });
                    }
                } else {
                    log("Response is valid");
                    //update frontend if updating default status was successful
                    if (callback !== undefined) {
                        callback();
                    }
                }
            }
        })
    }

    /**
     * Promote domain to be root
     * @param {String} domain 
     * @param {function} callback 
     */


    async promoteDomain (domain, callback, errorCallback) {
        await this.graphClient
        .api("/domains/" + domain + "/promote")
        .responseType(ResponseType.JSON)
        .post()
        .then((response) => {
            log(response);
            if (response.value) {
                log("Domain is successfully promoted to root");
                if (callback !== undefined) {
                    callback();
                }
            } else {
                log("Error during promotion");
                if (errorCallback !== undefined) {
                    errorCallback("Error during promotion");
                }
            }
        }).catch((error) => {
            log("Error during promotion");
            if (errorCallback !== undefined) {
                errorCallback(error.message.substr(0,error.message.indexOf('.')));
            }
        })
    }


    /**
     * Create new domain/subdomain (note: subdomain cannot be created for federated domain)
     * @param {String} domain 
     * @param {function} callback 
     * @param {function} errorCallback 
     */


    create (domain, callback, errorCallback) {

        const createRequest = {
            "id" : domain
        } 

        this.graphClient
        .api("/domains" )
        .responseType(ResponseType.RAW)
        .post(createRequest)
        .then((response) => {
            log(response);
            log(response.status)
            if (response.status === 201) {
                log("Domain is successfully created");
                if (callback !== undefined) {
                    callback();
                }
            } else {
                log("Error during creation");
                if (errorCallback !== undefined) {
                    errorCallback();
                }
            }
        }).catch((error) => {
            log(error);
            if (errorCallback !== undefined){
                errorCallback();
            }
            
        })
    }

    /**
     * Get tenant managed domains in format : {id: ..., isDefault: ...}
     *  
     */

    async getManagedDomains(domainList) {
        await this.graphClient
            .api("/domains")
            .responseType(ResponseType.JSON)
            .get()
            .then(function (res) {
                log(res);
                const resultArray = res.value;
                for (let i = 0; i < resultArray.length; i++) {
                    if (resultArray[i].authenticationType === "Managed") {
                        let el = {
                            "id": resultArray[i].id,
                            "isDefault": resultArray[i].isDefault
                        }
                        domainList.push(el);
                    }
                }
            }).catch((error) => {
                log(error);
                return [];
            });
    }


    /**
     * Get tenant domains in format : {id: ..., isDefault: ...}
     *  
     */

    async getDomains(domainList) {
        await this.graphClient
            .api("/domains")
            .responseType(ResponseType.JSON)
            .get()
            .then(function (res) {
                log(res);
                const resultArray = res.value;
                for (let i = 0; i < resultArray.length; i++) {
                        let el = {
                            "id": resultArray[i].id,
                            "isDefault": resultArray[i].isDefault
                        }
                        domainList.push(el);
                    
                }
            }).catch((error) => {
                log(error);
                return [];
            });
    }


    /**
     * 
     * @param {String} domain 
     * @returns true (if domain is within list of the existing domains on the tenant)/false (otherwise)
     */
    async checkIfEnteredDomainMatch (domain) {
        var domainList = [];
        await this.getDomains(domainList);
        log(domainList);
        let newArray = domainList.filter(function (el) {
            return lowerAndTrimEmail(el.id) === domain;
        });
        return newArray.length > 0;
    }
    

    /**
     * Get all infos about domain
     * @param {String} domain 
     */

    async getDomain (domain) {
        await this.graphClient
        .api("/domains/" + domain)
        .responseType(ResponseType.JSON)
        .get()
        .then(function(res) {
            log(res);
            return res;
        }).catch((error) => {
            log(error);
        })
    }

    /**
     * 
     * @param {String} domain 
     * @param {function} callback 
     * @param {function} errorCallback 
     */

    async promoteToManaged (domain, callback, errorCallback) {
        const promotionDomainRequest = {
            "authenticationType": "Managed"
        }
        this.graphClient
        .api("/domains/" + domain)
        .responseType(ResponseType.RAW)
        .patch(promotionDomainRequest)
        .then((response) => {
            log(response);
            log(response.status)
            if (response.ok) {
                log("Domain is successfully promoted to managed");
                if (callback !== undefined) {
                    callback();
                }
            } else {
                log("Error during promotion to managed");
                if (errorCallback !== undefined) {
                    errorCallback();
                }
            }
        }).catch((error) => {
            log(error);
            if (errorCallback !== undefined){
                errorCallback();
            }
            
        })
    }

    /**
     * 
     * @param {String} domain 
     * @param {function} callback 
     * @param {function} errorCallback 
     */

    async promoteToFederated(domain, serverName, callback, errorCallback) {
        let uri = serverName != null ? "https://" + serverName + "/wsfed" : this.configManager.idpEndpoint;
        const promotionDomainRequest = {
            "@odata.type": "#microsoft.graph.internalDomainFederation",
            "displayName": "IDEE IdP",
            "issuerUri": this.configManager.idpEntityIdWsfed + domain,
            "metadataExchangeUri": null,
            "signingCertificate": this.configManager.idpSigning1,
            "passiveSignInUri": uri,
            "preferredAuthenticationProtocol": "wsFed",
            "signOutUri": uri,
            "federatedIdpMfaBehavior": "enforceMfaByFederatedIdp"
        }

        await this.graphClient
        .api("/domains/" + domain + "/federationConfiguration")
        .responseType(ResponseType.RAW)
        .post(promotionDomainRequest)
        .then((response) => {
            log(response);
            log(response.status)
            if (response.ok) {
                log("Domain is successfully promoted to federated");
                if (callback !== undefined) {
                    callback();
                }
            } else {
                log("Error during promotion to federated");
                if (errorCallback !== undefined) {
                    errorCallback("Error during promotion to federated");
                }
            }
        }).catch((error) => {
            log(error);
            if (errorCallback !== undefined) {
                errorCallback(error.message.substr(0,error.message.indexOf('.')));
            }
        })
    }


    /**
     * Updates ImmutableId for a particular user.
     *
     * @param {String} id
     * @param {String} email
     * @param {function} callback
     * @param {function} errorCallback
     */
    async updateImmutableIdForUser(id, email, callback, errorCallback) {
        const updateRequest = {
            "onPremisesImmutableId": email
        }

        log(updateRequest);

        await this.graphClient
        .api("/users/" + id)
        .responseType(ResponseType.RAW)
        .patch(updateRequest)
        .then((response) => {
            log(response);
            log(response.status)
            if (response.ok) {
                log("User immutable id is successfully updated.");
                if (callback !== undefined) {
                    callback();
                }
            } else {
                log("Error during update of the user's immutable id.");
                if (errorCallback !== undefined) {
                    response.json().then(body => {
                        try {
                            errorCallback(body.error.message);
                        } catch (error) {}
                        
                    });
                }
            }
        }).catch((error) => {
            log(error);
            if (errorCallback !== undefined) {
                errorCallback(error.message.substr(0,error.message.indexOf('.')));
            }
        })
    }

    /**
     * Retrieves the list of users belonging to a domain.
     *
     * @param {String} domain User domain
     * @param {Array} usersList List of users
     */
    async getUsersForDomain(domain, usersList) {
        return this.graphClient
        .api("/users?$select=id,userPrincipalName,onPremisesImmutableId&$count=true&$filter=endswith(userPrincipalName,'@" + domain + "')+and+userType+eq+'Member'&$top=999")
        .responseType(ResponseType.JSON)
        .header("ConsistencyLevel", "eventual")
        .get()
        .then(function(res) {
            log(res.value);
            for (const user of res.value) {
                let el = {
                    "id": user.id,
                    "userPrincipalName": user.userPrincipalName,
                    "onPremisesImmutableId": user.onPremisesImmutableId
                }
                usersList.push(el);
            }
        }).catch((error) => {
            log(error);
            return [];
        })
    }


    async checkIfHybridSetup() {
        return this.graphClient
        .api("/devices?$select=id,domainName")
        .responseType(ResponseType.JSON)
        .header("ConsistencyLevel", "eventual")
        .get()
        .then(function(res) {
            log(res.value);
            let newArray = res.value.filter(function (el) {
                return el.domainName !== null;
            });
            return newArray.length > 0;
        }).catch((error) => {
            log(error);
            return false;
        })
    }

    async checkIfHybridSetupCheckOnPremisesLastSyncDateTime() {
        return this.graphClient
        .api("/devices?$select=id,onPremisesLastSyncDateTime")
        .responseType(ResponseType.JSON)
        .header("ConsistencyLevel", "eventual")
        .get()
        .then(function(res) {
            log(res.value);
            let newArray = res.value.filter(function (el) {
                return el.onPremisesImmutableId !== null;
            });
            return newArray.length > 0;
        }).catch((error) => {
            log(error);
            return false;
        })
    }

}















