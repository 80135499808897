import React from 'react';
import {styled} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import GrantAccessButtonToggle from "./GrantAccessButtonToggle";
import {federateDomainTokenRequest} from "../config/authConfig";


export default function GrantIPAccessComponent(props) {
    const { showGrantAccessButton, setShowGrantAccessButton, adminEmail, setAdminEmail, setDisabled, isViewModeOn, fontWeight, messageWidth} = props;

    const { t } = useTranslation();

    const StyledDescription = styled(Typography)(() => ({
        color: "#000000",
        fontFamily: "Source Sans Pro",
        fontSize: "18px",
        fontStyle: "normal",
        lineHeight: "24px",
        fontWeight: fontWeight ? fontWeight : 400,
        marginBottom: "24px",
        letterSpacing: "0.09px",
    }));

    return (<>
        <StyledDescription>{t('create_azure_user.grant_access_heading')}</StyledDescription>
        {!isViewModeOn && <GrantAccessButtonToggle showGrantAccessButton={showGrantAccessButton}
                                  setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail}
                                  setAdminEmail={setAdminEmail} configTokenRequest={federateDomainTokenRequest}
                                  setDisabled={setDisabled} messageWidth={messageWidth}/>
        }
    </>);

}