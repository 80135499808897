import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextFieldCopy from "./TextFieldCopy";
import { useTranslation } from "react-i18next";

const TagWrapper = styled(Box)(({ theme }) => ({
    alignSelf: "stretch",
    borderRadius: "50px",
    background: "var(--Blue---primary-10, #f0f5ff)",
    gap: "4px",
    overflow: "hidden",
    color: "var(--Blue---primary-Blue---source, #293dc7)",
    whiteSpace: "nowrap",
    letterSpacing: "0.14px",
    padding: "8px 12px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create(["outline", "transform"], {
        duration: theme.transitions.duration.shortest,
    }),
    "&:focus": {
        outline: "none",
    },
    "&:focus-visible": {
        outline: `2px solid ${theme.palette.primary.main}`,
        outlineOffset: "2px",
    },
    "&:hover": {
        cursor: "default",
    },
    "@media (prefers-reduced-motion: reduce)": {
        transition: "none",
    },
    "@media screen and (forced-colors: active)": {
        borderColor: "ButtonText",
        color: "ButtonText",
    },
}));

const TextInfoWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    fontFamily: "Source Sans Pro, sans-serif",
    justifyContent: "start",
    paddingBottom: '24px'
});


function DomainInviteSection(props) {
    const { t } = useTranslation();
    const { licenceType, domainInviteLink } = props
    return (
        <>
            <TextInfoWrapper>
                <TagWrapper
                >
                    {licenceType}
                </TagWrapper>
            </TextInfoWrapper>
            <TextFieldCopy
                infoDescription={{
                    "type": "domaininvitelink",
                    "label": t('integration_view.domain_invite_link')
                }}
                isOidc={false}
                isCopied={true}
                isEditable={false}
                value={domainInviteLink}
            ></TextFieldCopy>
        </>

    );
}


export default DomainInviteSection;