import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {styled, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ACTION_TYPE, context} from "../State";
import ManageAllGroupsTable from "./ManageAllGroupsTable";

export default function ManageAllGroupsDialog(props) {
    const { open, groups } = props;
    const { dispatch } = React.useContext(context);
    const { t } = useTranslation();

    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        dispatch({ type: ACTION_TYPE.OPEN_DIALOG_MANAGE_ALL_GROUPS, payload: false });
    };

    const StyledTypography = styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: 1,
        fontFamily: "Source Sans Pro, sans-serif",
    }));

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            PaperProps={{style : {
                    borderRadius: "16px",
                    border: "1px solid #f0f5ff",
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                    minWidth: "896px",
                    padding: "56px",
                    gap: "32px"
                }}}
            sx={{'& .MuiBackdrop-root': {
                    backgroundColor: '#05084980'
                }}}>

            <DialogTitle sx={{display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p:0,
                marginBottom: "16px"
            }}>
                <StyledTypography id="manageAllGroupsTitle">{t('group_ownership.manage_groups_btn_title')}</StyledTypography>
                <IconButton
                    id="closeManageGroups"
                    aria-label="close"
                    onClick={handleDialogClose}
                    size="large"
                    sx={{borderRadius: "24px",
                        width: "48px",
                        height: "48px",
                        padding: "12px",}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{overflow: 'hidden', padding: '0px'}}>
                <ManageAllGroupsTable groups={groups.filter(group => group.owner === "Me")} />
            </DialogContent>

        </Dialog>
    );
}