import React, { useEffect } from "react";
import { Button } from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Trans, useTranslation} from "react-i18next";
import {styled, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextInput from "./TextInput";
import { log, lowerAndTrimEmail, validateEmail} from "../helpers/utils";
import Api from "../helpers/service";
import ManageAdminTable from "./ManageAdminsTable";
import SelectAdminAccess from "./SelectAdminAccess";
import CustomDialog from "./DialogText";
import {ACTION_TYPE, context} from "../State";
import CustomSnackbar from "./Snackbar";


export default function GroupSettings(props){

    const {open, currentSettingsGroupName, groupContainerId, setOpenSettingsState } = props;

    const { t } = useTranslation();
    const [errorTxt, setErrorTxt] = React.useState('');
    const [adminFound, setAdminFound] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const [admins, setAdmins] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAccessLevelMenu, setOpenAccessLevelMenu] = React.useState(false);
    const [currentAdminAccessLevelKey, setCurrentAdminAccessLevelKey] = React.useState('');
    const [openAdminAccessRevokeDialog, setOpenAdminAccessRevokeDialog] = React.useState(false);
    const [openTransferGroupOwnershipDialog, setOpenTransferGroupOwnershipDialog] = React.useState(false);
    const [openTransferGroupOwnershipMessageDialog, setOpenTransferGroupOwnershipMessageDialog] = React.useState(false);
    const [showInviteAdminSnack, setShowInviteAdminSnack] = React.useState(false);
    const [showOwnershipTransferredSnack, setShowOwnershipTransferredSnack] = React.useState(false);
    const [openTransferContractErrorDialog, setOpenTransferContractErrorDialog] = React.useState(false);
    const [openTransferBrandingErrorDialog, setOpenTransferBrandingErrorDialog] = React.useState(false);
    const [openGenericErrorDialog, setOpenGenericErrorDialog] = React.useState(false);
    const [errorTextIds,setErrorTextIds] = React.useState(null);
    const [currentAdminStatus, setCurrentAdminStatus] = React.useState(null);

    const {state, dispatch} = React.useContext(context);
    const api = new Api();

    const ERRROS = {
        EMAIL_ALREADY_SENT: "Granting access email already sent",
        USER_IS_OWNER_OF_THE_GROUP: "This user is the owner of this group."
    }

    useEffect(() => {
        const adminEmailInputDelay = setTimeout(() => {
            let emailEdited = lowerAndTrimEmail(email);
            if(email !== ''){
                if (!validateEmail(emailEdited)) {
                    setAdminFound(false);
                    setErrorTxt(t('dialog.error_text'));
                } else {
                    api.checkIfAdminExists(emailEdited).then(function () {
                        setAdminFound(true);
                        setErrorTxt('');
                    }).catch(function () {
                        setAdminFound(false);
                        setErrorTxt(t('admin_access.admin_not_found'));
                    })
                }
            }
        }, 500);

        return () => clearTimeout(adminEmailInputDelay);
    }, [email])

    const fetch = async (groupContainerId) => {
        try {
            const {data} = await api.getAdmins([groupContainerId]);
            setAdmins(data);
        }catch (err) {
            log(err);
        }
    }

    useEffect( () => {
        fetch(groupContainerId);
    },[]);


    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenSettingsState(false);
        setAdminFound(false);
        setErrorTxt('');
        setEmail('');
    };

    const handleAdminInvite = (email, containerId) => {
        log("Email: " + email);
        let emailEdited = lowerAndTrimEmail(email);
        api.grantAdminAccess(emailEdited, containerId).then(function (res) {
            log(res.data);
            setShowInviteAdminSnack(true);
            setErrorTxt('');
            fetch(groupContainerId);
        }).catch(function (error) {
            log(error);
            if (error.response.data == ERRROS.EMAIL_ALREADY_SENT || error.response.data == ERRROS.USER_IS_OWNER_OF_THE_GROUP) {
                setErrorTxt(t('admin_access.admin_already_has_access'));
            } else {
                setErrorTxt(t('admin_access.admin_not_found'));
            }
            setAdminFound(false);
        })

    };

    const handleInviteAdminSnackBarClose = () => {
        setShowInviteAdminSnack(false);
    }

    const handleOwnershipTransferredSnackBarClose = () => {
        setShowOwnershipTransferredSnack(false);
    }

    const handleTextChange = (e) => {
        setEmail(e.target.value);
        setErrorTxt('');
    };

    const handleCloseAccessLevelMenu = () => {
        setOpenAccessLevelMenu(false);
    };

    const handleAccessLevelButtonClick = (event, adminEmail, status) => {
        setAnchorEl(event.currentTarget);
        setCurrentAdminAccessLevelKey(adminEmail);
        setOpenAccessLevelMenu(true);
        setCurrentAdminStatus(status);
    }

    function handleAdminAccessRevoke () {
        api.revokeAdminAccess(currentAdminAccessLevelKey, groupContainerId).then(function (res) {
            log(res.data);
            dispatch({type: ACTION_TYPE.SHOW_ACCESS_REVOKED_SNACK, payload:true});
            fetch(groupContainerId);
            setCurrentAdminAccessLevelKey('');
        }).catch(function (error) {
            log(error);
            setCurrentAdminAccessLevelKey('');
        })
    }

    const handleSnackAccessRevokedClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch({type: ACTION_TYPE.SHOW_ACCESS_REVOKED_SNACK, payload:false});
    }

    function handleTransferGroupOwnership () {

        api.transferContainers(currentAdminAccessLevelKey, [groupContainerId]).then(function (res) {
            log(res.data);
            setOpenTransferGroupOwnershipMessageDialog(true);
            setShowOwnershipTransferredSnack(true);
        }).catch(function (error) {
            log(error);
            if(error.response.data.status === "CONTRACTS_IN_OTHER_CONTAINERS") {
                setErrorTextIds(error.response.data.contracts);
                setOpenTransferContractErrorDialog(true);
            } else if(error.response.data.status === "BRANDINGS_IN_OTHER_CONTAINERS") {
                setErrorTextIds(error.response.data.brandings);
                setOpenTransferBrandingErrorDialog(true);
            } else {
                setOpenGenericErrorDialog(true);
            }
            setCurrentAdminAccessLevelKey('');
        })
    }

    function handleTransferGroupOwnershipMessage () {
        setOpenTransferGroupOwnershipMessageDialog(true);
    }

    const StyledTypography = styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: 1,
        fontFamily: "Source Sans Pro, sans-serif",
    }));

    return (<>
        <Dialog
            open={open}
            onClose={handleDialogClose}
            PaperProps={{style : {
                    borderRadius: "16px",
                    border: "1px solid #f0f5ff",
                    background: "#FFFFFF",
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                    minWidth: "896px",
                    padding: "56px",
                    gap: "32px"
                }}}
            sx={{'& .MuiBackdrop-root': {
                    backgroundColor: '#05084980'
                }}}
        >

            <DialogTitle sx={{display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p:0,
                marginBottom: "16px"
            }}>
                <StyledTypography id="groupsSettingsTitle">{t('admin_access.group_settings_title')}</StyledTypography>
                <IconButton
                    id="closeGroupSettings"
                    aria-label="close"
                    onClick={handleDialogClose}
                    size="large"
                    sx={{borderRadius: "24px",
                        width: "48px",
                        height: "48px",
                        padding: "12px",}}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{overflow: 'hidden', padding: '0px'}}>
                <Box style={{display: "flex",flexDirection: "column", gap: "16px", marginBottom: "10px"}}>
                    <Typography variant="h2">{t('admin_access.group_settings_subtitle')}</Typography>
                    <div style={{display: "flex",alignItems: 'flex-start', gap: "16px", minHeight: '93px'}}>
                        <TextInput 
                                   id={"inviteAdminEmail"}
                                   width={'640px'}
                                   infoLabel={t('admin_access.text_input_label')}
                                   placeholder={"Enter here"}
                                   onChange={e => handleTextChange(e)}
                                   setError={errorTxt !== ''}
                                   errorText={errorTxt}/>
                        <Button id="sendInviteButton" sx={{marginTop: '20px', height: '49px'}} disAabled={adminFound === false} onClick={() => {handleAdminInvite(email, groupContainerId)}}>
                            {t('admin_access.send_invite')}
                        </Button>
                    </div>
                </Box>
                <Box style={{display: "flex",flexDirection: "column", gap: "16px"}}>
                    <Typography variant="h2">{t('admin_access.heading_admin_access')}</Typography>
                    <ManageAdminTable admins={admins} handleAccessLevelButtonClick={handleAccessLevelButtonClick} currentAdminAccessLevelKey={currentAdminAccessLevelKey}/>
                    <SelectAdminAccess open={Boolean(openAccessLevelMenu)} onClose={handleCloseAccessLevelMenu} anchorEl={anchorEl} currentAdminAccessLevelKey={currentAdminAccessLevelKey} setOpenAdminAccessRevokeDialog={setOpenAdminAccessRevokeDialog} setOpenTransferGroupOwnershipDialog={setOpenTransferGroupOwnershipDialog} status={currentAdminStatus}/>
                    <CustomDialog
                        open={openAdminAccessRevokeDialog}
                        title={t('admin_access.title_dialog',{ admin_email: currentAdminAccessLevelKey })}
                        hideCloseButton={true}
                        confirmBtnText={"Yes"}
                        cancelBtnText={"No"}
                        onCancel={() => setOpenAdminAccessRevokeDialog(false)}
                        onConfirm={() => handleAdminAccessRevoke()}/>
                    <CustomDialog
                        open={openTransferGroupOwnershipDialog}
                        title={t("group_ownership.title_dialog")}
                        subtitle={<Trans t={t} i18nKey="group_ownership.subtitle_dialog" >Are you sure you want to make <b>{{new_owner: currentAdminAccessLevelKey}}</b> the owner of <b>{{group_name: currentSettingsGroupName}}</b> ? <b>You can’t undo this action.</b> </Trans>}
                        hideCloseButton={true}
                        confirmBtnText={t("group_ownership.confirm_button_text")}
                        cancelBtnText={"Cancel"}
                        onCancel={() => setOpenTransferGroupOwnershipDialog(false)}
                        onConfirm={() => handleTransferGroupOwnership()}/>
                    <CustomDialog
                        open={openTransferGroupOwnershipMessageDialog}
                        title={t("group_ownership.message_title")}
                        subtitle={<Trans t={t} i18nKey="group_ownership.message_subtitle" >Owner of <b>{{group_name: currentSettingsGroupName}}</b> is {{new_owner: currentAdminAccessLevelKey}}. You don’t have access to this group anymore.</Trans>}
                        hideCloseButton={true}
                        confirmBtnText={"Ok"}
                        singleButton={true}
                        onConfirm={() => handleTransferGroupOwnershipMessage()}
                        onCancel={() => {
                            setOpenTransferGroupOwnershipMessageDialog(false);
                            setCurrentAdminAccessLevelKey('');
                            window.location.reload();
                        }}/>
                    <CustomDialog
                        open={openTransferContractErrorDialog}
                        singleButton={true}
                        title={t('group_ownership.error_title')}
                        onConfirm={()=> {
                            setOpenTransferContractErrorDialog(false);
                            setErrorTextIds(null);
                        }}
                        subtitle={<Trans t={t} i18nKey="group_ownership.error_contracts_text" >You are attempting to transfer groups, but they do not contain all integrations for Contract(s) <b>[{{contract_ids: errorTextIds}}]</b>. Before you can transfer these groups, please move all integrations for Contract(s) <b>[{{contract_ids: errorTextIds}}]</b> into Groups <b>[{{names: currentSettingsGroupName}}]</b>. <br/> <br/>And then try one more time.</Trans>}
                        confirmBtnText={"Ok"}
                        hideCloseButton={true}
                    />
                    <CustomDialog
                        open={openTransferBrandingErrorDialog}
                        singleButton={true}
                        title={t('group_ownership.error_title')}
                        onConfirm={()=> {
                            setOpenTransferBrandingErrorDialog(false);
                            setErrorTextIds(null);
                        }}
                        subtitle={<Trans t={t} i18nKey="group_ownership.error_brandings_text" >You are attempting to transfer groups, but they do not contain all integrations for Branding(s) <b>[{{branding_ids: errorTextIds}}]</b>. Before you can transfer these groups, please move all integrations for Branding(s) <b>[{{branding_ids: errorTextIds}}]</b> into Groups <b>[{{names: currentSettingsGroupName}}]</b>. <br/> <br/>And then try one more time.</Trans>}
                        confirmBtnText={"Ok"}
                        hideCloseButton={true}
                    />
                    <CustomDialog
                        open={openGenericErrorDialog}
                        singleButton={true}
                        title={t('group_ownership.error_title')}
                        onConfirm={()=> {
                            setOpenGenericErrorDialog(false);
                            setErrorTextIds(null);
                        }}
                        subtitle={t('group_ownership.error_generic_text')}
                        confirmBtnText={"Ok"}
                        hideCloseButton={true}
                    />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button id="closeButton" onClick={handleDialogClose}>{t('Close')}</Button>
            </DialogActions>
        </Dialog>
        <CustomSnackbar open={state.showAccessRevokedSnack} onCancel={handleSnackAccessRevokedClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.info_access_revoked')} buttonText={t('notification.okay')} showIcon = 'true'/>
        <CustomSnackbar open={showInviteAdminSnack} onCancel={handleInviteAdminSnackBarClose} type={'success'} vertical={'bottom'} horizontal={'right'} text={t('notification.share_integration_group_invitation')} buttonText={t('notification.okay')} showIcon = 'true'/>
        <CustomSnackbar open={showOwnershipTransferredSnack} onCancel={handleOwnershipTransferredSnackBarClose} type={'recommendation'} vertical={'bottom'} horizontal={'right'} text={<Trans t={t} i18nKey="group_ownership.snackbar_text" >Owner of <b>{{group_name: currentSettingsGroupName}}</b> is {{new_owner: currentAdminAccessLevelKey}}.</Trans>} buttonText={t('notification.okay')} showIcon = 'true'/>

    </>);
}