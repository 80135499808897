import React, { useEffect, useState } from "react";
import { Button, InverseButton } from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CustomSnackbar from "./Snackbar";
import { Typography} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { styled } from "@mui/material";
import Api from '../helpers/service'
import { log, validateEmail } from '../helpers/utils'
import { context, ACTION_TYPE } from "../State";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { FormControlLabel } from "@mui/material"
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import DateTimePickerCustom from "./DateTimePicker";
import i18n from "../i18n/config";
import CloseIcon from '@mui/icons-material/Close';
import TextInput from "./TextInput";
import TextFieldCopy from "./TextFieldCopy";
import Box from "@mui/material/Box";

export default function CustomDialogTextBox(props) {
  const { open, confirmBtnText, confirmBtnTextAdditional, id, authenticationSettingsDto, prefilledEmail, inviteMenuIndex, setInviteMenuIndex, setInviteUserIntegrationId, inviteUserIntegrationId, inviteUserIntegrationDomain, setInviteUserIntegrationDomain, setRepeatSearchUser } = props;
  const [openDialog, setOpenDialog] = React.useState(false);
  const { t } = useTranslation();
  const [linkCopiedText, setLinkCopiedText] = React.useState('');
  const [linkCopiedTextCode, setLinkCopiedTextCode] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [magicLink, setMagicLink] = React.useState('');
  const [code, setCode] = React.useState('');
  const [resultReady, setResultReady] = React.useState(false);
  const [resultReadyCode, setResultReadyCode] = React.useState(false);
  const [errorTxt, setErrorTxt] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showSnackErrorGrantOwnAccess, setShownShackErrorGrantOwnAccess] = React.useState(false);
  const [numberOfUsages, setNumberOfUsages] = React.useState(1);
  const [validFromTime, setValidFromTime] = React.useState(null);
  const [selectedNumberOfUsages, setSelectedNumberOfUsages] = React.useState(1);
  const [valueNumberOfUsages, setValueNumberOfUsages] = React.useState('one-time');
  const [valueValidFromTime, setValueValidFromTime] = React.useState('right-away');
  const api = new Api();
  const { state, dispatch } = React.useContext(context);
  const [showCode, setShowCode] = useState(false);
  const lang = i18n.language;
  const numberOfUsagesOptions = [1,2,3,4,5,6,7,8,9,10]

  useEffect(() => {
    setOpenDialog(open);
    if (prefilledEmail) {
      if (inviteMenuIndex === 0) {
        createCode(prefilledEmail);
      } else if (inviteMenuIndex === 1) {
        createMagicLink(prefilledEmail);
      }
    }
  }, [open]);

  const handleDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    
    setOpenDialog(false);
    setInviteMenuIndex(null);
    dispatch({ type: ACTION_TYPE.OPEN_DIALOG_INVITE_NEW_USER, payload: false });
    setResultReady(false);
    setResultReadyCode(false);
    setLinkCopiedText('');
    setLinkCopiedTextCode('');
    setErrorTxt('');
    setShowCode(false);
    setValidFromTime(null);
    setNumberOfUsages(1);
    setSelectedNumberOfUsages(1);
    setValueNumberOfUsages('one-time');
    setValueValidFromTime('right-away');
    setInviteUserIntegrationId(null);
    setInviteUserIntegrationDomain(null);
    setRepeatSearchUser(true);
  };

  const handleTextChange = (e) => {
    setEmail(e.target.value);
    setErrorTxt('');
  };

  const handleSnackBarErrorClose = () => {
    setShownShackErrorGrantOwnAccess(false);
  }

  function createMagicLink(email) {
    if (!validateEmail(email)) {
      setErrorTxt(t('dialog.error_text'));
      return;
    }

    let domain = inviteUserIntegrationDomain !== null ? inviteUserIntegrationDomain : state.integrationData.domain;
    let integrationId = inviteUserIntegrationId !== null ? inviteUserIntegrationId : state.integrationData.integrationId

    api.createMagicLink(email, domain, integrationId, lang).then(function (res) {
      log(res.data);
      if (!res.data.errorMessage) {
        setMagicLink(res.data.url);
        setEmail('');
        setResultReady(true);
      } else {
        setErrorTxt(res.data.url);
      }
    }).catch(function (error) {
      log(error);
      if (error.response.status === 403) {
        handleDialogClose();
        setErrorMessage(t('dialog.create_magic_link_error'));
        setShownShackErrorGrantOwnAccess(true);
      } else {
        setErrorTxt(t('dialog.not_same_domain'));
      }
    })

  }

  function createCode(email) {
    if (!validateEmail(email)) {
      setErrorTxt(t('dialog.error_text'));
      return;
    }

    let domain = inviteUserIntegrationDomain !== null ? inviteUserIntegrationDomain : state.integrationData.domain;
    let integrationId = inviteUserIntegrationId !== null ? inviteUserIntegrationId : state.integrationData.integrationId

    api.createCode(email, domain, integrationId, 'en').then(function (res) {
      log(res.data);
      if (!res.data.errorMessage) {
        setCode(res.data.code);
        setResultReadyCode(true);
      } else {
        setErrorTxt(res.data.code);
      }
    }).catch(function (error) {
      log(error);
      if (error.response.status === 403) {
        handleDialogClose();
        setErrorMessage(t('dialog.create_magic_link_error'));
        setShownShackErrorGrantOwnAccess(true);
      } else {
        setErrorTxt(t('dialog.not_same_domain'));
      }
    })
  }

  function updateCode(email) {
    api.updateCode(email, inviteUserIntegrationId !== null ? inviteUserIntegrationId : state.integrationData.integrationId, numberOfUsages, validFromTime).then(function(res){
      log(res.data);
      setEmail('');
    }).catch(function (error) {
      log(error);
    })
  }

  const handleNumberOfUsagesChange = event => {
    setValueNumberOfUsages(event.target.value);
  }

  const handleChangeValidFromTime = event => {
    setValueValidFromTime(event.target.value);
  }

  const handleChangeSelection = event => {
    setSelectedNumberOfUsages(event.target.value);
    setNumberOfUsages(event.target.value);
  }

  const StyledTypography = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: 1,
    fontFamily: "Source Sans Pro, sans-serif",
  }));


  return (
    <>
        <Dialog
            id = {id}
            open={openDialog}
            onClose={handleDialogClose}
            fullWidth
            width="656px"
            PaperProps={{style : {
                borderRadius: "16px",
                border: "1px solid #f0f5ff",
                background: "#FFFFFF",
                boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                maxWidth: "656px",
                padding: "56px",
                }}}
            sx={{'& .MuiBackdrop-root': {
                backgroundColor: '#05084980'
              }}}
            scroll="body"
        >
          <DialogTitle sx={{display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p:0,
            marginBottom: "16px"
          }}>
            <StyledTypography variant="h4">
              {(!resultReady && !resultReadyCode)? t('invite_user.invite_user_title') : (resultReady? t('invite_user.create_magic_link_title') : t('invite_user.create_access_key_title') )}
            </StyledTypography>
            <IconButton
                aria-label="close"
                id="closeInviteUserTab"
                onClick={handleDialogClose}
                size="large"
                sx={{borderRadius: "24px",
                  width: "48px",
                  height: "48px",
                  padding: "12px",}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>


        <DialogContent sx={{overflow: 'hidden', padding: '0px'}}>
          {!resultReady && !resultReadyCode &&
              <TextInput infoLabel={t('invite_user.text_input_label')} disabled={showCode}
                     placeholder={t('invite_user.domain_placeholder', {domain: state.integrationData.domain ? state.integrationData.domain : "example.com"})}
                     onChange={e => handleTextChange(e)} setError={errorTxt !== ''} errorText={errorTxt} />}

          {resultReady &&
              <div style={{paddingTop: '16px'}}>
              <TextFieldCopy infoDescription={{type: "magic_link",label: t('invite_user.text_input_magic_link_label')}} value={magicLink} isEditable={false} isCopied={true}/>
              </div>
          }

            {resultReadyCode &&
              <>
                <>
                  <Box>

                    <FormControl disabled={showCode}>
                      <StyledTypography sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "0.09px"}}>Key type</StyledTypography>
                      <RadioGroup
                        aria-labelledby="num-of-usages-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group-number-of-usages"
                        sx={{marginLeft: "16px"}}
                        value={valueNumberOfUsages}
                        onChange={handleNumberOfUsagesChange}
                      >
                        <FormControlLabel value="one-time" control={<Radio />} label="One-time use" />
                        <div style={{alignItems: "center",
                          alignSelf: "stretch",
                          display: "flex",
                          justifyContent: "start",
                          flexWrap: "wrap",
                          }}><FormControlLabel value="multiple" control={<Radio />} label="Multi-use" />
                          <FormControl sx={{ m: 1,
                            height: '48px', width: '65px'
                             }}>
                            <Select
                                sx={{alignItems: "center",
                                  borderRadius: "8px",
                                  border: (valueNumberOfUsages === 'one-time' || showCode) ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
                                  backgroundColor: (valueNumberOfUsages === 'one-time' || showCode) ? "var(--Gray-2, #eee)" : "#FFFFFF",
                                  display: "flex",
                                  gap: "8px",
                                  justifyContent: "start",
                                  height: '48px', width: '65px',

                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: (valueNumberOfUsages === 'one-time' || showCode) ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: (valueNumberOfUsages === 'one-time' || showCode) ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: (valueNumberOfUsages === 'one-time' || showCode) ? "0px solid var(--Gray-2, #eee)" : "1px solid var(--Gray-2, #eee)",
                                  },

                                }}
                                disabled={valueNumberOfUsages === 'one-time' || showCode}
                                labelId="number-of-usages-select-standard-label"
                                id="number-of-usages-select-standard"
                                value={selectedNumberOfUsages}
                                onChange={handleChangeSelection}
                                variant="outlined">
                              {numberOfUsagesOptions.map( number => <MenuItem value={number}> {number} </MenuItem>)}
                            </Select>
                          </FormControl></div>
                      </RadioGroup>
                    </FormControl>

                  </Box>
                  <Box sx={{marginTop: '32px'}}>

                    <FormControl disabled={showCode}>
                      <StyledTypography sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "0.09px"}}>Time frames</StyledTypography>
                      <RadioGroup
                        aria-labelledby="valid-from-time-controlled-radio-buttons-group-valid-from-time"
                        name="controlled-radio-buttons-group"
                        value={valueValidFromTime}
                        sx={{marginLeft: "16px"}}
                        onChange={handleChangeValidFromTime}
                      >
                        <FormControlLabel value="right-away" control={<Radio />} label="Use right away" />
                        <div style={{alignItems: "center",
                          alignSelf: "stretch",
                          display: "flex",
                          justifyContent: "start",
                          flexWrap: "wrap",
                        }}>
                          <FormControlLabel value="specific-time" control={<Radio/>} label="Use on specific time"/>
                          <DateTimePickerCustom
                            sx={{marginBottom: '-4px'}}
                            disabled={valueValidFromTime === 'right-away' || showCode }
                            setValidFromTime={setValidFromTime} />
                        </div>
                      </RadioGroup>
                    </FormControl>

                  </Box>
                </>
                {resultReadyCode &&
                  <Button id="generateAccessKey" disabled={showCode} onClick={() => { updateCode(prefilledEmail ? prefilledEmail : email); setShowCode(true) }} style={{ marginTop: 15, marginLeft: 2 }}> {t('dialog.generate')} </Button>
                }
                <div style={{ display: "flex", visibility: showCode ? 'visible' : 'hidden', height: showCode ? "100%" : "0px", flexDirection: 'column', marginTop: '32px' }}>
                  <StyledTypography sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.09px"}}>{"Copy access key"}</StyledTypography>
                  <TextFieldCopy isCopied={true} value={code} infoDescription={{type: "accessCode", label: ""}}/>
                </div>
              </>
            }

        </DialogContent>
        <DialogActions>
          {!resultReady && !resultReadyCode &&
            <div style={{marginTop: '32px', display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", p:0, gap:"100px",
              '@media (max-width: 991px)': {
                gap: '40px',
                flexWrap: 'wrap'
              }}}>
              <Button id="createAccessKey" variant="contained" onClick={() => createCode(email)} >{confirmBtnTextAdditional}</Button>
              <Button id="createMagicLink" variant="contained" onClick={() => createMagicLink(email)} className={authenticationSettingsDto !== null && ((authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED || authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED) && authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED)? "clientCertificateAndByodEnabled" : "accessKeyEnabled"} disabled= {authenticationSettingsDto !== null ? ((authenticationSettingsDto.CLIENT_CERTIFICATE_SD_ENABLED || authenticationSettingsDto.CLIENT_CERTIFICATE_MD_ENABLED) && authenticationSettingsDto.CLIENT_CERTIFICATE_BYOD_ALLOWED) : false}>{confirmBtnText}</Button>
            </div>
          }

          {
            (resultReady || resultReadyCode) &&
              <div style={{
                marginTop: resultReady || showCode ? '32px' : '0px',
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                p: 0,
                gap: "100px",
                '@media (max-width: 991px)': {
                  gap: '40px',
                  flexWrap: 'wrap'
                }
              }}>
                <InverseButton id="cancelButton" sx={{borderColor: "#FFFFFF"}} onClick={handleDialogClose}>Cancel</InverseButton>
                <Button id="doneButton" disabled={(!(resultReadyCode && showCode) && !resultReady)}
                        onClick={handleDialogClose}>{t('dialog.done')}</Button>
              </div>
          }

        </DialogActions>
        </Dialog>

      <CustomSnackbar open={showSnackErrorGrantOwnAccess} onCancel={handleSnackBarErrorClose} type={'error'}
                      vertical={'bottom'} horizontal={'right'} text={errorMessage} buttonText={t('notification.okay')}
                      showIcon='true' />
    </>
  );

}