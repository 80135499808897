import React, {useEffect} from 'react';
import {Button, Dialog, Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box/Box";
import DropDown from "./DropDown";
import TextInput from "./TextInput";
import GrantIPAccessComponent from "./GrantIPAccessComponent";
import {ACTION_TYPE, context} from "../State";
import {useTranslation} from "react-i18next";
import GraphApi from "../helpers/graph";
import {generate} from "../helpers/generate";
import {log} from "../helpers/utils";
import {ResponseType} from "@microsoft/microsoft-graph-client";
import DialogContent from "@mui/material/DialogContent";
import exitButton from "../img/close.svg";
import IconButton from "@mui/material/IconButton";
import {useMsal} from "@azure/msal-react";

function DialogHeader(props){
    const { onClose } = props;
    const { t } = useTranslation();
    return (
        <HeaderContainer>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding:0, width: "100%"}}>
                <HeaderTitle>
                    {t('create_azure_user.create_azure_user_heading')}
                </HeaderTitle>
                <IconButton onClick={onClose} sx={{height: '24px', width: '24px'}}>
                    <img src={exitButton} alt={"exit"}></img>
                </IconButton>
            </div>
        </HeaderContainer>
    );
}

const HeaderContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '16px',
    color: '#000',
    marginBottom: '32px'
});

const HeaderTitle = styled(Typography)({
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: 1,
    flex: 1,
    '@media (max-width: 991px)': {
        fontSize: '28px'
    }
});

function AzureForm (props) {

    const { showErrorMessageFirstName, showErrorMessageLastName, showErrorMessageUsername, showErrorMessageDisplayName, showErrorGraph, errorMessage, firstName, setFirstName, lastName, setLastName, displayName, setDisplayName, userName, setUsername, setDomain, graphApi, showGrantAccessButton, setShowGrantAccessButton, disableFields, setDisableFields} = props;

    const [dropdownText, setDropdownText] = React.useState('');
    const [domainList, setDomainList] = React.useState([]);
    const [adminEmail, setAdminEmail] = React.useState(null);
    const { state } = React.useContext(context);

    graphApi.init(state.graphApiAccessToken);

    useEffect( () => {
        if (!showGrantAccessButton) {
            setDisableFields(!state.graphApiAccessToken);
        }
        async function getDomains() {
            let domainListTmp = [];
            await graphApi.getManagedDomains(domainListTmp);
            if (domainListTmp.length > 0) {
                setDomainList(domainListTmp);
                setDropdownText(domainListTmp[0].id);
                setDomain(domainListTmp[0].id.toLowerCase().trim());
            } else {
                setDomainList([]);
            }
        }
        getDomains();
    }, [state.graphApiAccessToken]);

    useEffect(() => {
       if (disableFields) {
           setDropdownText('');
       }
    }, [disableFields]);

    let values = {
        dropdownText: dropdownText
    }

    let actions = {
        setDropdownText: setDropdownText,
        setDomain: setDomain
    }
    const handleFirstNameChange = (e) => {
        let newFirstName = e.target.value;

        if (lastName !== "") {
            setDisplayName(newFirstName + " " + lastName);
        }
        else {
            setDisplayName(newFirstName);
        }
        setFirstName(newFirstName);
    }


    const handleLastNameChange = (e) => {
        let newLastName = e.target.value;
        if (newLastName !== "") {
            setDisplayName(firstName + " " + newLastName);
        }
        else {
            setDisplayName(firstName);
        }
        setLastName(newLastName);
    }

    const handleDisplayNameChange = (e) => {
        setDisplayName(e.target.value);
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden"}}>
            <GrantIPAccessComponent showGrantAccessButton={showGrantAccessButton} setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail} setAdminEmail={setAdminEmail} setDisabled={false} isViewModeOn={false} fontWeight={600} messageWidth={544}/>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "24px", alignSelf: "stretch", marginTop: "32px"}}>
                <TextInput infoLabel={"First name"} disabled={disableFields} width={544} value={firstName} setError={showErrorMessageFirstName} errorText={showErrorMessageFirstName ? errorMessage : ''} onChange={(event) => handleFirstNameChange(event)}/>
                <TextInput infoLabel={"Last name"} disabled={disableFields} width={544} value={lastName} setError={showErrorMessageLastName} errorText={showErrorMessageLastName ? errorMessage : ''} onChange={(e) => handleLastNameChange(e)}/>
                <TextInput infoLabel={"Display name"} disabled={disableFields} width={544} value={displayName} setError={showErrorMessageDisplayName} errorText={showErrorMessageDisplayName ? errorMessage : ''} onChange={(e) => handleDisplayNameChange(e)}/>
                <div style={{alignItems: 'flex-start', alignSelf: 'stretch', display: 'flex', gap: '8px', width: '544px', overflow: 'hidden', justifyContent: 'flex-start'}}>
                    <TextInput infoLabel={"Username"} disabled={disableFields} width={268} value={userName} setError={showErrorMessageUsername} errorText={showErrorMessageUsername ? errorMessage : ''} onChange={(e) => handleUsernameChange(e)}/>
                    <DropDown disabled={disableFields} width={268} type={"Azure user"} items={domainList} label={"Select domain"} actions={actions} values={values} isEdit={false} isAdd={false} isAzure={true}/>
                </div>
            </Box>
            {showErrorGraph && <Typography variant='h4' sx={{color: "#FF5140", marginTop: "18px"}}>{errorMessage}</Typography>}
        </Box>
    );
}

const DialogActions = (props) => {
    const { setShow, createUser, onCancel } = props;
    return (
        <ActionsContainer>
            <ActionButton
                label="Cancel"
                variant="secondary"
                onClick={() => {
                    onCancel();
                    setShow(false);
                }}
                aria-label="Cancel changes"
            />
            <ActionButton
                label="Save"
                variant="primary"
                onClick={createUser}
                aria-label="Confirm and save changes"
            />
        </ActionsContainer>
    );
};

const ActionsContainer = styled(Box)({
    display: 'flex',
    marginTop: '24px',
    width: '100%',
    alignItems: 'center',
    gap: '40px',
    justifyContent: 'space-between',
    '@media (max-width: 991px)': {
        flexDirection: 'column-reverse',
        gap: '16px'
    }
});

const ActionButton = ({ label, variant, onClick, 'aria-label': ariaLabel }) => {
    return (
        <StyledButton
            variant={variant}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {label}
        </StyledButton>
    );
};

const StyledButton = styled(Button)(({variant }) => ({
    borderRadius: '24px',
    backgroundColor: variant === 'primary' ? '#293dc7' : '#fff',
    color: variant === 'primary' ? '#fff' : '#293dc7',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: 1,
    padding: '12px 24px',
    minWidth: variant === 'secondary' ? '100px' : '120px',
    textTransform: 'none',
    border: 'none',
    '&:hover': {
        backgroundColor: variant === 'primary' ? '#1f2e99' : '#f0f5ff',
    },
    '&:focus-visible': {
        outline: '0px solid #293dc7',
        outlineOffset: '0px'
    },
    '@media (max-width: 991px)': {
        width: '100%'
    }
}));


export default function CreateUserInMsEntraIdDialog(props) {

    const { show, setShow } = props;

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [displayName, setDisplayName] = React.useState("");
    const [userName, setUsername] = React.useState("");
    const [domain, setDomain] = React.useState("");
    const [showErrorMessageDisplayName, setShowErrorMessageDisplayName] = React.useState(false);
    const [showErrorMessageUsername, setShowErrorMessageUsername] = React.useState(false);
    const [showErrorMessageLastName, setShowErrorMessageLastName] = React.useState(false);
    const [showErrorMessageFirstName, setShowErrorMessageFirstName] = React.useState(false);
    const [showErrorGraph, setShowErrorGraph] = React.useState(false);
    const [showGrantAccessButton, setShowGrantAccessButton] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [disableFields, setDisableFields] = React.useState(true);

    const { dispatch } = React.useContext(context);
    const { t} = useTranslation();
    const graphApi = new GraphApi();

    const { instance, accounts } = useMsal();

    log(instance);
    log(accounts);

    const MINIMUM_DISPLAY_NAME_LENGTH = 1;
    const MAXIMUM_DISPLAY_NAME_LENGTH = 256;
    const MINIMUM_FIRST_NAME_LENGTH = 0;
    const MINIMUM_LAST_NAME_LENGTH = 0;
    const MAXIMUM_FIRST_NAME_LENGTH = 64;
    const MAXIMUM_LAST_NAME_LENGTH = 64;

    const validateEmailUsername = React.useMemo(() => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))$/;
        return (username) => re.test(username);
    }, []);

    function validateFields(){
        //trim all the fields
        setShowErrorMessageUsername(false);
        setShowErrorMessageDisplayName(false);
        setShowErrorMessageFirstName(false);
        setShowErrorMessageLastName(false);
        setShowErrorGraph(false);
        setErrorMessage("");

        //check first name length
        if (firstName!=="") {
            if (firstName.length < MINIMUM_FIRST_NAME_LENGTH || firstName.length > MAXIMUM_FIRST_NAME_LENGTH) {
                setShowErrorMessageFirstName(true);
                setErrorMessage("First name should be between " + MINIMUM_FIRST_NAME_LENGTH + " and "+ MAXIMUM_FIRST_NAME_LENGTH +" characters!");
                return false;
            }
        }

        //check last name length
        if (lastName!=="") {
            if (lastName.length < MINIMUM_LAST_NAME_LENGTH || lastName.length > MAXIMUM_LAST_NAME_LENGTH) {
                setShowErrorMessageLastName(true);
                setErrorMessage("Last name should be between " + MINIMUM_LAST_NAME_LENGTH + " and "+ MAXIMUM_LAST_NAME_LENGTH +" characters!");
                return false;
            }
        }

        //check if username is provided
        if (userName === "") {
            setShowErrorMessageUsername(true);
            setErrorMessage("Please provide username.");
            return false;
        }

        //validate username
        if (!validateEmailUsername(userName)){
            setShowErrorMessageUsername(true);
            setErrorMessage("Username is not in valid format");
            return false;
        }

        //check if displayName is provided
        if (displayName === ""){
            setShowErrorMessageDisplayName(true);
            setErrorMessage("Please provide a display name.");
            return false;
        }

        //check displayName length
        if (displayName.length < MINIMUM_DISPLAY_NAME_LENGTH || displayName.length > MAXIMUM_DISPLAY_NAME_LENGTH) {
            setShowErrorMessageDisplayName(true);
            setErrorMessage("Display name should be between " + MINIMUM_DISPLAY_NAME_LENGTH + " and " +MAXIMUM_DISPLAY_NAME_LENGTH + " characters in length!");
            return false;
        }

        return true;

    }

    function resetState() {
        setFirstName("");
        setLastName("");
        setDisplayName("");
        setUsername("");
    }

    function resetErrorsAndGrantAccessButton() {
        setErrorMessage("");
        setShowErrorGraph(false);
        setShowErrorMessageUsername(false);
        setShowErrorMessageLastName(false);
        setShowErrorMessageFirstName(false);
        setShowErrorMessageDisplayName(false);
        setDisableFields(true);
        setShowGrantAccessButton(true);
        setDomain("");
    }

    function createUser() {
        //here we should add the user, with the fields that he entered

        if (!validateFields()) return;

        let firstNameValue = firstName.trim();
        if (firstNameValue.length === 0) {
            firstNameValue = undefined;
        }

        let lastNameValue = lastName.trim();
        if (lastNameValue.length === 0) {
            lastNameValue = undefined;
        }

        let displayNameValue = displayName.trim();

        let userNameValue = userName.toLowerCase();

        let emailValue = userNameValue + '@' + domain.toLowerCase();


        const createUserRequest = {
            accountEnabled: true,
            displayName: displayNameValue,
            mailNickname: userNameValue,
            onPremisesImmutableId: emailValue,
            userPrincipalName: emailValue,
            mail: emailValue,
            givenName: firstNameValue,
            surname: lastNameValue,
            passwordProfile : {
                forceChangePasswordNextSignIn: true,
                password: generate({ // Generate dummy password
                    length: 12,
                    numbers: true,
                    symbols: true,
                    lowercase: true,
                    uppercase: true,
                    strict: true
                })
            }
        };

        log(createUserRequest);

        graphApi.graphClientInstance()
            .api('/users')
            .responseType(ResponseType.RAW)
            .post(createUserRequest)
            .then((response) => {
                log(response);

                if (response) {
                    if (!response.ok) {
                        response.json().then(body => {
                            log("Graph API response body");
                            log(body);

                            var errorText = "";
                            try {
                                errorText = body.error.message;
                            } catch(e) {}

                            // Azure returns "Property netId is invalid." on duplicate username
                            if (errorText.includes("netId")) {
                                errorText = t('dialog.azure_user_already_exists');
                            } else {
                                errorText = t('dialog.azure_error_message') + ' ' + errorText;
                            }

                            setErrorMessage(errorText);
                            setShowErrorGraph(true);
                        });
                    } else {

                        response.json().then(body => {
                            log("Graph API response body");
                            log(body);
                            // let link = "https://admin.microsoft.com/Adminportal/Home#/users/:/UserDetails/" + body.id // this link not working properly first time
                            let text = "https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/" + body.id
                            const link = <a href={text} target="_blank" > {t('create_azure_user.user_created2')} </a>
                            let component = <>{t('create_azure_user.user_created1')} {link} {t('create_azure_user.user_created3')} </>
                            //show green snackbar after successful creation of azure user with link of current user
                            dispatch({ type: ACTION_TYPE.SHOW_CREATE_AZURE_USER_SNACK, payload: { show: true, link: component } });
                        });

                    }
                } else {
                    // This should not happen normally
                    setErrorMessage("");
                    setShowErrorGraph(true);
                }
            })
            .catch((error) => {
                log(error);

                // This should not happen normally
                setErrorMessage("");
                setShowErrorGraph(true);
            });

        resetState();
    }

    function onClose() {
        setShow(false);
        resetState();
        resetErrorsAndGrantAccessButton();
    }

    return (
        <StyledDialog
            open={show}
            onClose={onClose}
        >
            <DialogHeader onClose={onClose} />
            <DialogContent sx={{padding: '0px'}}>
                <AzureForm
                    showErrorMessageFirstName={showErrorMessageFirstName}
                    showErrorMessageLastName={showErrorMessageLastName}
                    showErrorMessageUsername={showErrorMessageUsername}
                    showErrorMessageDisplayName={showErrorMessageDisplayName}
                    showErrorGraph={showErrorGraph}
                    showGrantAccessButton={showGrantAccessButton}
                    setShowGrantAccessButton={setShowGrantAccessButton}
                    errorMessage={errorMessage}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    userName={userName}
                    setUsername={setUsername}
                    setDomain={setDomain}
                    graphApi={graphApi}
                    disableFields={disableFields}
                    setDisableFields={setDisableFields}
                />
            </DialogContent>
            <DialogActions setShow={setShow} createUser={createUser} onCancel={() => {resetState(); resetErrorsAndGrantAccessButton();}} />
        </StyledDialog>
    );
};

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        padding: '55px',
        borderRadius: '16px',
        border: '1px solid #f0f5ff',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.04)',
        maxWidth: '656px',
        width: '100%',
        margin: '16px',
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    '& .MuiBackdrop-root': {
        backgroundColor: '#05084980'
    }
});
