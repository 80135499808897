import * as React from "react";
import { Typography, Box } from "@mui/material";
import TextInput from "./TextInput"
import {useTranslation} from "react-i18next";
import {serviceProvidersIcon} from "../helpers/utils";

export default function MainInfo(props) {
    const { integrationType, integrationName, integrationProtocol, isViewModeOn, integrationDetails, setIntegrationDetails } = props;
    const { t } = useTranslation();

    let spIcon = serviceProvidersIcon[integrationType];

    const changeIntegrationName = (event) => {
        setIntegrationDetails({...integrationDetails,
                                integrationName: event.target.value });
    }

    const FieldContainerStyle = {
        display: "flex",
        maxWidth: "568px",
        flexDirection: "column",
        color: "var(--Gray-7, #5f5f5f)",
        whiteSpace: "nowrap",
        letterSpacing: "0.14px",
        justifyContent: "flex-start",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: 1,
        fontFamily: "Source Sans Pro, sans-serif",
        "@media (max-width: 991px)": {
            whiteSpace: "initial",
        },
    };

    const ApplicationIconStyle = {
        aspectRatio: 1,
        objectFit: "contain",
        objectPosition: "center",
        width: "40px",
        borderRadius: "4px",
        marginTop: "4px",
    };

    return (
        <div style={{display: "flex", width: "100%", flexDirection: "column", fontFamily: "Source Sans Pro, sans-serif", justifyContent: "start", gap: "24px"}}>
            <div style={{ width: '100%' }}><TextInput infoLabel={t('integration_view.protocol')} value={integrationProtocol} disabled={true}></TextInput></div>
            <Box sx={FieldContainerStyle}>
                <Typography variant="h4" sx={{color: "var(--Gray-7, #5f5f5f)"}}>{t('integration_view.app')}</Typography>
                <img loading="lazy"
                     src={spIcon}
                     alt="Application icon"
                     tabIndex={0} style={ApplicationIconStyle}/>
            </Box>
            <div style={{ width: '100%' }}><TextInput value={isViewModeOn ? integrationName : null} infoLabel={t('integration_view.integration_name')} content={integrationName} disabled={isViewModeOn} placeholder={integrationName} onChange={changeIntegrationName}></TextInput>
            </div>
        </div>
    )
}