import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useImperativeHandle, forwardRef, useEffect} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Draggable, Droppable} from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {GroupActions, GroupStats} from "./GroupComponent";
import {CheckButton, CheckedHeaderButton, GreenButton, LicenseButton, RedButton} from "./CustomUI";
import TimeAgo from 'react-timeago'
import {AccordionSummary} from "@mui/material";
import {useTranslation} from "react-i18next";
import SelectItemList from "./SelectListMenu";
let today = new Date().getTime();
/**
 * Custom group that consist of a group header (Accordion component) and a group content (Table component).
 * Group header is expandable on a click. Group name, description, color and actions can be passed as props.
 * Group content is a table. Table headers and table rows can be passed as props (array).
 * Every row has an info icon on right side and when clicked, 'rowInfoClicked' callback is triggered.
 * If dragAndDrop prop is passed as true, rows can be moved inside the group or between groups. This requires a surrounding tag:
 * <DragDropContext onDragEnd={onDragEnd}>
 *     ...
 *     </Group>
 *     ...
 * </DragDropContext>
 * where 'onDragEnd' method needs to be implemented.
 * And every row needs to have an unique id in order for drag and drop to work.
 * @param props
 * @param ref
 * @returns {*}
 * @constructor
 */
const Group = (props, ref) => {
    const {isShared, groupName, groupContainerId, groupDescription, groupActions, groupColor, tableHeaders, tableRows, rowInfoClicked, dragAndDrop, id, setAddModeOn, setOpenDrawer, handleOpenViewDetailsIntegration, ownedGroups, setOwnedGroups, groups, setInviteUserIntegrationId, setInviteUserIntegrationDomain, setIntegrationToDelete, setIsSharedGroup} = props;

    const [isMoveActive, setIsMoveActive] = React.useState(false);
    const [oidcIntegrations, setOidcIntegrations] = React.useState([]);
    const [samlIntegrations, setSamlIntegrations] = React.useState([]);
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        getSelectedRow() {
            return ""
        }
    }));

    const onTableRowClick = (index, event, rowId, tableId) => {
        rowInfoClicked(index, event, rowId, tableId)
    };

    return (
        <TableHeaderAccordion name={groupName}
                              isShared={isShared}
                              description={groupDescription}
                              actionItems={groupActions}
                              color={groupColor}
                              tableRows={tableRows}
                              setIsAddModeOn={setAddModeOn}
                              setOpenDrawer={setOpenDrawer}
                              groupContainerId={groupContainerId}
                              isMoveActive={isMoveActive}
                              ownedGroups={ownedGroups}
                              setOwnedGroups={setOwnedGroups}
                              oidcIntegrations={oidcIntegrations}
                              samlIntegrations={samlIntegrations}
                              content={<CustomTable isShared={isShared} setIsSharedGroup={setIsSharedGroup} oidcIntegrations={oidcIntegrations} setOidcIntegrations={setOidcIntegrations} samlIntegrations={samlIntegrations} setSamlIntegrations={setSamlIntegrations} setIsMoveActive={setIsMoveActive} handleOpenViewDetailsIntegration={handleOpenViewDetailsIntegration} tableHeaders={tableHeaders} tableRows={tableRows} dragAndDrop={dragAndDrop} onTableRowClick={onTableRowClick} id={id} groups={groups} setInviteUserIntegrationId={setInviteUserIntegrationId} setInviteUserIntegrationDomain={setInviteUserIntegrationDomain} setIntegrationToDelete={setIntegrationToDelete} groupName={groupName}/>}/>
    );
};

function TableHeaderAccordion(props) {
    const {isShared, name, description, groupContainerId, content, tableRows, setIsAddModeOn, setOpenDrawer, isMoveActive, ownedGroups, setOwnedGroups, samlIntegrations, oidcIntegrations} = props;
    const [expanded, setExpanded] = React.useState(true);

    const { t } = useTranslation();

    const handleChange = () => {
        setExpanded(!expanded)
    };

    return (
        <Accordion className='accordion' variant='outlined' expanded={expanded} defaultExpanded={true} style={{boxShadow:'none', border: '1px solid var(--Gray-3, #D7D7D7)', borderRadius: '16px', width: 'inherit', marginBottom: '16px'}}>
            <AccordionSummary
                expandIcon={<ExpandLessIcon onClick={handleChange} sx={{width: '24px', height: '24px', color: '#293dc7'}} />}
                className="accordionSummary"
                role="container"
                sx={{ width: 'inherit', borderBottom: expanded ? '1px solid var(--Gray-3, #D7D7D7)' : '0px', backgroundColor: "#FFFFFF", borderTop: '0px',
                    '&.MuiButtonBase-root': {borderTopRightRadius: '16px', borderTopLeftRadius: '16px', borderBottomRightRadius: expanded ? '0px' : '16px', borderBottomLeftRadius: expanded ? '0px' : '16px'}}}
            >
                <GroupStats isShared={isShared} groupContainerId={groupContainerId} owner={description} integrationsNumber={tableRows.length} firstGroupName={name} changeGroupName={null} t={t} />

                { description === "Me" && <GroupActions isMoveActive={isMoveActive} groupContainerId={groupContainerId} setIsAddModeOn={setIsAddModeOn} t={t} setOpenDrawer={setOpenDrawer}
                               owner={description} ownedGroups={ownedGroups} setOwnedGroups={setOwnedGroups} samlIntegrations={samlIntegrations} oidcIntegrations={oidcIntegrations} groupName={name} />}

            </AccordionSummary>
            <AccordionDetails sx={{display: expanded ? 'flex' : 'none', padding: '0px', width: 'inherit'}}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={`${id}`} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    <TableCell align="left" style={{width: '40px', paddingRight: '0px', paddingTop: '27px'}} {...provided.dragHandleProps}>
                        <DragIndicatorIcon sx={{color: '#BDBDBD'}}/>
                    </TableCell>
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
};

const DroppableComponent = (index) => (props) =>
{
    return (
            <Droppable droppableId={`${index}`} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>

    )
};

const CustomTable = (props) => {

    const {tableRows, onTableRowClick, dragAndDrop, id, handleOpenViewDetailsIntegration, setIsMoveActive, oidcIntegrations, setOidcIntegrations, samlIntegrations, setSamlIntegrations, isShared, groups, setInviteUserIntegrationId, setInviteUserIntegrationDomain, setIntegrationToDelete, setIsSharedGroup, groupName} = props;

    const [checkedHeader, setCheckedHeader] = React.useState(false);
    const [checkedIntegrations, setCheckedIntegrations] = React.useState({});

    useEffect(() => {
        let initialCheckedIntegrations = {};
        tableRows.map(row => initialCheckedIntegrations[row.name] = null);
        setCheckedIntegrations(initialCheckedIntegrations);
    }, []);

    useEffect(() => {
        handleCheckedHeaderAndMoveActive();
    }, [checkedIntegrations]);

    const handleCheckedHeaderAndMoveActive = () => {
        setCheckedHeader(Object.values(checkedIntegrations).reduce((t, value) => t || value, false));
        setIsMoveActive(Object.values(checkedIntegrations).reduce((t, value) => t || value, false));
    }

    const handleRowCheckedChange = (rowName, integrationType, integrationId) => {
        setCheckedIntegrations({
            ...checkedIntegrations,
            [rowName]: !checkedIntegrations[rowName]
        });

        if (!checkedIntegrations[rowName]) {
            if (integrationType === 'OIDC') {
                setOidcIntegrations([...oidcIntegrations, integrationId]);
            } else {
                setSamlIntegrations([...samlIntegrations, integrationId]);
            }
        } else {
            if (integrationType === 'OIDC') {
                let filteredOidcIntegration = oidcIntegrations.filter(id => id !== integrationId);
                setOidcIntegrations(filteredOidcIntegration);
            } else {
                let filteredSamlIntegration = samlIntegrations.filter(id => id !== integrationId);
                setSamlIntegrations(filteredSamlIntegration);
            }
        }
    }

    const handleReset = () => {
        setCheckedHeader(false);
        setIsMoveActive(false);
        let initialCheckedIntegrations = {};
        tableRows.map(row => initialCheckedIntegrations[row.name] = null);
        setCheckedIntegrations(initialCheckedIntegrations);
    }
    const handleRowClick = (index, event, rowId, tableId, rowExpirationDate, rowLicenceType)  => {
        let expired = rowExpirationDate - today <= 0;
        let canClick = !(expired && (rowLicenceType == 'Licensed'))
        if (canClick) {
            onTableRowClick(index, event, rowId, tableId, rowExpirationDate);
            setIsSharedGroup(isShared);
            handleOpenViewDetailsIntegration();
        }
    }

    const displayLicenseLimit = (row) => {
        let licenseLimit = (row.licenseLimit !== undefined && row.licenseLimit !== null) ? (row.licenseLimit < 1000000 ? row.licenseLimit : '∞') : '';
        return (row.licenseLimit === null || row.licenseTotal === null || row.licenseLimit === undefined || row.licenseTotal === undefined) ? "-" : row.licenseTotal + " / " + licenseLimit;
    }

    function calculateDaysLeft(expirationDate) {
        let today = new Date().getTime();
        let expirationDayinMilliseconds = expirationDate;
        let milliseconds = expirationDayinMilliseconds - today;
        let millsecInDay = 86400000;
        return Math.round(milliseconds/millsecInDay)
      }

      function checkIfTrialExpired(expirationDate) {
        let today = new Date().getTime();
        return expirationDate - today <= 0
      }

    return (
        <TableContainer sx={{ width: '100%'}}>
            <Table sx={{ width: 'inherit', overflow: "hidden" }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{borderColor: '#EEEEEE'}}>
                        {dragAndDrop && <TableCell/>}
                        <TableCell align="start"  sx={{width: '80px', paddingLeft: 0}} key={'select'}> { checkedHeader && !isShared  && <CheckedHeaderButton isChecked={checkedHeader} onClick={(e) => {e.stopPropagation(); handleReset()}}/>} </TableCell>
                        <TableCell align="start"  sx={{width: '80px'}} key={'logo'}></TableCell>
                        <TableCell align="start"  sx={{width: '276px'}} key={"Name"}><Typography variant="h4">{"Name"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '276px'}} key={"Domains"}><Typography variant="h4">{"Domains"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '80px'}} key={"License ID"}><Typography variant="h4">{"License ID"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '130px'}} key={"Licensed Users"}><Typography variant="h4">{"Licensed Users"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '130px'}} key={"Enrolled Users"}><Typography variant="h4">{"Enrolled Users"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '130px'}} key={"SCIM on/off"}><Typography variant="h4">{"SCIM on/off"}</Typography></TableCell>
                        <TableCell align="start"  sx={{width: '130px'}} key={"SCIM status"}><Typography variant="h4">{"SCIM status"}</Typography></TableCell>
                        <TableCell align="start" sx={{ width: '40px'}} key={"Three dots"} ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody id={groupName === "My group" ? "MyGroup" : groupName} component={ dragAndDrop ? DroppableComponent(id) : ''}>
                    {tableRows.map((row, index) => (
                        <TableRow
                            component={dragAndDrop ? DraggableComponent(row.id, index) : ''}
                            key={`${index} ${row.id}`}
                            sx={{position: 'relative', '&:last-child td, &:last-child th': {border: 0}, opacity: ((row.expirationDate - today <= 0) && (row.licenseType === "Licensed")) ? 0.3 : 1, borderColor: '#EEEEEE','&:hover': {backgroundColor: '#F0F5FF'} }}
                            onClick={(e) => {handleRowClick(index, e, row.id, id, row.expirationDate, row.licenseType);}}
                        >
                            <TableCell align="center" style={{width: '80px', paddingLeft: 0}}> {!isShared && <CheckButton isChecked={checkedIntegrations[row.name]} onClick={(e) => {e.stopPropagation(); handleRowCheckedChange(row.name, row.integrationType, row.id);}} inputProps={{ 'aria-label': 'controlled' }}/>} </TableCell>
                            <TableCell align="center" style={{width: '80px'}}> {<img alt={"integration icon"} src={row.imagePath} style={{height: '40px', width: '40px'}}/>} </TableCell>
                            <TableCell align="start" style={{width: '276px'}}> <Typography className='integrationName' variant="h4_column"> {row.name} </Typography> </TableCell>
                            <TableCell align="start" style={{width: '276px'}}> <Typography className='integrationDomain' variant="h4_column"> {row.domain ?? '-'} </Typography> </TableCell>
                            <TableCell align="start" style={{width: '200px'}}> {row.licenseType ? (row.licenseType === "Licensed" ? <Typography className='licenseButton' variant="h4_column">{row.contractId}</Typography> : <LicenseButton sx={{minWidth: 160}} className='licenseButton'>{checkIfTrialExpired(row.expirationDate)? "Trial expired" : "Trial expires in " + calculateDaysLeft(row.expirationDate) + " days"}</LicenseButton>)  : "-"} </TableCell>
                            <TableCell align="start" style={{width: '100px'}}> <Typography className='licensedUsers' variant="h4_column"> {displayLicenseLimit(row)} </Typography> </TableCell>
                            <TableCell align="start" style={{width: '100px'}}> <Typography className='enrolledUsers' variant="h4_column"> {((row.scimEnabled && row.numberOfUsers != null) ? row.numberOfUsers : (row.licenseTotal !== null ? row.licenseTotal : '-')) + ( " / " + (row.enrolledUsers !== null ? row.enrolledUsers : "-"))} </Typography> </TableCell>
                            <TableCell align="start" style={{width: '80px'}}> {row.scim ? <GreenButton className='scimOn'>on</GreenButton> : <RedButton className='scimOff'>off</RedButton>} </TableCell>
                            <TableCell align="start" style={{width: '80px'}}> <Typography className='scimStatus' variant="h4_column"> {row.scimEnabled ?  <TimeAgo date={row.lastSync}/> : "-"} </Typography> </TableCell>
                            <TableCell align="start" style={{width: '40px'}}>
                                <SelectItemList index={index} rowId={row.id} tableId={id} rowLicenseType={row.licenseType} rowExpirationDate={row.expirationDate} groups={groups} setInviteUserIntegrationId={setInviteUserIntegrationId} setInviteUserIntegrationDomain={setInviteUserIntegrationDomain} setIntegrationToDelete={setIntegrationToDelete}/>
                            </TableCell>
                        </TableRow>

                        ))}
                    </TableBody>
                </Table>
        </TableContainer>
    );
};

export default forwardRef(Group);

