import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import {useTranslation} from "react-i18next";

export function ToggleSwitch(props) {
    const {isChecked, setIsChecked, setCreateContract, setSelectedContract, setCheckedToggle, assignedContract, sectionTitle} = props;

    const {t} = useTranslation();

    const handleToggle = () => {
        setIsChecked(!isChecked);
        setCheckedToggle(!isChecked);
        setCreateContract(false);
        if (isChecked && sectionTitle === t('add_integration.contract_heading')) {
            setSelectedContract(null);
        }
        if (!isChecked && sectionTitle === t('add_integration.contract_heading')) {
            setSelectedContract(assignedContract);
        }
    };

    return (
        <SwitchContainer>
            <StyledButton 
                className="toggleButton"
                onClick={handleToggle}
                role="switch"
                $isChecked={isChecked}
            >
                <SwitchIndicator $isChecked={isChecked} />
            </StyledButton>
        </SwitchContainer>
    );
}

const SwitchContainer = styled(Box)({
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "32px",
    padding: "4px 2px",
});

const StyledButton = styled(Button)(({ $isChecked }) => ({
    minWidth: "unset",
    borderRadius: "16px",
    background: $isChecked
        ? "var(--Blue---primary-Blue---source, #293dc7)"
        : "#e0e0e0",
    display: "flex",
    flexDirection: "column",
    alignItems: $isChecked ? "flex-end" : "flex-start",
    justifyContent: "center",
    padding: "2px 3px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
        background: $isChecked
            ? "var(--Blue---primary-Blue---source, #293dc7)"
            : "#e0e0e0",
    },
}));

const SwitchIndicator = styled(Box)({
    backgroundColor: "var(--White, #fff)",
    borderRadius: "50%",
    display: "flex",
    width: "12px",
    height: "12px",
    fill: "var(--White, #fff)",
    transition: "transform 0.2s ease-in-out",
});