import { bold } from "kleur";

const typography = {
    fontFamily: [
        'Source Sans Pro'
    ].join(','),

    h1: {
        color: "var(--Black, #000)",
        fontFamily: "Source Sans Pro",
        fontSize: "36px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "48px",
        letterSpacing: "0.36px"
    },
    h2: {
        color: 'var(--Black, #000)',
        fontFamily: "Source Sans Pro",
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.3px'
    },
    h3: {
        fontSize: '1.6vw',
        fontWeight: 400,
        '@media (max-width:480px)': {
            fontSize: '1.4vw',
        },
    },
    h4: {
        color: 'var(--Black, #000)',
        fontFamily: "Source Sans Pro",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.14px'
    },

    h4_column: {
        color: 'var(--Black, #000)',
        fontFamily: "Source Sans Pro",
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.09px'
    },

    h4_bold: {
        fontSize: 13,
        fontWeight: 700,
        '@media (max-width: 480px)': {
            fontSize: 12
        },
    },
    h5: {
        fontSize: 9,
    },
    h6: {
        fontFamily: "Source Sans Pro",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.3px"
    },

    title1: {
        fontWeight: bold,
        fontSize: 25
    }, 
    subtitle1: { // used mostly for dialog title
        fontSize: 25,
        fontWeight: 700,
        lineHeight: 1.4,
        '@media (max-width:480px)': {
            fontSize: 17,
        },
    },
    subtitle2: { // used mostly for dialog subtitle
        fontSize: 16,
        '@media (max-width:480px)': {
            fontSize: 14,
        },
    },
    button: {
        fontSize: 14,
        fontWeight: 400,
        textTransform: "capitalize",
        textAlign: "center",
        textDecoration: "none",
        '@media (max-width:480px)': {
            fontSize: 12,
        },
    },
    button2: {
        textAlign: "left",
        font: "normal normal 600 1vw/3vw Source Sans Pro",
        letterSpacing: "0px",
        color: "black",
        opacity: 1
    },
    body2: {
        fontSize: '1.2vw',
    },
    sectionHeading: {
        textAlign: "left",
        font: "normal normal bold 26px/76px Source Sans Pro",
        letterSpacing: "0px",
        color: "#000000",
        opacity: 1,
        lineHeight: '34px'
    },
    sectionHeading2: {
        textAlign: "left",
        font: "normal normal bold 26px/50px Source Sans Pro",
        letterSpacing: "0px",
        color: "#000000",
        opacity: 1
    },
    integrationAddHeading: {
        textAlign: "left",
        font: "normal normal bold 2.2vw/3vw Source Sans Pro",
        letterSpacing: "0px",
        color: "#000000",
        opacity: 1
    },
    createAzureUserHeading: {
        textAlign: "left",
        font: "normal normal bold 2.2vw/3vw Source Sans Pro",
        letterSpacing: "0px",
        color: "#000000",
        opacity: 1
    },

    createAzureUserSubheading: {
        textAlign: "left",
        font: "normal normal 1.5vw/2vw Source Sans Pro",
        letterSpacing: "0px",
        color: "#000000",
        opacity: 1
    },

    textFieldCopyHeading: {
        textAlign: "left",
        font: "normal normal 600 16px/24px Source Sans Pro",
        letterSpacing: "0.15px",
        color: "#000000",
        opacity: 1
    },
    headingTextView: {
        textAlign: 'left',
        font: 'normal normal bold 14px/18px Source Sans Pro',
        letterSpacing: '0px',
        color: '#152035'
    },
    headingTextViewSection: {
        textAlign: 'left',
        font: 'normal normal bold 26px/76px Source Sans Pro',
        letterSpacing: '0px',
        color: '#000000',
        opacity: 1,
        lineHeight: '30px'
    },
    labelTextView: {
        textAlign: 'left',
        font: 'normal normal normal 14px/24px Source Sans Pro',
        letterSpacing: '0.13px',
        color: '#000000ED',
        opacity: 1
    },
    label: {
        fontFamily: "Source Sans Pro",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.14px"
    }
};

export function getTheme() {

    return {
        typography: typography,
        palette: {
            background: {
                default: "#FFFFFF"
            },
            warning: {
                main: "#FFBCBC"
            },
            primary: {
                main: "#293dc7",
                contrastText: "#FFFFFF"
            },
            secondary: {
                main: "#293dc7",
                contrastText: "#FFFFFF"
            },
            text: {
                primary: "#000",
                secondary: "#000"
            },
            onHover: {
                main: "#0f2094",
                inverse: "#FAFAFA"
            },
            common: {
                black: "000000"
            },
        },
    }
}