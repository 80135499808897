import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {InverseButton} from "./CustomUI";
import {styled} from "@mui/styles";
import Box from "@mui/material/Box/Box";
import FileUploadIconSrc from "../img/upload.svg"
import FileUploadIconRedSrc from "../img/upload_red.svg"
import {InputAdornment, InputLabel, TextField} from "@mui/material";
import deleteIcon from "../img/delete.svg";
import DrawerMessage from "./DrawerMessage";
import {log} from "../helpers/utils";

export default function FileUploader(props) {

    const { integrationType, integrationDetails, setIntegrationDetails, fileInput, errorMsg, setErrorMsg } = props;
    const { t } = useTranslation();

    const [focusUploadButton, setFocusUploadButton] = React.useState(false);

    useEffect(() => {
        if (errorMsg !== null) {
            setFocusUploadButton(true);
        }
    }, [errorMsg]);

    const handleButtonClick = () => {
        setErrorMsg(null);
        fileInput.current.click();
        setFocusUploadButton(false);
    }

    const handleFileUpload = (event) => {
        if (event.target.files[0] === undefined) {
            log("Undefined uploaded file");
            return;
        }
        setIntegrationDetails({
            ...integrationDetails,
            metadataFile: event.target.files[0],
            metadataUrl: null
        });
    }

    const handleMetadataUrlInput = (event) => {
        setIntegrationDetails({
            ...integrationDetails,
            metadataUrl: event.target.value
        });
    }

    const deleteMetadata = () => {
        setIntegrationDetails({
            ...integrationDetails,
            metadataFile: null
        });
        fileInput.current.value = "";
        setErrorMsg(null);
    }
    function FileStatus() {
        return (
            <StatusContainer>
                {integrationDetails.metadataFile && (
                <FileInfo>
                    <StyledMetadataInfoField
                        disabled={true}
                        InputProps={{
                                endAdornment:   <InputAdornment position="end">
                                                    <FileIcon component="img"
                                                              onClick={deleteMetadata}
                                                              src={deleteIcon}
                                                              style={{ transform: 'translateY(-12px)'}} />
                                                </InputAdornment>,
                                readOnly: false
                        }}
                        fullWidth
                        variant="outlined"
                        value={integrationDetails.metadataFile.name}
                    />
                </FileInfo>
                )}
                { errorMsg === null && integrationDetails.metadataFile && <DrawerMessage text={"File uploaded"} />}
                { errorMsg !== null && <DrawerMessage text={errorMsg} isError={errorMsg} />}
            </StatusContainer>
        );
    }

    return (
        <Container>
            <UploadSection>
                {integrationType === "CUSTOM" &&
                    <>
                        <FieldWrapper>
                            <StyledLabel htmlFor="metadataUrl">{"Metadata URL"}</StyledLabel>
                            <StyledTextField
                                id="metadataUrl"
                                placeholder={"Enter here"}
                                onChange={handleMetadataUrlInput}
                                disabled={integrationDetails.metadataFile}
                                value={integrationDetails.metadataUrl}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                    "aria-label": "label",
                                }}
                            />
                        </FieldWrapper>
                        <Separator>or</Separator>
                    </>
                }
                {errorMsg !== "Metadata file is missing" &&
                    <InverseButton id="uploadMetadataButton" onClick={handleButtonClick} disabled={integrationDetails.metadataFile} sx={{gap: '4px'}} autoFocus={focusUploadButton}>
                        <img src={FileUploadIconSrc} alt="File upload" style={{width: '20px', height: '20px'}}/>
                        {t('add_integration.upload_metadata_button')}
                    </InverseButton>}
                {errorMsg === "Metadata file is missing" &&
                    <InverseButton id="uploadMetadataButton" onClick={handleButtonClick} disabled={integrationDetails.metadataFile} sx={{gap: '4px'}} autoFocus={focusUploadButton} style={{borderColor: "#FF5140", color:"#FF5140"}}>
                        <img src={FileUploadIconRedSrc} alt="File upload" style={{width: '20px', height: '20px'}}/>
                        {t('add_integration.upload_metadata_button')}
                    </InverseButton>}
                <input type="file" id="uploadFileInput" ref={fileInput} onChange={handleFileUpload}
                       style={{display: "none"}}/>
            </UploadSection>
            <FileStatus/>
        </Container>
    )
}

const Container = styled(Box)({
    display: "flex",
    maxWidth: "592px",
    flexDirection: "column",
    fontFamily: "Source Sans Pro, sans-serif",
});

const UploadSection = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    gap: "8px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const Separator = styled(Box)({
    color: "var(--Black, #000)",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.09px",
    width: "17px",
});

const StatusContainer = styled(Box)({
    display: "flex",
    gap: "12px",
    marginTop: "24px",
    width: "100%",
    flexDirection: "column",
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: "0.09px",
    lineHeight: 1,
    justifyContent: "flex-start",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
    },
});

const FileInfo = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    color: "var(--Gray-9, #202020)",
    whiteSpace: "nowrap",
    justifyContent: "flex-start",
    "@media (max-width: 991px)": {
        maxWidth: "100%",
        whiteSpace: "initial",
    },
});

const StyledMetadataInfoField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        backgroundColor: "var(--Blue---primary-10, #f0f5ff)",
        borderColor: "var(--Blue---primary-10, #f0f5ff)",
        borderRadius: "12px",
        "& fieldset": {
            borderWidth: "0px",
            borderColor: "var(--Blue---primary-10, #f0f5ff)",
        },
        "&:hover fieldset": {
            borderWidth: "0px",
            borderColor: "var(--Blue---primary-10, #f0f5ff)",
        },
        "& input": {
            padding: "12px",
            fontSize: "18px",
            fontWeight: 400,
            letterSpacing: "0.09px",
            lineHeight: 1,
        },
    },
});

const FileIcon = styled(Box)({
    aspectRatio: "1",
    objectFit: "contain",
    objectPosition: "center",
    width: "24px",
    alignSelf: "stretch",
    margin: "auto 0",
});

const FieldWrapper = styled(Box)({
    display: "flex",
    minWidth: "240px",
    flexDirection: "column",
    color: "var(--Gray-4, #bdbdbd)",
    justifyContent: "flex-start",
    flex: 1,
    flexBasis: "32px",
});

const StyledLabel = styled(InputLabel)({
    width: "100%",
    gap: "4px",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.14px",
    lineHeight: 1,
    marginBottom: "4px",
    color: 'var(--Gray-4, #BDBDBD)'
});

const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        backgroundColor: "var(--Gray-2, #eeeeee)",
        borderRadius: 12,
        "& fieldset": {
            borderWidth: "0px",
            border: "1px solid var(--Gray-2, #EEEEEE)",
        },
        "&:hover fieldset": {
            borderWidth: "0px",
            border: "1px solid var(--Gray-2, #EEEEEE)",
        },
        "&.Mui-focused fieldset": {
            border: "1px solid var(--Gray-2, #EEEEEE)",
        },
    },
    "& .MuiOutlinedInput-input": {
        fontSize: "18px",
        fontWeight: 400,
        letterSpacing: "0.09px",
        lineHeight: 1,
        padding: "12px",
    },
});
