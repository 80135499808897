import {availableServiceProviders, getTextFieldValue, idPCertificateName, idPMetadataName} from "../helpers/utils";
import React from "react";
import TextFieldCopy from "./TextFieldCopy";
import DownloadComponent from "./DownloadComponent";
import {configManager} from "../App";

export default function IdentityProviderParametersSection(props) {
    const {integrationData, integrationDetails, setCredentials, isAddModeThirdStepOn, isEdit} = props;

    const [isLoadedCredentials, setIsLoadedCredentials] = React.useState(false);

    let integrationType = integrationData.integrationType ? integrationData.integrationType : integrationDetails.integrationType;
    let sp = availableServiceProviders.filter(serviceProvider => serviceProvider.integrationType === integrationType);

    //make json for IdP parameters
    let paramJSON = [];
    let isMetadataUrl = false;

    if (sp !== undefined && sp[0] !== undefined) {
        sp[0].idPParameters.map(param => {
            let labelKey = param['label'];
            let paramType = param['type'];
            let labelValue = '';
            let clientId = integrationData && integrationData.clientId ? integrationData.clientId : (integrationDetails.credentials ? integrationDetails.credentials.clientId : null);
            let serverName = configManager.serverName ? configManager.serverName : null;

            if (paramType === "scimendpointurl" || paramType === "scimsecrettoken") {
                return null;
            }
            if ((paramType === "clientsecret" || paramType === "clientid") && !isAddModeThirdStepOn) {
                return null;
            }
            if (paramType === "tvendpointsso") {
                labelValue = configManager.shibSsoRedirectEndpoint.replace("/profile/SAML2/Redirect/SSO", "/idee/" + clientId + "/SSO/Redirect")
            } else if (paramType === "tvendpointslo") {
                labelValue = configManager.shibSloRedirectEndpoint.replace("/profile/SAML2/Redirect/SLO", "/idee/" + clientId + "/SLO/Redirect")
            } else if (paramType === "metadataurl_wsfed") {
                labelValue = "https://" + serverName +"/wsfed/idp/metadata"
            } else if (paramType === "metadataurl") {
                labelValue = "https://" + serverName + "/idp/metadata"
            } else if (paramType === "metadataurl_modified") {
                labelValue = "";
                isMetadataUrl = true;
            } else if (paramType === "clientid") {
                labelValue = integrationDetails.credentials ? integrationDetails.credentials.clientId : ""
            } else if (paramType === "clientsecret") {
                labelValue = integrationDetails.credentials ? integrationDetails.credentials.clientSecret : ""
            } else {
                labelValue = getTextFieldValue(param['type']);
            }

            paramJSON.push({
                [labelKey]: [labelValue],
                'type': [paramType]
            });
            return null;
        })
    }

    return <div>
        {paramJSON.map((param) => {
            let type = param["type"];
            let labelKey = Object.keys(param).filter(key => key !== "type")[0];
            let infoDescription = {
                "type": type !== undefined ? type[0] : "",
                "label": labelKey
            }

            let isCertificate = (type !== undefined && type[0] === 'certificate');

            if (isMetadataUrl) {
                return <DownloadComponent
                    infoDescription={'Metadata URL'}
                    filePath="/app/downloadIdp"
                    fileName={idPMetadataName}
                    isLinkComponent={true} />
            } else {
                return !isCertificate ?
                    <TextFieldCopy infoDescription={infoDescription} isOidc={integrationType} credentials={integrationDetails.credentials} integrationDetails={integrationDetails}
                                   isEditable={false} value={param[labelKey]} isCopied={true} credentialsSetter={setCredentials} isAddModeThirdStepOn={isAddModeThirdStepOn} isLoadedCredentials={isLoadedCredentials} setIsLoadedCredentials={setIsLoadedCredentials}/>
                    :
                    <DownloadComponent
                        infoDescription={labelKey.toLowerCase}
                        filePath="/app/download"
                        fileName={idPCertificateName}
                        isLinkComponent={!isEdit}/>
            }
        })
        }
    </div>;

}