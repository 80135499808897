import React from 'react';
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import {configManager} from "../App";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container/Container";

export default function BasicLayout(props) {
    const { content, hasFooter, isSetup } = props;
    return (
        <Container
            maxWidth={false} disableGutters
            sx={{minHeight: '100vh', backgroundColor: isSetup === true ? '#F7F7F7' : "#FFFFF", display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: 'center',
                padding: '40px',}}
        >

            <AppBar />
            <Box style={{zIndex: 1200, alignContent: 'flex-start'}}>
                {content}
            </Box>
            <Box>
                {hasFooter &&
                    <Footer iosLink={configManager.iosLink} androidLink={configManager.androidLink}/>
                }
            </Box>
        </Container>
    );
}