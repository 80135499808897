import React from "react";
import {Button, InverseButton} from './CustomUI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {styled, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DropDown from "./DropDown";
import AddIcon from "@mui/icons-material/Add";
import {useTranslation} from "react-i18next";
import Api from "../helpers/service";
import {log} from "../helpers/utils";
import CustomDialog from "./DialogText";


export default function DeleteGroupDialog (props) {
    const {groups, emptyGroup, setEmptyGroup, openDeleteGroupDialog, setOpenDeleteGroupDialog, currentGroupToDelete, setCurrentGroupToDelete, currentGroupToDeleteName, setCurrentGroupToDeleteName} = props;

    const [groupId, setGroupId] = React.useState(null);
    const [dropdownText, setDropdownText] = React.useState(null);
    const [openCreateNewGroupDialog, setOpenCreateNewGroupDialog] = React.useState(false);
    const [newGroupName, setNewGroupName] = React.useState('');

    const { t } = useTranslation();
    const api = new Api();

    const handleDialogClose = () => {
        setOpenDeleteGroupDialog(false);
        setCurrentGroupToDelete('');
        setCurrentGroupToDeleteName('');
        setGroupId(null);
    };

    const moveIntegrationsAndDeleteGroup = (destinationContainerId) => {
       let group = groups.filter(group => group.containerId === currentGroupToDelete)[0];
       let oidcIntegrations = (group.integrations.filter(integration => integration.integrationType === "OIDC")).map(integration => integration.id);
       let samlIntegrations = (group.integrations.filter(integration => integration.integrationType === "SAML")).map(integration => integration.id);

       if (destinationContainerId) {
           try{
               api.moveIntegrations(destinationContainerId, oidcIntegrations, samlIntegrations).then(() => {
                   api.deleteGroup(currentGroupToDelete).then(() => window.location.reload()).catch((err) => log(err));
                   setEmptyGroup(false);
               });
           }catch(error) {
               log(error);
               setEmptyGroup(false);
           }

        }
    }

    const deleteEmptyGroup = () => {
            try {
                api.deleteGroup(currentGroupToDelete).then(() => {
                    setEmptyGroup(false);
                    window.location.reload();
                });
            } catch(error) {
                log(error);
                setEmptyGroup(false);
            }
        }


    const handleCreateNewGroupBtn = () => {
        setOpenCreateNewGroupDialog(true);
    }

    const handleDialogCreateNewGroup = () => {
        api.addNewContainer(newGroupName).then(function (res) {
            log(res.data);
            setGroupId(res.data.containerId);
            setDropdownText(res.data.name);
        }).catch(function (error) {
            log(error);
        })

    }

    const onConfirm = () => {
        if(!emptyGroup) {
            if(groupId) {
                moveIntegrationsAndDeleteGroup(groupId);
            }
        }
        else {
            deleteEmptyGroup();
        }
    }

    const StyledTypography = styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: "32px",
        lineHeight: 1,
        fontFamily: "Source Sans Pro, sans-serif",
    }));

    return (
        <div>
            <Dialog
                open={openDeleteGroupDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{style : {
                        borderRadius: "16px",
                        border: "1px solid #f0f5ff",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                        maxWidth: "896px",
                        padding: "56px",

                    }}}
                sx={{'& .MuiBackdrop-root': {
                        backgroundColor: '#05084980'
                    }}}
            >
                <DialogTitle  id="alert-dialog-title"
                              sx={{display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  p:0,
                                  marginBottom: "16px"
                              }}>
                    <StyledTypography > Delete group </StyledTypography>

                    <IconButton onClick={handleDialogClose} aria-label="close"
                                size="large"
                                sx={{borderRadius: "24px",
                                    width: "48px",
                                    height: "48px",
                                    padding: "12px",}}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{p:0, marginTop: '32px', overflow: 'hidden'}}>
                    {!emptyGroup && <>
                        <Typography sx={{fontWeight: 400, fontSize: "18px", lineHeight: "24px", letterSpacing: "0.09px", fontFamily: "Source Sans Pro"}}>
                            {t('group_ownership.delete_group_subtitle', {group_name: currentGroupToDeleteName})}
                        </Typography>

                        <Box style={{display: "flex", alignItems: "center", gap: "16px", alignSelf: "stretch", marginTop: '32px', height: '100px'}}>
                            <DropDown width={450} actions={{setGroupId: setGroupId, setDropdownText: setDropdownText}} values={{dropdownText: dropdownText}} type="Group" disabled={false} items={groups.filter(group => group.containerId !== currentGroupToDelete)} label="Select existing group to move integrations" />
                            <Typography variant="h4_column" sx={{marginTop: '15px'}}>or</Typography>
                            <InverseButton id="createNewGroupButton" sx={{marginTop: '15px', '&:hover': {transform: 'none'}}} onClick={handleCreateNewGroupBtn}><AddIcon/>Create new group</InverseButton>
                        </Box>
                        </>
                    }
                    {emptyGroup &&
                        <Typography sx={{fontWeight: 400, fontSize: "18px", lineHeight: "24px", letterSpacing: "0.09px", fontFamily: "Source Sans Pro"}}>
                            {t('group_ownership.delete_empty_group_subtitle', {group_name: currentGroupToDeleteName})}
                        </Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <div style={{
                        marginTop: '32px',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        p: 0,
                        gap: "100px",
                        '@media (max-width: 991px)': {
                            gap: '40px',
                            flexWrap: 'wrap'
                        }
                    }}>
                        <InverseButton id="cancelButton" onClick={handleDialogClose} sx={{border: '0px'}}> Cancel </InverseButton>
                        <Button disabled={(groupId === null && !emptyGroup)} className={(groupId === null && !emptyGroup)? "groupIdNullAndGroupNotEmpty" : "deleteGroupButtonEnabled"} onClick={() => {onConfirm(); handleDialogClose();}}>Confirm</Button>
                    </div>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openCreateNewGroupDialog}
                onConfirm={handleDialogCreateNewGroup}
                newGroupName={newGroupName}
                setNewGroupName={setNewGroupName}
                title={t('create_new_group.title_dialog')}
                confirmBtnText={t('create_new_group.button_confirm')}
                cancelBtnText={t('create_new_group.button_cancel')}
                onCancel={()=> setOpenCreateNewGroupDialog(false)}
            />
        </div>
    );
}