import {Button} from "./CustomUI";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {availableServiceProviders} from "../helpers/utils";

export function SetupGuideSection(props) {

    const { integrationType } = props;
    const { t } = useTranslation();

    let serviceProvider= availableServiceProviders.filter(serviceProvider => serviceProvider.integrationType === integrationType)[0];

    function goToLink() {
        window.open(serviceProvider.url, "_blank");
    }

    return (
        <Button id="openGuideButton" style={{width: '120px'}} onClick={goToLink}>
                {t('add_integration.open_guide')}
        </Button>
    )
}