import React, {useCallback, useEffect} from 'react';
import { context, ACTION_TYPE } from "../State";
import MuiDrawer from '@mui/material/Drawer';
import CheckIcon from '@mui/icons-material/Check';
import {
    availableServiceProviders,
    log,
    isValidDomain,
    MICROSOFT,
    OKTA,
    SALESFORCE,
    AWS,
    GSUITE,
    ZENDESK,
    CUSTOM,
    generateAndAddUtils,
    TEAMVIEWER,
    addNewTeamviewerClientUtils,
    KEEPER,
    isNumeric, formatDate
} from '../helpers/utils.js';
import { uploadFileUtils, addNewClientWithDomainUtils, saveDataOidcUtils, saveUtils } from '../helpers/utils.js';
import MuiDivider from '@mui/material/Divider';
import { styled } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { Button } from "./CustomUI"
import Api from "../helpers/service";
import {tryLocalStorageSetItem} from "../helpers/utils";
import { configManager } from '../App';
import { isValidBase64 } from '../helpers/utils.js';
import { isValidUUID } from '../helpers/utils.js';
import ContractRemovalConfirmationDialog from "./ContractRemovalConfirmationDialog";
import Box from "@mui/material/Box/Box";
import DomainInviteSection from "./DomainInviteSection";
import DrawerSection from "./DrawerSection";
import MainInfo from "./MainInfo";
import IdentityProviderParametersSection from "./IdentityProviderParametersSection";
import ServiceProviderMetadataComponent from "./ServiceProviderMetadataComponent";
import GrantIPAccessComponent from "./GrantIPAccessComponent";
import FederationComponent from "./FederationComponent";
import Configurator from "./Configurator";
import {ContractSection} from "./ContractSection";
import ScimComponent from "./ScimComponent";
import SIEMSection from "./SIEMSection";
import {SetupGuideSection} from "./SetupGuideSection";
import {IntegrationTypeSelector} from "./IntegrationTypeSelector";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {IntegrationNameInput} from "./IntegrationNameInput";
import {IntegrationConfigHeader} from "./IntegrationConfigHeader";
import OidcMethodSelector from "./OidcMethodSelector";
import NotificationCard from "./NotificationCard";
export default function Drawer(props) {
    const { isEditModeOn, isAddModeFirstStepOn, isViewModeOn, setIsAddModeFirstStepOn, setIsEditModeOn, setIsViewModeOn, openDrawer , setOpenDrawer, isSharedGroup, setIsSharedGroup } = props;
    const { state, dispatch } = React.useContext(context);
    const [integrationData, setIntegrationData] = React.useState(state.integrationData);
    const [checkedScim, setCheckedScim] = React.useState(integrationData.scimEnabled);
    const [scimCredentials, setScimCredentials] = React.useState(null);
    const [isLoadedScimCredentials, setIsLoadedScimCredentials] = React.useState(false);
    const [selectedSiem, setSelectedSiem] = React.useState('SENTINEL');
    const [checkedSiem, setCheckedSiem] = React.useState(integrationData.siemEnabled);
    const [showGrantAccessButton, setShowGrantAccessButton] = React.useState(true);
    const [newContractId, setNewContractId] = React.useState(null);
    const [identitiesLimit, setIdentitiesLimit] = React.useState(1000000);
    const [integrationsLimit, setIntegrationsLimit] = React.useState(5);
    const [contractReference, setContractReference] = React.useState({});
    const [selectedContract, setSelectedContract] = React.useState(null);
    const [assignContractChecked, setAssignContractChecked] = React.useState(integrationData.licenseType === "Licensed");
    const [showContractRemovalDialog, setShowContractRemovalDialog] = React.useState(false);
    const [removeContract, setRemoveContract] = React.useState(null);
    const [assignedContract, setAssignedContract] = React.useState(null);
    const [adminEmail, setAdminEmail] = React.useState("");
    const [disabled, setDisabled] = React.useState(true);
    const [createContract, setCreateContract] = React.useState(false);
    const [showSelectedContract, setShowSelectedContract] = React.useState(false);
    const [edition, setEdition] = React.useState("Business");
    const [isAddModeSecondStepOn, setIsAddModeSecondStepOn] = React.useState(false);
    const [isAddModeThirdStepOn, setIsAddModeThirdStepOn] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [federateResolved, setFederateResolved] = React.useState(false);
    const [inputError, setInputError] = React.useState(null);
    const [notifications, setNotifications] = React.useState(null);
    const [integrationDetails, setIntegrationDetails] = React.useState({
        integrationName: null,
        integrationType: null,
        domain: null,
        customerId: null,
        metadataUrl: null,
        metadataFile: null,
        credentials: null,
        redirectUri : null,
        values: {
            "workspaceId": "",
            "primaryKey": "",
            "serverHostname": "",
            "serverPort": 0
        },
        oidcConfiguration: { signingAlgorithm: "RS256", encryptionAlgorithm: "none", encryptionMethod: "none", authenticationMethod: "client_secret_post" }
    })

    const domainRef = React.useRef(null);
    const fileInput = React.useRef(null);
    const serviceProviderSectionRef = React.useRef(null);
    const { t } = useTranslation();
    const api = new Api();

    //if Siem was enabled for integration before
    let isSiemEnabled = integrationData.siemEnabled;
    //When we edit/view integration, we change integrationData state, but when we create integration we change integrationDetails state for integrationType
    let integrationType = integrationData.integrationType ? integrationData.integrationType : integrationDetails.integrationType;
    let protocol = integrationType === "OIDC" ? "OIDC" : (integrationType === "MICROSOFT" ? "WS-Fed" : "SAML");
    const Divider = styled(MuiDivider)(() => ({
        marginBottom: 22,
        width: '80%'
    }));

    const credentialsSetter = (data) => {
        setIntegrationDetails({
            ...integrationDetails,
            credentials: data
        });
    }

    const fetchSCIM = useCallback(async () => {
        if (!isLoadedScimCredentials) {
            try {
                const { data } = await api.generateSCIMCredentials("/app/generateSCIMCredentials");
                setScimCredentials(data);
                setIsLoadedScimCredentials(true);
            } catch (err) {
                log(err);
            }
        }
    }, [api, isLoadedScimCredentials]);

    useEffect(() => {
        setNotifications(state.notificationsForUser);
    }, [state.notificationsForUser]);


    useEffect(() => {
        setIntegrationData(state.integrationData);
        setCheckedScim(state.integrationData.scimEnabled);
        setCheckedSiem(state.integrationData.siemEnabled);
        setAssignContractChecked(state.integrationData.licenseType === "Licensed");
        setSelectedContract(state.integrationData.contract !== null ? state.integrationData.contract : null);
        setAssignedContract(state.integrationData.contract);
        setIntegrationDetails({
            ...integrationDetails,
            oidcConfiguration: {
                signingAlgorithm: state.integrationData.oidcSigningAlgorithm ? state.integrationData.oidcSigningAlgorithm : "RS256",
                encryptionAlgorithm: state.integrationData.oidcEncryptionAlgorithm ? state.integrationData.oidcEncryptionAlgorithm : "none",
                encryptionMethod: state.integrationData.oidcEncryptionMethod ? state.integrationData.oidcEncryptionMethod : "none",
                authenticationMethod: state.integrationData.oidcAuthenticationMethod ? state.integrationData.oidcAuthenticationMethod : "client_secret_post"
            }
        });
        if (state.integrationData.siemType !== "" && state.integrationData.siemType !== null && state.integrationData.siemType !== undefined) {
            setSelectedSiem(state.integrationData.siemType);
        }
        if (state.integrationData.scimEnabled === false) {
            fetchSCIM().then(r => log(r));
        }
    }, [state.integrationData]);

    let serviceProvider= availableServiceProviders.filter(sp => (sp.integrationType === integrationType))[0];

    let serverName = state.integrationData.serverName !== null  ? state.integrationData.serverName : configManager.serverName;
    let urlProtocol = integrationType === "MICROSOFT" ? "saml" : protocol.toLowerCase();
    const domainInviteLink = "https://" + serverName + "/"+ urlProtocol + "/signup/" + (state.integrationData.integrationType === 'MICROSOFT' || state.integrationData.integrationType === 'GSUITE' ? state.integrationData.domain : state.integrationData.integrationId);


    function resetIntegrationDetails() {
        setIntegrationDetails({
            ...integrationDetails,
            integrationName: null,
            domain: null,
            customerId: null,
            metadataUrl: null,
            metadataFile: null,
            credentials: null,
            redirectUri: null,
            oidcConfiguration: { signingAlgorithm: "RS256", encryptionAlgorithm: "none", encryptionMethod: "none", authenticationMethod: "client_secret_post" }
        });
    }

    function resetIntegrationData(){
        let initialIntegrationData = {
            domain:'',
            integrationId:'',
            clientType: '',
            siteId: '',
            contract: null,
            integrationName: '',
            containerId: null,
            loginMethod: '',
            fallbackLoginMethod: '',
            numberOfDevices: '',
            metadataUrl: null,
            metadataFile: null,
            clientMetadataId: 0,
            authenticationSettingsDto: null,
            file: null,
            isShared: false,
            numberOfSyncedUsers: 0,
            scimEndpointUrl: "",
            scimEnabled: false,
            scimSecret: "",
            licenseType: "",
            expirationDate: null,
            startDate: null,
            clientId: "",
            siemEnabled: false,
            workspaceId: "",
            serverHostname: "",
            serverPort: 0,
            siemType: "",
            serverName: null,
            domainVerified: false,
            oidcAuthenticationMethod: "client_secret_post",
            oidcSigningAlgorithm: "RS256",
            oidcEncryptionAlgorithm: "none",
            oidcEncryptionMethod: "none"
        }
        setIntegrationData(initialIntegrationData);
        dispatch({type: ACTION_TYPE.INTEGRATION_DATA, payload: initialIntegrationData});
    }

    const backClick = () => {
        if (isAddModeFirstStepOn) {
            setOpenDrawer(false);
            setIsAddModeFirstStepOn(false);
        } else if (isAddModeSecondStepOn) {
            setIsAddModeSecondStepOn(false);
            setIsAddModeFirstStepOn(true);
            setIntegrationDetails({
                ...integrationDetails,
                integrationName: null,
                integrationType: null
            });
        } else if (isAddModeThirdStepOn) {
            setIsAddModeSecondStepOn(true);
            setIsAddModeThirdStepOn(false);
            setCreateContract(false);
            resetIntegrationDetails();
            setErrorMsg(null);
            setInputError(null);
        } else if (isEditModeOn) {
            setIsEditModeOn(false);
            setIsViewModeOn(true);
            setCreateContract(false);
            resetIntegrationDetails();
            setErrorMsg(null);
        } else if (isViewModeOn) {
            setOpenDrawer(false);
            setIsSharedGroup(false);
            setIsViewModeOn(false);
            resetIntegrationData();
            resetIntegrationDetails();
        } else if (state.openNotificationsDrawer) {
            dispatch({ type: ACTION_TYPE.OPEN_NOTIFICATIONS_DRAWER, payload: false });
        }
    }

    const goToEditPage = () => {
        setIsViewModeOn(false);
        setIsEditModeOn(true);
    }
    function onSuccess(res) {
        log(res);
        setIntegrationDetails({
            ...integrationDetails,
            metadataFile: null
        });
        let authConfig = {
            WEBAUTHN_ENABLED: true,
            WEBAUTHN_MD_ENABLED: true,
            WEBAUTHN_ACCESS_KEY_ENABLED: false,
            AUTHN_ENABLED: true,
            CMLA_ENABLED: false
        };
       
        dispatch({ type: ACTION_TYPE.INTEGRATION_AUTH_CONFIG, payload: authConfig });
        tryLocalStorageSetItem(ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, true);
        dispatch({ type: ACTION_TYPE.SHOW_INTEGRATION_CHANGED_SNACK, payload: true });
        window.location.reload();
    }

    function setErrorWithMessage(isError, message) {
    }

    function onFailure(error) {
        log(error);
        log(error.response.data.errorType);
        log(error.response.data.errorMessage);
        let errorMessage = "Error occurred";
        let isError = true;
        switch (error.response.data.errorType) {
            case "METADATA_NOT_VALID":
                errorMessage = t('add_integration.errors.metadata_not_valid') + ": " + error.response.data.errorMessage;
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "CERTIFICATE_NOT_VALID":
                errorMessage = t('add_integration.errors.certificate_not_valid');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "REACHED_MAX_INTEGRATION_NUMBER":
                isError = false;
                dispatch({ type: ACTION_TYPE.SHOW_REACHED_MAX_INTEGRATION_ERROR_SNACK, payload: true });
                break;
            case "NOT_UNIQUE_ENTITY_ID":
                errorMessage = t('add_integration.errors.entity_id_not_unique');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "CERTIFICATE_EXPIRED":
                errorMessage = t('add_integration.errors.certificate_expired');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "CERTIFICATE_NOT_EXISTS":
                errorMessage = t('add_integration.errors.certificate_not_exists');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "DOMAIN_ALREADY_TAKEN":
                errorMessage = t('add_integration.errors.domain_taken');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            case "CANNOT_MODIFY_ENTITY_ID":
                errorMessage = t('add_integration.errors.entity_id_cannot_be_modified');
                setErrorMsg(errorMessage);
                serviceProviderSectionRef.current.focus();
                break;
            default:
                window.location.assign("#/integrations");
                break;
        }
        if (isError) {
            setErrorWithMessage(isError, errorMessage);
        }

    }

    function uploadFile() {
        uploadFileUtils(api, integrationDetails.metadataFile ? integrationDetails.metadataFile : integrationData.metadataFile,
            integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
            state.containerId ? state.containerId : integrationData.containerId,
            serviceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED,
            state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED,
            onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1,
            checkedScim,
            (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "",
            (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "",
            checkedSiem, selectedSiem, integrationDetails.values,selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function addNewClientWithDomain() {
        addNewClientWithDomainUtils(api, integrationData.domain ? integrationData.domain : integrationDetails.domain,
            integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
            state.containerId ? state.containerId : integrationData.containerId,
            serviceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED,
            onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1, checkedScim, (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "",
            (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, integrationDetails.values, selectedContract === null ? newContractId : selectedContract.contractId,
            identitiesLimit, integrationsLimit, contractReference);
    }

    function addNewTeamviewerClient() {
        addNewTeamviewerClientUtils(api, integrationDetails.customerId, integrationData.clientId ? integrationData.clientId : integrationDetails.credentials.clientId,
            integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
            state.containerId ? state.containerId : integrationData.containerId,
            serviceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED,
            state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED,
            onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1, checkedScim,
            (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "",
            checkedSiem, selectedSiem, integrationDetails.values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function generateAndAdd() {
        generateAndAddUtils(api, integrationData.domain ? integrationData.domain : integrationDetails.domain,
            integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
            state.containerId ? state.containerId : integrationData.containerId,
            serviceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED,
            state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1, checkedScim,
            (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "",
            checkedSiem, selectedSiem, integrationDetails.values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference);
    }

    function addCustomIntegration() {
        if (integrationData.metadataUrl || integrationDetails.metadataUrl) {
            saveUtils(api, integrationDetails.metadataUrl ? integrationDetails.metadataUrl : integrationData.metadataUrl,
                integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
                state.containerId ? state.containerId : integrationData.containerId,
                serviceProvider.formValue, state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED,
                state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED, onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1, checkedScim,
                (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "", (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "",
                checkedSiem, selectedSiem, integrationDetails.values, selectedContract === null ? newContractId : selectedContract.contractId, identitiesLimit, integrationsLimit, contractReference)
        } else {
            uploadFile();
        }
    }

    function saveAlgorithmsAndURL() {
        saveDataOidcUtils(api, isEditModeOn ? null : integrationDetails.credentials.clientId, integrationDetails.redirectUri,
            state.containerId ? state.containerId : integrationData.containerId,
            integrationDetails.oidcConfiguration.signingAlgorithm, integrationDetails.oidcConfiguration.encryptionAlgorithm,
            integrationDetails.oidcConfiguration.encryptionMethod, integrationDetails.oidcConfiguration.authenticationMethod,
            isEditModeOn ? null : integrationDetails.credentials.clientSecret, integrationDetails.integrationName ? integrationDetails.integrationName : integrationData.integrationName,
            state.integrationAuthConfig.WEBAUTHN_ENABLED, state.integrationAuthConfig.AUTHN_ENABLED, state.integrationAuthConfig.CMLA_ENABLED, state.integrationAuthConfig.WEBAUTHN_ACCESS_KEY_ENABLED,
            onSuccess, onFailure, isEditModeOn, integrationData.integrationId ? integrationData.integrationId : -1,  checkedScim, (scimCredentials !== null && checkedScim) ? scimCredentials.scimClientId : "",
            (scimCredentials !== null && checkedScim) ?  scimCredentials.scimSecretToken : "", checkedSiem, selectedSiem, integrationDetails.values, selectedContract === null ? newContractId : selectedContract.contractId,
            identitiesLimit, integrationsLimit, contractReference);
    }

    function saveChanges() {
        let custom = serviceProvider.custom;
        let formValue = serviceProvider.formValue;
        let primaryKeyValid = true;
        let workspaceIdValid = true;
        let serverHostnameValid = true;
        let serverPortValid = true;

        if (isAddModeThirdStepOn) {
            if (integrationDetails.integrationType === "OKTA" || integrationDetails.integrationType === "SALESFORCE" || integrationDetails.integrationType === "AWS" || integrationDetails.integrationType === "KEEPER") {
                if (!integrationDetails.metadataFile) {
                    setErrorMsg("Metadata file is missing");
                    serviceProviderSectionRef.current.focus();
                    return;
                }
            } else if (integrationDetails.integrationType === "CUSTOM") {
                if (!integrationDetails.metadataFile && !integrationDetails.metadataUrl) {
                    setErrorMsg("Metadata file or metadata URL should be set");
                    serviceProviderSectionRef.current.focus();
                    return;
                }
            } else if (integrationDetails.integrationType === "MICROSOFT" || integrationDetails.integrationType === "GSUITE" || integrationDetails.integrationType === "ZENDESK" ) {
                if (integrationDetails.domain === null) {
                    setInputError("Domain is empty");
                    serviceProviderSectionRef.current.focus();
                    return;
                }
            } else if (integrationDetails.integrationType === "TEAMVIEWER") {
                if (integrationDetails.customerId === null) {
                    setInputError("Customer id is empty");
                    serviceProviderSectionRef.current.focus();
                    return;
                }
            } else if (integrationDetails.integrationType === "OIDC") {
                if (integrationDetails.redirectUri === null) {
                    setInputError("Redirect URI is empty");
                    serviceProviderSectionRef.current.focus();
                    return;
                }
            }
        }

        if (inputError) {
            serviceProviderSectionRef.current.focus();
            return;
        }

        if (!isValidBase64(integrationDetails.values["primaryKey"]) && checkedSiem && selectedSiem === "SENTINEL") {
            primaryKeyValid = false;

        } 
        if (!isValidUUID(integrationDetails.values["workspaceId"]) && checkedSiem && selectedSiem === "SENTINEL" ) {
            workspaceIdValid = false;
        }
        if (!isValidDomain(integrationDetails.values["serverHostname"]) && checkedSiem && selectedSiem === "SYSLOG_QRADAR" ) {
            serverHostnameValid = false;
        }

        if ((isNaN(integrationDetails.values["serverPort"]) || Number(integrationDetails.values["serverPort"]) < 1 || Number(integrationDetails.values["serverPort"]) > 65536) && checkedSiem && selectedSiem === "SYSLOG_QRADAR" ) {
            serverPortValid = false;
        }

        if (isSiemEnabled && (integrationDetails.values["primaryKey"] === "" || integrationDetails.values["workspaceId"] === "" || integrationDetails.values["serverHostname"] === "" || integrationDetails.values["serverPort"] === 0)) {
            //this is in case that no value in SIEM section is changed
            primaryKeyValid = true;
            workspaceIdValid = true;
            serverHostnameValid = true;
            serverPortValid = true;
        }

        if ((!primaryKeyValid || !workspaceIdValid) && selectedSiem === "SENTINEL") {
            return false;
        }

        if((!serverHostnameValid || !serverPortValid) && selectedSiem === "SYSLOG_QRADAR") {
            return false;
        }

        for (let key in contractReference) {
            if (!isNumeric(contractReference[key])) {
                return;
            }
        }
        
        if(integrationData.integrationName === "" && integrationDetails.integrationName === "") {
            //if integration name is empty or HTML code, saving changes is not possible 
            return;
        }

        if (integrationData.licenseType === "Licensed" && newContractId === null && selectedContract === null) {
            if (removeContract === null || removeContract === false) {
                setShowContractRemovalDialog(true);
                return;
            }
        }

        if (custom === "OIDC") {
            saveAlgorithmsAndURL();
        } else {
            switch (formValue) {
                case OKTA:
                case SALESFORCE:
                case AWS:
                case KEEPER:
                    uploadFile();
                    break;
                case MICROSOFT:
                case GSUITE:
                    addNewClientWithDomain();
                    break;
                case TEAMVIEWER: 
                    addNewTeamviewerClient();
                    break;
                case ZENDESK:
                    generateAndAdd();
                    break;
                case CUSTOM:
                    addCustomIntegration();
                    break;
                default:
                    break;
            }
        }

    }

    const shouldDisplayMetadataSection = (integrationType) => {
        switch (integrationType) {
            case "OKTA":
            case "SALESFORCE":
            case "AWS":
            case "KEEPER":
            case "MICROSOFT":
            case "GSUITE":
            case "CUSTOM":
                return true;
            case "TEAMVIEWER":
            case "ZENDESK":
            case "OIDC":
                return isAddModeThirdStepOn !== undefined && isAddModeThirdStepOn;
            default:
                return false;
        }
    }

    const calculateStep = (integrationType) => {
        switch (integrationType) {
            case "OKTA":
            case "SALESFORCE":
            case "AWS":
            case "KEEPER":
            case "GSUITE":
            case "CUSTOM":
                return isEditModeOn ? "Step 2" : "Step 3";
            case "MICROSOFT":
                return "Step 4"
            case "TEAMVIEWER":
            case "ZENDESK":
            case "OIDC":
                return isEditModeOn ? "Step 1" : "Step 3"
            default:
                return "";
        }
    }
    
    const classConfiguratorAndOptionalStepsDisabled = () => {
        let thirdStepOn = "AndthirdStepIsOne"
        switch (integrationType) {
            case "OKTA":
            case "SALESFORCE":
            case "AWS":
            case "KEEPER":
                return "missingMetadataFile" + thirdStepOn;
            case "CUSTOM":
                return "missingMetadataFileOrUrl" + thirdStepOn;
            case "MICROSOFT":
                return "missingDomain" + thirdStepOn;
            case "TEAMVIEWER":
                return "missingCustomerId" + thirdStepOn;
            case "OIDC":
                return "missingRedirectUri" + thirdStepOn;
            case "GSUITE":
            case "ZENDESK":
                return "missingDomain" + thirdStepOn;
            default:
                return true;
        }
    }

    const unlockConfiguratorAndOptionalSteps = () => {
        switch (integrationType) {
            case "OKTA":
            case "SALESFORCE":
            case "AWS":
            case "KEEPER":
                return !integrationDetails.metadataFile;
            case "CUSTOM":
                return !(integrationDetails.metadataFile || integrationDetails.metadataUrl)
            case "MICROSOFT":
                return !integrationDetails.domain
            case "TEAMVIEWER":
                return !integrationDetails.customerId
            case "OIDC":
                return !integrationDetails.redirectUri
            case "GSUITE":
            case "ZENDESK":
                return !integrationDetails.domain
            default:
                return true;
        }
    }

    //information as JSON about each section within view/edit window
    const infos = [
        {   "title": "Domain invite",
            "stepLabel": null,
            "needsTitle": false,
            "needsToggle": false,
            "divide": true,
            "content": <DomainInviteSection licenceType={integrationData.licenseType} domainInviteLink={domainInviteLink}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": !isAddModeThirdStepOn,
            "disabled": false,
            "id": "domainInviteTitle"
        },
        {   "title": t('integration_view.main_info'),
            "stepLabel": null,
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <MainInfo integrationType={integrationType} integrationName={integrationData.integrationName} integrationProtocol={protocol} isViewModeOn={isViewModeOn} integrationDetails={integrationDetails} setIntegrationDetails={setIntegrationDetails} />,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": !isAddModeThirdStepOn,
            "disabled": false,
            "id": "mainInfoTitle"
        },
        {   "title": t('add_integration.idp_parameters_heading', {protocol: protocol}),
            "stepLabel": null,
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <IdentityProviderParametersSection integrationData={integrationData} integrationDetails={integrationDetails} setCredentials={credentialsSetter} isEdit={isEditModeOn} isAddModeThirdStepOn={isAddModeThirdStepOn}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": true,
            "disabled": false,
            "id": "idpParametersHeading"
        },
        {
            "title": t('add_integration.setup_guide_heading'),
            "stepLabel": "Step 1",
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <SetupGuideSection integrationType={integrationType}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": isAddModeThirdStepOn && (integrationType) !== "MICROSOFT",
            "disabled": false,
            "id": "setupGuideTitle"
        },
        {   "title": t('add_integration.metadata_heading'),
            "stepLabel": isAddModeThirdStepOn && integrationType !== "MICROSOFT" ? "Step 2" : "Step 1",
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <ServiceProviderMetadataComponent isAddIntegration={isAddModeThirdStepOn} isViewIntegration={isViewModeOn} integrationData={integrationData} integrationDetails={integrationDetails} setIntegrationDetails={setIntegrationDetails} domainRef={domainRef} fileInput={fileInput} errorMsg={errorMsg} setErrorMsg={setErrorMsg} serviceProviderSectionRef={serviceProviderSectionRef} inputError={inputError} setInputError={setInputError}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": shouldDisplayMetadataSection(integrationType),
            "disabled": false,
            "id": "spMetadataTitle"
        },
        {   "title": t('add_integration.grant_aip_access'),
            "stepLabel": "Step 2",
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <GrantIPAccessComponent showGrantAccessButton={showGrantAccessButton} setShowGrantAccessButton={setShowGrantAccessButton} adminEmail={adminEmail} setAdminEmail={setAdminEmail} setDisabled={setDisabled} isViewModeOn={isViewModeOn}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": (integrationType) === "MICROSOFT" && !isViewModeOn,
            "disabled": integrationDetails.domain === null && isAddModeThirdStepOn,
            "classMessage": integrationDetails.domain === null && isAddModeThirdStepOn? "domainIsNullAndThirdStepIsOn" : "grantAccessAvailableToSet", 
            "id": "grantAccessTitle"
        },
        {   "title": t('federate_domain.section_heading'),
            "stepLabel": "Step 3",
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <FederationComponent domainInputRef={serviceProviderSectionRef} domain={integrationData.domain ? integrationData.domain : integrationDetails.domain} isEdit={isEditModeOn} isView={isViewModeOn} isAdd={isAddModeThirdStepOn} disabled={disabled} serverName={serverName} setErrorMsg={setErrorMsg} setFederateResolved={setFederateResolved}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": (integrationType) === "MICROSOFT" && !isViewModeOn,
            "disabled": integrationDetails.domain === null && isAddModeThirdStepOn,
            "classMessage": integrationDetails.domain === null && isAddModeThirdStepOn? "domainIsNullAndThirdStepIsOn" : "federateComponentAvailableToSet",
            "id": "federateDomainTitle"
        },
        {   "title": t('add_integration.configurator_heading'),
            "stepLabel": calculateStep(integrationType),
            "needsTitle": true,
            "needsToggle": false,
            "divide": true,
            "content": <Configurator authenticationSettingsDto={state.integrationData.authenticationSettingsDto ? state.integrationData.authenticationSettingsDto : null} shouldShowAccessKey={integrationType === "MICROSOFT" || integrationType === "GSUITE"} disabled={isViewModeOn} integrationDetails={integrationDetails} setIntegrationDetails={setIntegrationDetails} />,
            "needsDescription": true,
            "description": t('add_integration.configurator_subheading'),
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": null,
            "display": true,
            "disabled": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn,
            "classMessage": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn? classConfiguratorAndOptionalStepsDisabled() : "configuratorAvailableToSet",
            "id": "configuratorTitle"
        },
        {   "title": t('add_integration.contract_heading'),
            "stepLabel": "Optional step",
            "needsTitle": true,
            "needsToggle": isEditModeOn || isAddModeThirdStepOn,
            "divide": true,
            "content": <ContractSection setNewContractId={setNewContractId} identitiesLimit={identitiesLimit} setIdentitiesLimit={setIdentitiesLimit} integrationsLimit={integrationsLimit} setIntegrationsLimit={setIntegrationsLimit} contractReference={contractReference} setContractReference={setContractReference} selectedContract={selectedContract} setSelectedContract={setSelectedContract} createContract={createContract} setCreateContract={setCreateContract} showSelectedContract={showSelectedContract} setShowSelectedContract={setShowSelectedContract} contract={integrationData.contract} isEdit={isEditModeOn} isAdd={isAddModeThirdStepOn} edition={edition} setEdition={setEdition} assignedContract={assignedContract} setAssignedContract={setAssignedContract}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": assignContractChecked,
            "setChecked": setAssignContractChecked,
            "display": isAddModeThirdStepOn || isEditModeOn || (isViewModeOn && assignedContract !== null && assignedContract.contractId !== null),
            "disabled": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn,
            "classMessage": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn? classConfiguratorAndOptionalStepsDisabled() : "contractAvailableToSet",
            "id": "contractTitle"
        },
        {   "title": t('add_integration.scim_provisioning'),
            "stepLabel": "Optional step",
            "needsTitle": true,
            "needsToggle": isEditModeOn || isAddModeThirdStepOn,
            "divide": true,
            "content": <ScimComponent isScimEnabled={integrationData.scimEnabled} integrationType={integrationType} isEditOrAddModeOn={isEditModeOn || isAddModeThirdStepOn} isLoadedScimCredentials={isLoadedScimCredentials} setIsLoadedScimCredentials={setIsLoadedScimCredentials} scimCredentials={scimCredentials} setScimCredentials={setScimCredentials}/>,
            "needsDescription": true,
            "description": t('integration_view.user_provisioning_description'),
            "needsDescriptionNote": true,
            "descriptionNote": t('integration_view.user_provisioning_description_note'),
            "checked": checkedScim,
            "setChecked": setCheckedScim,
            "display": (isAddModeThirdStepOn || isEditModeOn || (isViewModeOn && integrationData.scimEnabled)) && integrationType !== 'OIDC',
            "disabled": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn,
            "classMessage": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn? classConfiguratorAndOptionalStepsDisabled() : "scimAvailableToSet",
            "id": "scimTitle"
        },
        {   "title": t('add_integration.siem_heading'),
            "stepLabel": "Optional step",
            "needsTitle": true,
            "needsToggle": isEditModeOn || isAddModeThirdStepOn,
            "divide": integrationType === "OIDC",
            "content": <SIEMSection t={t} inSidebar={true} selectedSiem={selectedSiem} setSelectedSiem={setSelectedSiem} setIntegrationDetails={setIntegrationDetails} integrationDetails={integrationDetails} siemEnabled={integrationData.siemEnabled} isEditModeOn={isEditModeOn || isAddModeThirdStepOn}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": checkedSiem,
            "setChecked": setCheckedSiem,
            "display": isAddModeThirdStepOn || isEditModeOn || (isViewModeOn && integrationData.siemEnabled),
            "disabled": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn,
            "classMessage": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn? classConfiguratorAndOptionalStepsDisabled() : "siemAvailableToSet",
            "id": "siemTitle"
        },
        {   "title": t('add_integration.oidc_config'),
            "stepLabel": "Optional step",
            "needsTitle": true,
            "needsToggle": isEditModeOn || isAddModeThirdStepOn,
            "divide": false,
            "content": <OidcMethodSelector integrationDetails={integrationDetails} setIntegrationDetails={setIntegrationDetails}/>,
            "needsDescription": false,
            "description": null,
            "needsDescriptionNote": false,
            "descriptionNote": null,
            "checked": false,
            "setChecked": null,
            "display": (isAddModeThirdStepOn || isEditModeOn) && (integrationType === "OIDC"),
            "disabled": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn,
            "classMessage": unlockConfiguratorAndOptionalSteps() && isAddModeThirdStepOn? classConfiguratorAndOptionalStepsDisabled() : "oidcMethodAvailableToSet",
            "id": "oidcMethodTitle"
        },

    ]

    const getTitleForNotification = (type) => {
        switch (type) {
            case 'AIP_GROUP_ADMIN_INVITE':
                return t('group.notification_invite_admin_title');
            default:
                return '';
        }
    }

    const getNotificationText = (type, data, timestamp) => {
        switch (type) {
            case 'AIP_GROUP_ADMIN_INVITE':
                return t('group.notification_invite_admin_text', {adminEmail: data.email, createdDateTime: formatDate(timestamp), expiryTime: formatDate(data.expiryTimestamp)});
            default:
                return '';
        }
    }

    const onConfirm = (type, data) => {
        if (type === 'AIP_GROUP_ADMIN_INVITE') {
            window.open(data.acceptUrl, '_self');
        }
    }

    const onReject = (type, data) => {
        if (type === 'AIP_GROUP_ADMIN_INVITE') {
            let token = data.acceptUrl.substring(data.acceptUrl.indexOf('t=') + 2);
            api.deleteNotification(token, data.email, data.containerId).then((res) => {
                if (res.status === 200) {
                    let notifications = state.notificationsForUser.filter(notification => notification.data.acceptUrl !== data.acceptUrl);
                    dispatch({type: ACTION_TYPE.NOTIFICATIONS_FOR_USER, payload: notifications});
                }
            });
        }
    }

    return (
        <>
            <MuiDrawer anchor={'right'}
                       open={openDrawer || state.openNotificationsDrawer}
                       >
                <DrawerContainer>
                    { !state.openNotificationsDrawer &&
                        <Container className={"drawer"}>
                            <IntegrationConfigHeader isAddModeFirstStepOn={isAddModeFirstStepOn}
                                                     isAddModeSecondStepOn={isAddModeSecondStepOn}
                                                     isAddModeThirdStepOn={isAddModeThirdStepOn} isEditModeOn={isEditModeOn}
                                                     isViewModeOn={isViewModeOn}
                                                     integrationName={integrationData.integrationName ? integrationData.integrationName : integrationDetails.integrationName}
                                                     integrationType={integrationType} backClick={backClick}/>
                            {isAddModeFirstStepOn &&
                                <IntegrationTypeSelector setIsAddModeSecondStepOn={setIsAddModeSecondStepOn}
                                                         setIsAddModeFirstStepOn={setIsAddModeFirstStepOn}
                                                         integrationDetails={integrationDetails}
                                                         setIntegrationDetails={setIntegrationDetails}/>}
                            {isAddModeSecondStepOn &&
                                <IntegrationNameInput setIsAddModeSecondStepOn={setIsAddModeSecondStepOn}
                                                      setIsAddModeThirdStepOn={setIsAddModeThirdStepOn}
                                                      integrationDetails={integrationDetails}
                                                      setIntegrationDetails={setIntegrationDetails}/>}
                            {(isEditModeOn || isViewModeOn || isAddModeThirdStepOn) && <div style={{
                                display: 'flex',
                                padding: '24px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '24px',
                                flexShrink: 0,
                                alignSelf: 'stretch',
                                border: '1px solid var(--Gray-3, #D7D7D7)',
                                borderRadius: '16px'
                            }}>
                                {infos.map((info) => {
                                    return info.display ?
                                        <DrawerSection
                                            content={info.content}
                                            stepLabel={info.stepLabel}
                                            needsToggle={info.needsToggle}
                                            needsTitle={info.needsTitle}
                                            title={info.title}
                                            needsDescription={info.needsDescription}
                                            description={info.description}
                                            needsDescriptionNote={info.needsDescriptionNote}
                                            descriptionNote={info.descriptionNote}
                                            divide={info.divide}
                                            initialChecked={info.checked}
                                            setCheckedToggle={info.setChecked}
                                            setCreateContract={setCreateContract}
                                            isViewModeOn={isViewModeOn}
                                            isAddModeOn={isAddModeThirdStepOn}
                                            isEditModeOn={isEditModeOn}
                                            disabled={info.disabled}
                                            setSelectedContract={setSelectedContract}
                                            assignedContract={assignedContract}
                                            id={info.id}
                                            classMessage={info.classMessage}/> : null
                                })}
                            </div>}
                    </Container>}
                    { (!isAddModeFirstStepOn && !isAddModeSecondStepOn && !state.openNotificationsDrawer && !isSharedGroup) && <Divider sx={{marginTop: '32px', marginBottom: '32px', width: '100%'}}/>}
                    { isEditModeOn && !state.openNotificationsDrawer &&
                        <Button id='saveChangesButton' onClick={saveChanges}>
                        <CheckIcon sx={{width: '24px', height: '24px'}}/>
                        {t('add_integration.save_updates_button_heading')}
                        </Button>}
                    { isViewModeOn && !state.openNotificationsDrawer && !isSharedGroup &&
                        <Button id='goToEditPageButton' onClick={goToEditPage}>
                            <EditOutlinedIcon sx={{width: '24px', height: '24px'}}/>
                            {t('integration_view.edit_button_heading')}
                        </Button>}
                    { isAddModeThirdStepOn && !state.openNotificationsDrawer &&
                        <Button id='saveIntegrationButton' onClick={saveChanges}>
                            {t('add_integration.save_integration_button_heading')}
                        </Button>}

                    { state.openNotificationsDrawer &&
                        <Container>
                            <IntegrationConfigHeader backClick={backClick}/>
                            { notifications.map((notification) => {
                                let title = getTitleForNotification(notification.type);
                                let text = getNotificationText(notification.type, notification.data, notification.timestamp);
                                return <NotificationCard title={title} text={text} onAccept={() => onConfirm(notification.type, notification.data)} onReject={() => onReject(notification.type, notification.data)}/>
                            })}
                        </Container>}
                </DrawerContainer>
            </MuiDrawer>
            <ContractRemovalConfirmationDialog show={showContractRemovalDialog} setShow={setShowContractRemovalDialog}
                                               removeContract={removeContract} setRemoveContract={setRemoveContract}
                                               save={saveChanges}/>
        </>
    );
}

const Container = styled(Box)({
    display: 'flex',
    padding: '24px 24px 0px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    flexShrink: 0,
    width: '100%'
});

const DrawerContainer = styled(Box)({
    width: '704px',
    display: 'flex',
    padding: '32px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexShrink: 0
});

